import client from './client';

/**
 * Creates user
 *
 * @param {Object} user object contains data for creating user
 * @returns {Promise} Promise object represents operation result
 */
const createUser = async user => {
  const data = user;

  const response = await client.post('/users/', data);

  if (response.status === 201) {
    return response;
  }
};

/**
 * Fetches user by id
 *
 * @param {String} id user id
 * @returns {Promise} Promise object represents operation result
 */
const fetchUserById = async id => {
  const response = await client.get(`/users/${id}/`);

  if (response.status === 200) {
    const user = {
      data: {
        full_name: response.data.full_name,
        email: response.data.email,
        phone_number: response.data.phone_number,
        address: response.data.address,
        account_id: response.data.account_id,
        active: response.data.active,
        id: response.data.id,
        role_id: response.data.role_id,
        subscriptions: response.data.subscriptions,
        csLevels: response.data.cs_levels,
        avatar: response.data.avatar_url,
      },
    };

    return user;
  }
};

/**
 * Updates user by id
 *
 * @param {*} { userId, ...values }
 * @returns {Promise} Promise object represents operation result
 */
const updateUserById = async ({ userId, ...values }) => {
  const data = {
    ...values,
  };

  const response = await client.put(`/users/${userId}/`, data);
  if (response.status === 200) {
    const updatedUser = {
      data: {
        full_name: response.data.full_name,
        email: response.data.email,
        phone_number: response.data.phone_number,
        address: response.data.address,
        account_id: response.data.account_id,
        active: response.data.active,
        id: response.data.id,
        role_id: response.data.role_id,
        subscriptions: response.data.subscriptions,
        csLevels: response.data.cs_levels,
        avatar: response.data.avatar_url,
      },
      status: response.status,
    };
    return updatedUser;
  }
};

/**
 * Updates user by id
 *
 * @param {*} { userId, ...values }
 * @returns {Promise} Promise object represents operation result
 */
const updateCurrentUserCompamy = async values => {
  const data = {
    ...values,
  };

  const response = await client.put('/users/company', data);
  if (response.status === 200) {
    return response;
  }
};

/**
 * Deletes user from database
 *
 * @param {String} id user id
 * @returns {Promise} Promise object represents operation result
 */
const deleteUserById = async id => {
  const response = await client.delete(`/users/${id}/`);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Set or update user avatar
 *
 * @returns {Promise} Promise object represents operation result
 */
const updateUserAvatar = async formData => {
  const data = formData;

  const response = await client.post('/users/update_avatar/', data);

  if (response.status === 200) {
    return response;
  }
};

/**
 * Delete user avatar
 *
 * @returns {Promise} Promise object represents operation result
 */
const deleteUserAvatar = async () => {
  const response = await client.delete('/users/delete_avatar/');

  if (response.status === 200) {
    return response;
  }
};

/**
 * Request for onboarding
 *
 * @returns {Promise} Promise object represents operation result
 */
async function requestForOnboarding(values) {
  const data = values;

  const response = await client.post('/users/request_for_onboarding/', data);

  if (response.status === 200) {
    return response;
  }
}

export { createUser, fetchUserById, updateUserById, updateCurrentUserCompamy, deleteUserById, updateUserAvatar, deleteUserAvatar, requestForOnboarding };
