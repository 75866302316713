// Core
import React, { createContext, useState, useEffect } from 'react';
// Hooks
import { useAuth } from 'hooks/useAuth';
import useAsync from 'hooks/useAsync';
// Instruments
import { fetchLoggedInUserObj } from 'api/auth';

export const UserContext = createContext(JSON.parse(localStorage.getItem('user')));
export const UserConsumer = UserContext.Consumer;

const UserProvider = ({ children }) => {
  const { accessToken } = useAuth();

  const { execute: getLoggedInUser, pending: loadingUserObj, error, value: loggedInUser } = useAsync(fetchLoggedInUserObj, false);

  function updateUserData() {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      getLoggedInUser();
      if (loggedInUser) {
        localStorage.setItem('user', JSON.stringify(loggedInUser));
      }
    } else {
      setUserContext({loggedInUser: null, updateUserData})
    }
  };
  
  const initValue = () => ({
    loggedInUser: loggedInUser || JSON.parse(localStorage.getItem('user')),
    loadingUserObj,
    error,
    updateUserData
  });
  const [userContext, setUserContext] = useState(initValue());

  useEffect(() => {
    if (accessToken) {
      getLoggedInUser();
    }
  }, [getLoggedInUser, accessToken]);

  useEffect(() => {
    if (loggedInUser) {
      localStorage.setItem('user', JSON.stringify(loggedInUser));
    }
    setUserContext(initValue());
  }, [loggedInUser]);

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};

export default UserProvider;
