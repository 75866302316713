export const DEFAULT_MENU = [
  { text: 'Головна сторінка ', path: '/', exact: true },
  { text: 'Підприємці', path: '/entrepreneurs' },
  { text: 'Бібліотека', path: '/library' },
];

export const DASHBOARD_MENU = [
  { text: 'Підприємці', path: '/entrepreneurs' },
  { text: 'Новатори', path: '/innovators' },
  { text: 'Синергії', path: '/synergies' },
  { text: 'Бібліотека', path: '/library' },
];
