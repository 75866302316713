import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_API_URL;

const client = axios.create({
  baseURL: BASE_API_URL,
});

const getAccessToken = () => localStorage.getItem('access_token');
const getRefreshToken = () => localStorage.getItem('refresh_token');

client.interceptors.request.use(config => {
  const access_token = getAccessToken();
  const refresh_token = getRefreshToken();
  
  if (config.url.includes('refresh') && refresh_token) {
    config.headers.set('Authorization', `Bearer ${refresh_token}`);
  } else if (access_token) {
    config.headers.set('Authorization', `Bearer ${access_token}`);
  }
  return config;
});

export default client;
