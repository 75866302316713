import client from './client';

const ENDPOINT = '/applicants';

async function createApplicant(data) {
  const response = await client.post(ENDPOINT, data);
  return response;
}

async function getApplicant(id) {
  const response = await client.get(`${ENDPOINT}/${id}/`);
  return response;
}

async function getApplicants(params) {
  const response = await client.get(`${ENDPOINT}/`, { params });
  return response;
}

async function updateApplicant({id, data}) {
  const response = await client.put(`${ENDPOINT}/${id}/`, data);
  return response;
}

async function deleteApplicant(id) {
  const response = await client.delete(`${ENDPOINT}/${id}/`);
  return response;
}

export { createApplicant, getApplicant, getApplicants, updateApplicant, deleteApplicant };
