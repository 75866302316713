// Core
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ArrowRight, Language } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DashboardInfoBlock from './DashboardInfoBlock';
import DashboardChips from './DashboardChips';

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    marginBottom: '10px',
    border: '1px solid var(--border-color)',
    borderRadius: '4px',
    transition: 'border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      borderColor: 'var(--primary-regular)',
    },
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '11px 16px',
    borderBottom: '1px solid var(--border-color)',
  },
  cardContent: {
    padding: '16px',
  },
  cardName: {
    
    paddingRight: '16px',
    flex: '0 0 auto',
    '& h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      width: '202px',
    }
  },
  cardHeaderHolder: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardDescription: {
    marginBottom: '10px',
  },
  cardDescriptionText: {
    lineClamp: 2,
    overflow: 'hidden',
    boxOrient: 'vertical',
    display: 'box',
  },
}));
const DashboardCard = ({ name, country, items, description, itemLink, chipTitle, mouseEnterHandler, mouseLeaveHandler }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardWrapper} onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
      <Box className={classes.cardHeader}>
        <Box className={classes.cardName}>
          <Typography variant="h6" component="h6">
            {name}
          </Typography>
        </Box>
        <Box className={classes.cardHeaderHolder}>
          <DashboardInfoBlock title="Країна" content={country} icon={<Language fontSize="small" />} />
          {itemLink ?
          <Link to={itemLink}>
            <IconButton color="primary">
              <ArrowRight />
            </IconButton>
          </Link>
          : null}
        </Box>
      </Box>
      <Box className={classes.cardContent}>
        <Box className={classes.cardDescription}>
          <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
            Опис
          </Typography>
          <Typography className={classes.cardDescriptionText} variant="body1" gutterBottom>
            {description}
          </Typography>
        </Box>
        {
          items ? 
          items.map((item, index) => <DashboardChips key={index} items={item.items} title={item.title} /> ) : null
        }
      </Box>
    </Box>
  );
};

DashboardCard.defaultProps = {
  items: [],
  description: '',
  name: '',
  country: '',
  itemLink: '/',
  chipTitle: '',
};

DashboardCard.propTypes = {
  items: PropTypes.array,
  description: PropTypes.string,
  name: PropTypes.string,
  country: PropTypes.string,
  itemLink: PropTypes.string,
  chipTitle: PropTypes.string,
  mouseLeaveHandler: PropTypes.func,
  mouseEnterHandler: PropTypes.func
};

export default DashboardCard;
