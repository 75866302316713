import React from 'react';

const TabletIcon = props => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 80 80">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H80V80H0z" transform="translate(-291 -716) translate(276 580) translate(15 136)" />
              <path
                fill={color || '#FFF'}
                d="M39.454 6.466c1.76 0 3.437.848 4.484 2.27.5.68 1.295 1.085 2.127 1.085h2.862c1.565 0 2.92.914 3.558 2.237h7.102c3.083 0 5.592 2.509 
                                5.592 5.592v50.332c0 3.084-2.509 5.593-5.592 5.593H19.322c-3.085 0-5.593-2.51-5.593-5.593V17.65c0-3.083 2.508-5.592 5.593-5.592h7.1c.64-1.323 
                                1.994-2.237 3.558-2.237h2.862c.833 0 1.628-.405 2.127-1.084 1.048-1.423 2.724-2.271 4.485-2.271zm-13.422 7.829h-6.71c-1.85 0-3.356 1.506-3.356 
                                3.355v50.332c0 1.85 1.505 3.355 3.356 3.355h40.265c1.85 0 3.355-1.505 3.355-3.355V17.65c0-1.85-1.506-3.355-3.355-3.355h-6.711l-.001 
                                2.237h4.475c1.788 0 3.255 1.407 3.35 3.172l.005.184v45.857c0 1.85-1.505 3.355-3.355 3.355H21.558c-1.85 0-3.355-1.505-3.355-3.355V19.888c0-1.85 
                                1.505-3.356 3.355-3.356h4.474v-2.237zm0 4.474h-4.474c-.617 0-1.118.502-1.118 1.119v45.857c0 .616.5 1.119 1.118 1.119H57.35c.616 0 1.118-.503 
                                1.118-1.12V19.889c0-.617-.502-1.12-1.118-1.12h-4.474v1.12c0 .493-.321.913-.765 1.06l-.114.032c-.078.017-.158.026-.24.026H27.152c-.618 
                                0-1.119-.502-1.119-1.118v-1.12zm22.336 43.62c.618 0 1.119.502 1.119 1.12 0 .617-.501 1.118-1.119 1.118H23.795c-.617 0-1.119-.501-1.119-1.119 
                                0-.617.502-1.119 1.12-1.119zm6.745 0c.617 0 1.118.502 1.118 1.12 0 .617-.501 1.118-1.118 1.118h-2.237c-.617 0-1.118-.501-1.118-1.119 
                                0-.617.5-1.119 1.118-1.119zm0-4.473c.617 0 1.118.5 1.118 1.118 0 .617-.501 1.119-1.118 1.119h-8.948c-.618 0-1.119-.502-1.119-1.12 0-.616.501-1.117 
                                1.12-1.117zm-27.962 0c.617 0 1.118.5 1.118 1.118 0 .617-.501 1.119-1.118 1.119h-3.356c-.617 0-1.119-.502-1.119-1.12 
                                0-.616.502-1.117 1.12-1.117zm14.54 0c.618 0 1.119.5 1.119 1.118 0 .617-.501 1.119-1.12 1.119H31.625c-.617 0-1.118-.502-1.118-1.12 
                                0-.616.501-1.117 1.118-1.117zm6.677-4.474c.618 0 1.119.5 1.119 1.118 0 .617-.501 1.118-1.119 1.118H23.795c-.617 0-1.119-.5-1.119-1.118 
                                0-.617.502-1.118 1.12-1.118zm6.745 0c.617 0 1.118.5 1.118 1.118 0 .617-.501 1.118-1.118 1.118h-2.237c-.617 0-1.118-.5-1.118-1.118 
                                0-.617.5-1.118 1.118-1.118zm0-4.474c.617 0 1.118.501 1.118 1.118 0 .618-.501 1.119-1.118 1.119h-8.948c-.618 0-1.119-.501-1.119-1.119 
                                0-.617.501-1.118 1.12-1.118zm-27.962 0c.617 0 1.118.501 1.118 1.118 0 .618-.501 1.119-1.118 1.119h-3.356c-.617 0-1.119-.501-1.119-1.119 
                                0-.617.502-1.118 1.12-1.118zm14.54 0c.618 0 1.119.501 1.119 1.118 0 .618-.501 1.119-1.12 1.119H31.625c-.617 0-1.118-.501-1.118-1.119 
                                0-.617.501-1.118 1.118-1.118zM33.865 34.43c2.465 0 4.47 2.005 4.47 4.47v6.711c0 .618-.5 1.12-1.118 1.12H23.795c-.617 
                                0-1.119-.502-1.119-1.12v-6.71c0-2.466 2.006-4.471 4.472-4.471zm21.248-10.07c.617 0 1.118.501 1.118 1.119v20.132c0 .617-.501 1.119-1.118 
                                1.119H41.691c-.617 0-1.118-.502-1.118-1.119V25.48c0-.618.501-1.119 1.118-1.119zM33.865 36.668h-6.717c-1.232 0-2.234 1.002-2.234 
                                2.233v5.593h4.473V40.02c0-.576.437-1.051.997-1.112l.122-.007c.618 0 1.118.501 
                                1.118 1.12v4.473h4.475V38.9c0-1.231-1.003-2.233-2.234-2.233zm20.13-10.07H42.81v17.896h11.184V26.598zM50.638 40.02c.617 0 1.119.501 1.119 
                                1.118 0 .618-.502 1.119-1.12 1.119h-4.473c-.618 0-1.119-.501-1.119-1.119 0-.617.501-1.118 1.12-1.118zm0-5.592c.617 0 1.119.501 1.119 1.118
                                 0 .618-.502 1.119-1.12 1.119h-4.473c-.618 0-1.119-.501-1.119-1.12 0-.616.501-1.117 1.12-1.117zM30.506 23.246c2.467 0 4.474 2.007 4.474 
                                 4.474s-2.007 4.474-4.474 4.474-4.474-2.007-4.474-4.474 2.006-4.474 4.474-4.474zm20.133 5.59c.617 0 1.119.5 1.119 1.117 0 .618-.502 1.12-1.12 
                                 1.12h-4.473c-.618 0-1.119-.502-1.119-1.12 0-.616.501-1.118 1.12-1.118zm-20.133-3.353c-1.234 0-2.237 1.003-2.237 2.237 0 1.234 1.003 2.237 
                                 2.237 2.237 1.234 0 2.237-1.003 2.237-2.237 0-1.234-1.003-2.237-2.237-2.237zm8.948-16.78c-1.068 0-2.046.495-2.683 1.36-.918 1.249-2.387 
                                 1.995-3.928 1.995H29.98c-.943 0-1.71.768-1.71 1.711v5h22.369v-5c0-.943-.769-1.711-1.712-1.711h-2.862c-1.54 
                                 0-3.008-.746-3.928-1.994-.638-.866-1.615-1.362-2.683-1.362zm1.124 2.236c1.23 0 2.232 1.002 2.232 2.232v2.248c0 1.23-1.001 2.231-2.232 
                                 2.231H38.33c-1.23 0-2.231-1-2.231-2.23v-2.25c0-1.23 1-2.23 2.23-2.23zm-.006 2.232l-2.242.006.005 2.242 2.238-.006v-2.242z"
                transform="translate(-291 -716) translate(276 580) translate(15 136)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TabletIcon;
