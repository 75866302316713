import client from './client';

const ENDPOINT = '/synergies';

async function createSynergy(data) {
  const response = await client.post(`${ENDPOINT}/`, data);
  if (response.status === 201) {
    return response;
  }
}

async function uploadSynergies(data) {
  const response = await client.post(`${ENDPOINT}/batch-create`, data);
  if (response.status === 201) {
    return response;
  }
}

async function getSynergy(id) {
  const response = await client.get(`${ENDPOINT}/${id}/`);
  if (response.status === 200) {
    return response;
  }
}

async function getSynergies(params) {
  const response = await client.get(`${ENDPOINT}/`, { params });
  if (response.status === 200) {
    return response;
  }
}

async function updateSynergy({ id, data }) {
  const response = await client.put(`${ENDPOINT}/${id}/`, data);
  if (response.status === 200) {
    return response;
  }
}

async function deleteSynergy(id) {
  const response = await client.delete(`${ENDPOINT}/${id}/`);
  if (response.status === 200) {
    return response;
  }
}

export {
  createSynergy,
  uploadSynergies,
  getSynergy,
  getSynergies,
  updateSynergy,
  deleteSynergy,
};
