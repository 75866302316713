// Core
import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MapChart from 'components/MapChart/MapChart';
import Button from '@material-ui/core/Button';
import { Language, Mail, NavigateBefore, PhoneEnabled } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import DashboardInfoBlock from 'components/Dashboard/DashboardInfoBlock';
import DashboardChips from 'components/Dashboard/DashboardChips';
import Footer from 'components/blocks/Footer';
import useAsync from 'hooks/useAsync';
import { getNovator } from 'api/novators';
import { UserConsumer } from 'context/UserContext';
import { ROLES } from 'utils';
import { getCountryName, INDUSTRIES } from 'config/metaData';

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
  detailsWrapper: {
    margin: '3px',
    padding: '18px',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: 'calc(100% - 79px - 5px - 70px)',
      padding: '18px 45px',
    }
  },
  infoBlockHolder: {
    width: '50%',
  },
  contactsBlock: {
    border: '1px solid var(--border-color)',
    padding: '11px 16px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  sectionHeader: {
    padding: '18px',
    marginRight: '-4px',
    borderBottom: '1px solid var(--border-color)',
    height: '79px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '16px 48px',
    }
  },
}));
const InnovatorDetails = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [novator, setNovator] = useState(null);
  const { execute: loadNovators, value: novatorDetails } = useAsync(getNovator, false);
  useEffect(() => {
    console.log(id);
    loadNovators(id);
  }, [id, loadNovators]);

  useEffect(() => {
    console.log(novatorDetails);
    if (novatorDetails) {
      setNovator({
        ...novatorDetails,
        industries: novatorDetails.industries.map(item => INDUSTRIES[item.id]).map(item => ({ ...item, label: item.name })),
        synergies: novatorDetails.synergies.map(item => ({ ...item, label: item.name })),
      });
    }
  }, [novatorDetails]);

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} md={6} className={classes.fullHeight}>
        <Box className={classes.sectionHeader}>
          <Link to="/innovators">
            <Button className={classes.button} variant="outlined" startIcon={<NavigateBefore />} size="small" color="primary">
              Назад
            </Button>
          </Link>
        </Box>
        {novator ? (
          <Box className={classes.fullHeight}>
            <Box className={classes.detailsWrapper}>
              <Typography variant="h4" component="h4" gutterBottom>
                {novator.name}
                <UserConsumer>
                {({ loggedInUser }) => {
                  if (loggedInUser && loggedInUser.role.name === ROLES.cipe_admin) {
                    return <Button
                    component={Link}
                    to={`/manage-novator/${novator.id}`}
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                  >Edit</Button>
                  }
                  return null;
                }}
                </UserConsumer>
              </Typography>
              <Box className={classes.contactsBlock}>
                <Box className={classes.infoBlockHolder} sx={{ marginBottom: '18px' }}>
                  <DashboardInfoBlock title="Країна" content={getCountryName(novator.country_code)} icon={<Language fontSize="small" />} />
                </Box>
                <Box className={classes.infoBlockHolder} sx={{ marginBottom: '18px' }}>
                  <DashboardInfoBlock title="Імейл" content={novator.email} icon={<Mail fontSize="small" />} />
                </Box>
                <Box className={classes.infoBlockHolder}>
                  <DashboardInfoBlock title="Номер телефону" content={novator.contact} icon={<PhoneEnabled fontSize="small" />} />
                </Box>
                <Box className={classes.infoBlockHolder}>
                  <DashboardInfoBlock title="Веб сайт" content={novator.website} icon={<Language fontSize="small" />} />
                </Box>
              </Box>
              <Box className="text-block" sx={{ marginBottom: '20px' }}>
                
                <Typography variant="h5" component="h5" gutterBottom>
                  Про новатора
                </Typography>
                {novator.description}
              </Box>
              {/* <Box className="text-block" sx={{ marginBottom: '20px' }}>
                <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
                  Ключові напрями діяльності:
                </Typography>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
              </Box> */}
              <Box>
                <DashboardChips items={novator.synergies} title="Синергії" />
              </Box>
              <Box>
                <DashboardChips items={novator.industries} title="Індустрії" />
              </Box>
            </Box>
            <Footer showLogo px={6} />
          </Box>
        ) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
      </Grid>
      <Grid item xs={12} md={6} className={classes.mapChartWrapper}>
      {novator ? <MapChart lat={novator.lat || 30} lon={novator.lon || 30} address={novator.lon && novator.lat ? novator.address : 'Адреса не вказана'} /> : null}
      </Grid>
    </Grid>
  );
};

export default InnovatorDetails;
