import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ProfileForm from 'components/forms/ProfileForm';
import useAsync from 'hooks/useAsync';
import { updateCurrentUserCompamy } from 'api/users';
import { UserConsumer } from 'context/UserContext';
import { useSnackbar } from 'notistack';
import { ROLES } from 'utils';
import { CircularProgress } from '@material-ui/core';

export const ProfilePage = () => {
  const [userUpdateAction, setUserUpdateAction] = useState({});
  const [errors, setErrors] = useState();
  const { execute: onUpdateProfie, error: updateErrors, value, pending } = useAsync(updateCurrentUserCompamy, false);
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (data, updateUserData) => {
    const values = {...data};
    onUpdateProfie(values).then(() => updateUserData());
    // setUserUpdateAction()
  };

  useEffect(() => {
    if (value) {
      enqueueSnackbar('Profile has beed updated', {variant: 'success'})
      setErrors({})
      // if (userUpdateAction) {
        // userUpdateAction()
      // }
    }
  }, [value, userUpdateAction, enqueueSnackbar]);

  useEffect(() => {
    if (updateErrors) {
      console.log(updateErrors)
      const errs = {}
      if (updateErrors.errors['account name']) {
        errs.name = updateErrors.errors['account name']
        if (updateErrors.errors['account name'] === 'Account name already taken') {
          errs.name = 'Ім\'я облікового запису вже зайнято'
        }
      }
      if (updateErrors.errors['account email'] || updateErrors.errors['user email']) {
        errs.email = updateErrors.errors['account email'] || updateErrors.errors['user email']
        if (updateErrors.errors['account email'] === 'Account email already taken' || updateErrors.errors['user email'] === 'User email already taken') {
          errs.email = 'Email облікового запису вже зайнято'
        }
      }
      if (updateErrors.errors['company website']) {
        errs.website = updateErrors.errors['company website']
        if (updateErrors.errors['company website'] === "Incorrect field 'Company website'. Try again") {
          errs.website = 'URL-адреса недійсна'
        }
      }
      setErrors(errs)
      Object.keys(errs).forEach(key => {
        enqueueSnackbar(errs[key], {variant: 'error'})
      })
    } else {
      setErrors({})
    }
  }, [updateErrors])

  const onBlur = () => {
    setErrors({})
  }

  return (
    <Box>
      <Typography variant="h3" component="h1" gutterBottom>
        Налаштування профілю
      </Typography>
      <UserConsumer>
        {({ loggedInUser, updateUserData }) => {
          if (loggedInUser) {
            let user = null;
            if (loggedInUser.company) {
              const { id, name, country_code, region, address, lat, lon, website, contact, email, industries, description, key_activities } = loggedInUser.company;
              user = {id, name, country_code, region, address, lat, lon, website, contact, email,
              industries: industries.map(({ id, name }) => id),
              description, key_activities: key_activities ? key_activities : '', type: loggedInUser.role_id };
            }

            if (loggedInUser && loggedInUser.role.name=== ROLES.cipe_admin) {
              return <Box>Welcome CIPE Admin</Box>
            }
            
            return <ProfileForm initValue={user} externalErrors={errors || {}} formSubmit={(values) => onSubmit(values, updateUserData)} formBlur={onBlur} />;
          }
          return [];
        }}
      </UserConsumer>
      <Box>{ pending ? <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box> : null }</Box>
    </Box>
  );
};

export default ProfilePage;
