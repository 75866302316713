import React, { useEffect } from 'react';
import { useRouteMatch, useLocation, useHistory, useParams, Switch, Route, Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import useAsync from 'hooks/useAsync';
// Instruments
import { activateUser } from 'api/auth';
import ResetPasswordForm from 'components/forms/ResetPasswordForm';

export const ActivateUserPage = () => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const location = useLocation();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const { execute: onResetPassword, value: result, error: resetPasswordError } = useAsync(activateUser, false);

  const activateAccount = ({ values }) => {
    const activateData = {
      id: params.activation_token,
      values: {
        password: values.password,
        code: searchParams.get('code'),
      },
    };
    onResetPassword(activateData);
  };
  useEffect(() => {
    if (result) {
      const timeout = setTimeout(() => {
        push(`${url}/success`);
      }, 1500);
      return () => {
        clearTimeout(timeout);
      };
    }
    console.log(url);
  }, [result, push, url, params.activation_token]);

  return (
    <Box>
      <Switch>
        <Route exact path={url}>
          <Typography variant="h3" component="h1" gutterBottom>
            Налаштування паролю
          </Typography>
        </Route>
        <Route path={`${url}/success`}>
          <Typography variant="h3" component="h1" gutterBottom>
            Пароль налаштований успішно
          </Typography>
          <Box component="h2" fontSize={14} fontWeight={500} color="text.secondary">
            Тепер ви можете увійти на платформу, використовуючи e-mail та створений пароль.
          </Box>
        </Route>
      </Switch>

      <Box py={3}>
        <Switch>
          <Route exact path={url}>
            <ResetPasswordForm formResetPassword={activateAccount} resetPasswordError={resetPasswordError} />
          </Route>
          <Route path={`${url}/success`}>
            <Button variant="contained" color="primary" component={Link} to="/login">
              Зрозуміло
            </Button>
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default ActivateUserPage;
