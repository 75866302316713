const transformObjWithKeysToArr = obj => {
  return Object.keys(obj)
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, [])
    .filter(el => el !== null);
};

const reduceObjOnGivenKeys = (obj, ...arrOfKeys) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (!arrOfKeys.includes(key)) {
      return acc;
    }

    if (arrOfKeys.includes(key) && obj[key] && typeof obj[key] === 'object') {
      acc[key] = obj[key].name;
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

const checkIfAllValsAreFalsy = array => {
  const new_arr = array.slice();
  new_arr.map((item, index) => {
    const isEmptyObject = Object.values(item).every(val => {
      if (val !== 'Entity' && val !== 'Individual') {
        return !val;
      }
      return true;
    });
    if (isEmptyObject) {
      array.splice(index, 1);
    }
    return item;
  });
  return array;
};

// Method for 'entity' or 'individual' object
const checkIfAllValuesOfObjectAreFalsy = object => {
  const isEmpty = Object.values(object).every(val => {
    if (val === 'Entity' || val === 'Individual') {
      return true;
    }
    return !val;
  });
  return isEmpty;
};

const sortArrayOfObjects = (sortBy, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(sortBy) || !b.hasOwnProperty(sortBy)) {
      return 0;
    }

    const varA = typeof a[sortBy] === 'string' ? a[sortBy].toUpperCase() : a[sortBy];
    const varB = typeof b[sortBy] === 'string' ? b[sortBy].toUpperCase() : b[sortBy];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

const getLocationQueryVariable = (location, variable) => {
  const query = location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
};

const fileExists = url => {
  const http = new XMLHttpRequest();
  let isText = false;
  http.open('GET', url, false);
  http.send();
  if (http.status === 200 && http.readyState === 4) {
    isText = http.responseText.search('<!doctype html>') === -1;
  }
  return http.status !== 404 && isText;
};

const transformProjectId = idNum => {
  for (const i of idNum) {
    if (Number.isNaN(parseInt(i))) {
      return idNum;
    }
  }
  return parseInt(idNum).toString();
};

function linkify(inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" class="hyperlink">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" class="hyperlink">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" class="hyperlink">$1</a>');

  return replacedText;
}

export { checkIfAllValsAreFalsy, fileExists, getLocationQueryVariable, reduceObjOnGivenKeys, sortArrayOfObjects, transformObjWithKeysToArr, transformProjectId, checkIfAllValuesOfObjectAreFalsy, linkify };
