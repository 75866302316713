import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { Box, Typography, CircularProgress, Button } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getSynergy ,createSynergy, updateSynergy, deleteSynergy } from "api/synergies";
import { useHistory, useParams } from "react-router-dom";
import SynergyForm from "components/forms/SynergyForm";


const ManageSynergyPage = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { execute, value, error, pending } = useAsync( id ? updateSynergy : createSynergy, false);
  const [externalErrors, setExternalErrors] = useState({});
  const [activeAction, setActiveAction] = useState();

  const { execute: fetchItem, value: itemResponse, pendingItem } = useAsync(getSynergy, false);
  const [item, setItem] = useState();

  useEffect(() => {
    if (id) {
      fetchItem(id)
    }
  }, [id, fetchItem]);
  useEffect(() => {
    if (itemResponse) {
      setItem({
        ...itemResponse,
        applicants: itemResponse.applicants.map(item => item.id),
        novators: itemResponse.novators.map(item => item.id)
      })
    }
  }, [setItem, itemResponse])

  useEffect(() => {
    if (id) {
      if (value) {
        enqueueSnackbar('Synergy has been updated', {variant: 'success'})
      } else if (error) {
        enqueueSnackbar('There is error', {variant: 'error'})
        setExternalErrors(error)
      }
    } else {
      if (value) {
        enqueueSnackbar('Synergy has been created', {variant: 'success'})
        activeAction.resetForm()
      } else if (error) {
        enqueueSnackbar('There is error', {variant: 'error'})
        setExternalErrors(error)
      }
    }
  }, [id, execute, value, error, activeAction]);

  const handleSubmit = (values, actions) => {
    setActiveAction(actions)
    if (id) {
      execute({id, data: values});
    } else {
      execute(values);
    }
  }

  const { execute: deleteItem, value: deleteItemResponse, error: deleteItemError } = useAsync(deleteSynergy, false);
  const removeItem = () => {
    if (confirm('Are you sure?')) {
        deleteItem(id)
    }
  }

  const { push } = useHistory()
  useEffect(() => {
    if (deleteItemResponse) {
      enqueueSnackbar('Synergy has been deleted', {variant: 'success'})
      push('/synergies')
    }
    if (deleteItemError) {
      enqueueSnackbar('There is error', {variant: 'error'})
    }
  }, [deleteItemResponse, deleteItemError])
  
  return <Box p={6}>
    <Box py={4}>
      <Typography component="h2">{id ? 'Update' : 'Create'} Synergy</Typography>
      {id ? <Button
                  onClick={removeItem}
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteForeverIcon />}
                >Delete</Button> : null}
    </Box>
    { (pending || pendingItem) ? <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box> : null}
    {
      id ? item ? <SynergyForm initValues={item} handleSubmit={handleSubmit} externalErrors={externalErrors} isUpdate={id} loading={pending} /> : null
      :  <SynergyForm handleSubmit={handleSubmit} externalErrors={externalErrors} loading={pending} />
    }
      
  </Box>
  
}

export default ManageSynergyPage;