import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Components
import FormikInput from 'components/ui/FormikInput';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// Constants
import { PASSWORD_REGEX } from 'utils';

const initialValues = { oldPassword: '', password: '', confirmPassword: '' };
const fieldLables = {
  oldPassword: 'Введіть старий пароль',
  password: 'Введіть новий пароль',
  confirmPassword: 'Підтвердіть новий пароль',
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Поле є обов\'язковим'),
  password: Yup.string()
    .min(8, 'Пароль має містити принайні 8 символів')
    .max(16, 'Пароль не має перевищувати 16 символів')
    .matches(PASSWORD_REGEX, {
      message: 'Пароль має містити літери верхнього та нижнього регістру, цифри, та принайні один із цих спец символів @#$%^&+=',
      excludeEmptyString: true,
    })
    .required('Поле є обов\'язковим'),

  confirmPassword: Yup.string()
    .required('Поле є обов\'язковим')
    .matches(PASSWORD_REGEX, {
      message: 'Пароль занадто легкий',
      excludeEmptyString: true,
    })
    .oneOf([Yup.ref('password'), null], 'Пароль має співпадати'),
});

const PasswordSettingForm = ({ formSubmit, cancelLink = '/login', errorReport }) => {
  const codeError = errorReport && errorReport.message;
  const passwordError = errorReport && errorReport.password;
  const renderForm = values => {
    const form = Object.keys(values).map(key => {
      const type = key === 'code' ? 'text' : 'password';
      const error = key === 'code' ? codeError : passwordError;

      return <FormikInput key={key} mandatory type={type} offMultiline={key === 'code'} id={key} name={key} label={fieldLables[key]} value={values[key]} apiError={error} />;
    });

    return form;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={formSubmit}>
      {({ values, isSubmitting, isValid }) => (
        <Form>
          {renderForm(values)}
          <Box display="flex" justifyContent="flex-end">
            <Box component="span" pr={2}>
              <Button variant="outlined" color="primary" component={Link} to={cancelLink}>
                Відмінити
              </Button>
            </Box>

            <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
              Зберегти
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

PasswordSettingForm.defaultProps = {
  cancelLink: '/login',
};

PasswordSettingForm.propTypes = {
  formSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string,
};
export default PasswordSettingForm;
