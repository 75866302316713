import React from 'react';

const EyeInMonitorIcon = props => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 80 80">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H80V80H0z" transform="translate(-291 -340) translate(276 204) translate(15 136)" />
              <path
                fill={color || '#FFF'}
                d="M71.198 7C73.846 7 76 9.148 76 11.787v37.07l.001.018v5.98c0 2.64-2.154 4.788-4.802 4.788l-23.964.001c.278 4.765 2.281 7.178 
                        5.966 7.178h2.4c1.984 0 3.6 1.61 3.6 3.589C59.2 72.39 57.585 74 55.6 74H24.4c-1.985 0-3.6-1.61-3.6-3.59 0-1.978 1.615-3.588 
                        3.6-3.588h2.4c3.685 0 5.688-2.413 5.966-7.178H8.801C6.154 59.643 4 57.495 4 54.855v-43.07C4 9.149 6.154 7 8.8 7zM55.6 69.215H24.4c-.662 
                        0-1.2.537-1.2 1.196 0 .66.538 1.196 1.2 1.196h31.2c.662 0 1.2-.537 1.2-1.196 0-.659-.538-1.196-1.2-1.196zm-10.767-9.572h-9.666c-.175 
                        3.117-1.045 5.547-2.522 7.179h14.71c-1.477-1.632-2.347-4.062-2.522-7.179zM73.6 50.072H6.4v4.784c0 1.321 1.079 2.394 2.402 2.394h62.397c1.324 
                        0 2.401-1.073 2.401-2.394v-4.784zM43.6 52.464c.662 0 1.2.537 1.2 1.197s-.538 1.196-1.2 1.196h-7.2c-.663 0-1.2-.536-1.2-1.196 0-.66.537-1.197 
                        1.2-1.197zM71.198 9.393H8.801c-1.323 0-2.401 1.073-2.401 2.394v35.892h2.4V12.982c0-.619.473-1.128 1.077-1.19l.123-.006h60c.662 0 1.2.536 1.2 1.196l-.001 
                        34.697h2.4V11.787c0-1.32-1.077-2.394-2.4-2.394zM68.8 14.179H11.2v33.5h57.6v-33.5zm-28.797 2.393c5.33 0 10.326 2.454 14.07 6.906l6.017 
                        6.65c.4.443.413 1.115.026 1.571l-6.045 7.161c-3.882 4.166-8.909 6.426-14.213 
                        6.426h-.25c-5.223-.071-10.076-2.345-13.662-6.405l-.019-.021-6.045-7.16c-.387-.457-.374-1.129.026-1.573l6.046-6.681c3.719-4.422 8.718-6.874 
                        14.047-6.874zm0 2.393c-4.611 0-8.956 2.148-12.24 6.05l-5.373 5.94 5.364 6.355c3.133 3.539 7.354 5.522 11.886 5.583 4.705.084 9.198-1.931 
                        12.636-5.617l5.332-6.318-5.345-5.908c-3.308-3.936-7.652-6.085-12.26-6.085zM40 23.75c3.97 0 7.2 3.22 7.2 7.179s-3.23 7.178-7.2 7.178-7.2-3.219-7.2-7.178 
                        3.23-7.179 7.2-7.179zm0 2.393c-2.648 0-4.8 2.147-4.8 4.786 0 2.64 2.152 4.785 4.8 4.785 2.647 0 4.8-2.146 4.8-4.785 0-2.64-2.153-4.786-4.8-4.786z"
                transform="translate(-291 -340) translate(276 204) translate(15 136)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EyeInMonitorIcon;
