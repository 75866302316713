// Core
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  chipItem: {
    margin: '2px',
    '& .MuiChip-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '248px',
      }
    }
  },
}));
const DashboardChips = ({ items, title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardChips}>
      <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
        {title}
      </Typography>
      <Box component="ul" display="flex" flexWrap="wrap">
        {items && items.length ? (
          items.map((data, index) => {
            return (
              <Box component="li" key={data.label + index} className={classes.chipItem}>
                <Chip label={data.label} className={classes.chip}></Chip>
              </Box>
            );
          })
        ) : (
          <Box component="li" className={classes.chipItem}>
            <Chip label="-" className={classes.chip}></Chip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

DashboardChips.defaultProps = {
  items: ['-'],
  title: 'Індустрії',
};

DashboardChips.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

export default DashboardChips;
