// Core
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DashboardCard from 'components/Dashboard/DashboardCard';
import DashboardSearch from 'components/Dashboard/DashboardSearch';
import Footer from 'components/blocks/Footer';
import { getSynergies } from 'api/synergies';
import useAsync from 'hooks/useAsync';
import { useDebounce } from 'hooks/useDebounce';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { UserConsumer } from 'context/UserContext';
import { MapContainer, TileLayer, Popup } from 'react-leaflet';
import { ROLES } from 'utils';
import { Link } from 'react-router-dom';
import { getCountryName } from 'config/metaData';

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
  cardsSearch: {
    padding: '0 12px',
    [theme.breakpoints.up('md')]: {
      padding: '0 48px',
    }
  },
  cardsWrapper: {
    padding: '0 12px',
    margin: '3px',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: 'calc(100% - 79px - 5px - 89px - 70px)',
      padding: '0 48px',
    }
  },
  sectionHeader: {
    padding: '16px 48px',
    marginRight: '-4px',
    borderBottom: '1px solid var(--border-color)',
    height: '79px',
    display: 'flex',
    alignItems: 'center',
  },
  map: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '100%',
    }
  },
  tooltip: {
    '& .leaflet-popup-content-wrapper': {
      background: 'rgba(6, 20, 39, .7)',
      color: 'white',
      fontSize: '14px',
      fontWeight: 700
    },
    '& .leaflet-popup-tip': {
      background: 'rgba(6, 20, 39, .7)',
    },
    '& .leaflet-popup-close-button': {
      display: 'none'
    }
  }
}));
const pageLimit = 10;
const Synergies = () => {
  const classes = useStyles();
  

  const [list, setList] = useState();
  const { execute: loadList, value: loadedList, pending: pendingList } = useAsync(getSynergies, false);
  const [pages, setPages] = useState(1);
  const [pagesCount, setPagesCount] = useState(pageLimit);
  const [totalAmountOfItems, setTotalAmountOfItems] = useState(0)

  useEffect(() => {
    if (loadedList) {
      const {data, total} = loadedList;
      setList(
        data.map(item => ({
          ...item,
          applicants: item.applicants.map(item => ({ ...item, label: item.name })),
          novators: item.novators.map(item => ({ ...item, label: item.name })),
        })),
      );
      setPagesCount(data.length)
      setTotalAmountOfItems(total);
      setPages(Math.ceil(total / pageLimit));
    }
  }, [loadedList, pageLimit]);

  const searchFilters = [
    {label: 'За назвою', value: 'name'},
    {label: 'За країною', value: 'country'},
    {label: 'За описом', value: 'description'},
  ];
  const sortItems = [
    {label: 'За замовчуванням', value: 'id'},
    {label: 'За кількістю синергій', value: 'synergy'},
    {label: 'За алфавітом', value: 'name'},
  ];
  const [selected, setSelected] = useState('name');
  const [sortSelected, setSortSelected] = useState('id');
  const [searchText, setSearchText] = useState('');
  const onSelectChange = event => {
    setSelected(event.target.value);
  };
  const onSortSelectChange = event => {
    setSortSelected(event.target.value);
  };
  const onSearchChange = event => {
    setPage(1);
    setSearchText(event.target.value);
  };

  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFiltersChange = useDebounce(() => {
    
    const params = {
      filter_value: searchText,
      filter_by: selected,
      order_by: sortSelected,
      offset: (page - 1) * pageLimit,
      limit: pageLimit,
    };
    setList(null);
    loadList( params)
    
  }, 400);

  useEffect(handleFiltersChange, [page, selected, sortSelected, searchText]);

  const mapCenter = ["30.30", "30.30"];
  const zoom = 14;
  const [map, setMap] = useState(null);
  const [activeItem, setActiveItem] = useState({})
  const displayMap = useMemo(() => {
    
    return <MapContainer ref={setMap} className={classes.map} center={mapCenter} zoom={zoom}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
      {activeItem.lat && activeItem.lon && activeItem.address ? <Popup className={classes.tooltip} position={[activeItem.lat, activeItem.lon]} permanent>{activeItem.address}</Popup> : null}
    </MapContainer>
  })

  const mouseEnterHandler = (item) => {
    if (item.lat && item.lon) {
      setActiveItem(item)
      map.setView([item.lat, item.lon], zoom)
    } else {
      setActiveItem({ ...item, lat: 30, lon: 30, address: 'Адреса не вказана'}, zoom)
      map.setView([30, 30], zoom)
    }
  }
  const mouseLeaveHandler = (item) => {
    console.log('leave', item)
  }

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} md={6} className={classes.fullHeight}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h4" component="h4">
            Синергії
            <UserConsumer>
              {({ loggedInUser }) => {
                if (loggedInUser && loggedInUser.role.name === ROLES.cipe_admin) {
                  return <Button
                    component={Link}
                    to={`/manage-synergies`}
                    variant="contained"
                    color="primary"
                    startIcon={<NoteAddIcon />}
                  >Create</Button>
                }
                return null;
              }}
            </UserConsumer>
          </Typography>
        </Box>
        <Box className={classes.fullHeight}>
          <Box className={classes.cardsSearch}>
            <DashboardSearch
              selectItems={searchFilters}
              sortItems={sortItems}
              selected={selected}
              sortSelected={sortSelected}
              onSelectChange={onSelectChange}
              onSearchChange={onSearchChange}
              onSortSelectChange={onSortSelectChange}
            />
          </Box>
          <Box className={classes.fullHeight}>
            <Box className={classes.cardsWrapper}>
              <Box>
                <UserConsumer>
                {({ loggedInUser }) => {
                  return list ? list.map(item => {
                    const items = [
                      {
                        title: 'Підприємці',
                        items: item.applicants
                      },
                      {
                        title: 'Новатори',
                        items: item.novators
                      }
                    ]
                    return (
                      <DashboardCard
                        key={item.id}
                        name={item.name}
                        country={getCountryName(item.country_code)} 
                        description={item.description}
                        items={items}
                        itemLink={loggedInUser ? `/synergies/${item.id}` : null}
                        mouseEnterHandler={() => mouseEnterHandler(item)}
                        mouseLeaveHandler={() => mouseLeaveHandler(item)}
                      />
                    );
                  }) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>
                }}
                </UserConsumer>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography color="textSecondary" variant="h6" component="h6">
                  Показано {pagesCount} аплікантів з {totalAmountOfItems}
                </Typography>
                {totalAmountOfItems > pageLimit ?
                  <Pagination
                    page={page}
                    count={pages}
                    onChange={handlePageChange}
                    shape="rounded"
                    color="primary"
                    hidePrevButton={page === 1}
                    hideNextButton={page === pages}
                    renderItem={item => {
                      if (item.type === 'next') {
                        return (
                          <IconButton {...item} variant="outlined" size="medium">
                            <ArrowRight fontSize="inherit" color="primary" />
                          </IconButton>
                        );
                      }
                      if (item.type === 'previous') {
                        return (
                          <IconButton {...item} variant="outlined" size="medium">
                            <ArrowLeft fontSize="inherit" color="primary" />
                          </IconButton>
                        );
                      }
                      return <PaginationItem {...item} />;
                    }}
                  />
                  : null
                }
              </Box>
            </Box>
            <Footer showLogo px={6} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} className={classes.mapChartWrapper}>
        {displayMap}
      </Grid>
    </Grid>
  );
};

export default Synergies;
