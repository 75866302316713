export const COUNTRIES = {
  "AFG": {
    "capital": "Kabul",
    "code": "AFG",
    "cpi_score": 16,
    "lat": 34.526,
    "lng": 69.1777,
    "name": "Афганістан",
    "score": "H"
  },
  "ALB": {
    "capital": "Tirana",
    "code": "ALB",
    "cpi_score": 35,
    "lat": 41.3269,
    "lng": 19.8188,
    "name": "Албанія",
    "score": "H"
  },
  "DZA": {
    "capital": "Algiers",
    "code": "DZA",
    "cpi_score": 33,
    "lat": 36.7754,
    "lng": 3.06019,
    "name": "Алжир",
    "score": "H"
  },
  "AND": {
    "capital": "Andorra la Vella",
    "code": "AND",
    "cpi_score": 0,
    "lat": 42.5063,
    "lng": 1.5218,
    "name": "Андорра",
    "score": ""
  },
  "AGO": {
    "capital": "Luanda",
    "code": "AGO",
    "cpi_score": 29,
    "lat": -8.82727,
    "lng": 13.244,
    "name": "Ангола",
    "score": "H"
  },
  "ATG": {
    "capital": "Saint John's",
    "code": "ATG",
    "cpi_score": 0,
    "lat": 47.5617,
    "lng": -52.7151,
    "name": "Антигуа і Барбуда",
    "score": ""
  },
  "ARG": {
    "capital": "Buenos Aires",
    "code": "ARG",
    "cpi_score": 38,
    "lat": -34.6076,
    "lng": -58.4371,
    "name": "Аргентина",
    "score": "M"
  },
  "ARM": {
    "capital": "Yerevan",
    "code": "ARM",
    "cpi_score": 49,
    "lat": 40.1776,
    "lng": 44.5126,
    "name": "Вірменія",
    "score": "M"
  },
  "AUS": {
    "capital": "Canberra",
    "code": "AUS",
    "cpi_score": 73,
    "lat": -35.2976,
    "lng": 149.101,
    "name": "Австралія",
    "score": "L"
  },
  "AUT": {
    "capital": "Vienna",
    "code": "AUT",
    "cpi_score": 74,
    "lat": 48.2084,
    "lng": 16.3725,
    "name": "Австрія",
    "score": "L"
  },
  "AZE": {
    "capital": "Baku",
    "code": "AZE",
    "cpi_score": 30,
    "lat": 40.3754,
    "lng": 49.8327,
    "name": "Азербайджан",
    "score": "H"
  },
  "BHR": {
    "capital": "Manama",
    "code": "BHR",
    "cpi_score": 42,
    "lat": 26.2235,
    "lng": 50.5822,
    "name": "Бахрейн",
    "score": "M"
  },
  "BGD": {
    "capital": "Dhaka",
    "code": "BGD",
    "cpi_score": 26,
    "lat": 23.7594,
    "lng": 90.3788,
    "name": "Бангладеш",
    "score": "H"
  },
  "BRB": {
    "capital": "Bridgetown",
    "code": "BRB",
    "cpi_score": 65,
    "lat": 13.0978,
    "lng": -59.6184,
    "name": "Барбадос",
    "score": "L"
  },
  "BLR": {
    "capital": "Minsk",
    "code": "BLR",
    "cpi_score": 41,
    "lat": 53.9023,
    "lng": 27.5619,
    "name": "Білорусь",
    "score": "M"
  },
  "BEL": {
    "capital": "Brussels",
    "code": "BEL",
    "cpi_score": 73,
    "lat": 50.8466,
    "lng": 4.3517,
    "name": "Бельгія",
    "score": "L"
  },
  "BLZ": {
    "capital": "Belmopan",
    "code": "BLZ",
    "cpi_score": 0,
    "lat": 17.2502,
    "lng": -88.77,
    "name": "Беліз",
    "score": ""
  },
  "BEN": {
    "capital": "Porto-Novo",
    "code": "BEN",
    "cpi_score": 42,
    "lat": 6.49907,
    "lng": 2.62534,
    "name": "Бенін",
    "score": "M"
  },
  "BTN": {
    "capital": "Thimphu",
    "code": "BTN",
    "cpi_score": 68,
    "lat": 27.4728,
    "lng": 89.6295,
    "name": "Бутан",
    "score": "L"
  },
  "BOL": {
    "capital": "Sucre",
    "code": "BOL",
    "cpi_score": 30,
    "lat": -19.0477,
    "lng": -65.2594,
    "name": "Болівія",
    "score": "H"
  },
  "BIH": {
    "capital": "Sarajevo",
    "code": "BIH",
    "cpi_score": 35,
    "lat": 43.852,
    "lng": 18.3867,
    "name": "Боснія і Герцеговина",
    "score": "H"
  },
  "BWA": {
    "capital": "Gaborone",
    "code": "BWA",
    "cpi_score": 55,
    "lat": -24.6581,
    "lng": 25.9088,
    "name": "Ботсвана",
    "score": "L"
  },
  "BRA": {
    "capital": "Brasília",
    "code": "BRA",
    "cpi_score": 38,
    "lat": -10.3333,
    "lng": -53.2,
    "name": "Бразилія",
    "score": "H"
  },
  "BRN": {
    "capital": "Bandar Seri Begawan",
    "code": "BRN",
    "cpi_score": 60,
    "lat": 4.88954,
    "lng": 114.942,
    "name": "Бруней",
    "score": "L"
  },
  "BGR": {
    "capital": "Sofia",
    "code": "BGR",
    "cpi_score": 42,
    "lat": -15.2538,
    "lng": 48.2562,
    "name": "Болгарія",
    "score": "M"
  },
  "BFA": {
    "capital": "Ouagadougou",
    "code": "BFA",
    "cpi_score": 42,
    "lat": 12.3682,
    "lng": -1.52709,
    "name": "Буркіна Фасо",
    "score": "M"
  },
  "BDI": {
    "capital": "Bujumbura",
    "code": "BDI",
    "cpi_score": 19,
    "lat": -3.36381,
    "lng": 29.3675,
    "name": "Бурунді",
    "score": "H"
  },
  "CPV": {
    "capital": "Praia",
    "code": "CPV",
    "cpi_score": 58,
    "lat": 14.916,
    "lng": -23.5096,
    "name": "Кабо Верде",
    "score": "M"
  },
  "KHM": {
    "capital": "Phnom Penh",
    "code": "KHM",
    "cpi_score": 23,
    "lat": 11.5683,
    "lng": 104.922,
    "name": "Камбоджа",
    "score": "L"
  },
  "CMR": {
    "capital": "Yaoundé",
    "code": "CMR",
    "cpi_score": 27,
    "lat": 3.86899,
    "lng": 11.5213,
    "name": "Камерун",
    "score": "H"
  },
  "CAN": {
    "capital": "Ottawa",
    "code": "CAN",
    "cpi_score": 74,
    "lat": 45.4211,
    "lng": -75.6903,
    "name": "Канада",
    "score": "L"
  },
  "CAF": {
    "capital": "Bangui",
    "code": "CAF",
    "cpi_score": 24,
    "lat": 4.39072,
    "lng": 18.5509,
    "name": "Центральноафриканська Республіка",
    "score": "H"
  },
  "TCD": {
    "capital": "N'Djamena",
    "code": "TCD",
    "cpi_score": 20,
    "lat": 12.1192,
    "lng": 15.0503,
    "name": "Чад",
    "score": "H"
  },
  "CHL": {
    "capital": "Santiago",
    "code": "CHL",
    "cpi_score": 67,
    "lat": 9.86948,
    "lng": -83.7981,
    "name": "Чилі",
    "score": "L"
  },
  "CHN": {
    "capital": "Beijing",
    "code": "CHN",
    "cpi_score": 45,
    "lat": 39.9062,
    "lng": 116.391,
    "name": "Китай",
    "score": "M"
  },
  "COL": {
    "capital": "Bogotá",
    "code": "COL",
    "cpi_score": 39,
    "lat": 4.59808,
    "lng": -74.076,
    "name": "Колумбія",
    "score": "H"
  },
  "COM": {
    "capital": "Moroni",
    "code": "COM",
    "cpi_score": 20,
    "lat": -11.6931,
    "lng": 43.2543,
    "name": "Коморські острови",
    "score": "H"
  },
  "COK": {
    "capital": "Avarua",
    "code": "COK",
    "cpi_score": 0,
    "lat": -21.2075,
    "lng": -159.771,
    "name": "Острови Кука",
    "score": ""
  },
  "CRI": {
    "capital": "San José",
    "code": "CRI",
    "cpi_score": 58,
    "lat": 9.93254,
    "lng": -84.0796,
    "name": "Коста-Ріка",
    "score": "M"
  },
  "HRV": {
    "capital": "Zagreb",
    "code": "HRV",
    "cpi_score": 47,
    "lat": 45.8132,
    "lng": 15.977,
    "name": "Хорватія",
    "score": "M"
  },
  "CUB": {
    "capital": "Havana",
    "code": "CUB",
    "cpi_score": 46,
    "lat": 23.1353,
    "lng": -82.359,
    "name": "Куба",
    "score": "M"
  },
  "CYP": {
    "capital": "Nicosia",
    "code": "CYP",
    "cpi_score": 53,
    "lat": 35.1739,
    "lng": 33.3647,
    "name": "Кіпр",
    "score": "M"
  },
  "CZE": {
    "capital": "Prague",
    "code": "CZE",
    "cpi_score": 54,
    "lat": 50.0875,
    "lng": 14.4213,
    "name": "Чеська Республіка",
    "score": "M"
  },
  "COD": {
    "capital": "Kinshasa",
    "code": "COD",
    "cpi_score": 19,
    "lat": -4.32171,
    "lng": 15.3126,
    "name": "Демократична Республіка Конго",
    "score": "H"
  },
  "DNK": {
    "capital": "Copenhagen",
    "code": "DNK",
    "cpi_score": 88,
    "lat": 55.6867,
    "lng": 12.5701,
    "name": "Данія",
    "score": "L"
  },
  "DJI": {
    "capital": "Djibouti",
    "code": "DJI",
    "cpi_score": 30,
    "lat": 11.8146,
    "lng": 42.8453,
    "name": "Джібуті",
    "score": "H"
  },
  "DMA": {
    "capital": "Roseau",
    "code": "DMA",
    "cpi_score": 55,
    "lat": 48.771,
    "lng": -95.7698,
    "name": "Домініка",
    "score": "M"
  },
  "DOM": {
    "capital": "Santo Domingo",
    "code": "DOM",
    "cpi_score": 30,
    "lat": 18.4802,
    "lng": -69.9421,
    "name": "Домініканська республіка",
    "score": "H"
  },
  "TLS": {
    "capital": "Dili",
    "code": "TLS",
    "cpi_score": 41,
    "lat": 28.6517,
    "lng": 77.2219,
    "name": "Східний Тимор",
    "score": "M"
  },
  "ECU": {
    "capital": "Quito",
    "code": "ECU",
    "cpi_score": 36,
    "lat": -0.220164,
    "lng": -78.5123,
    "name": "Еквадор",
    "score": "H"
  },
  "EGY": {
    "capital": "Cairo",
    "code": "EGY",
    "cpi_score": 33,
    "lat": 30.0488,
    "lng": 31.2437,
    "name": "Єгипет",
    "score": "H"
  },
  "SLV": {
    "capital": "San Salvador",
    "code": "SLV",
    "cpi_score": 34,
    "lat": 13.699,
    "lng": -89.1914,
    "name": "Сальвадор",
    "score": "H"
  },
  "GNQ": {
    "capital": "Malabo",
    "code": "GNQ",
    "cpi_score": 17,
    "lat": 3.75283,
    "lng": 8.78006,
    "name": "Екваторіальна Гвінея",
    "score": "H"
  },
  "ERI": {
    "capital": "Asmara",
    "code": "ERI",
    "cpi_score": 22,
    "lat": 15.339,
    "lng": 38.9327,
    "name": "Еритрея",
    "score": "H"
  },
  "EST": {
    "capital": "Tallinn",
    "code": "EST",
    "cpi_score": 74,
    "lat": 59.4372,
    "lng": 24.7454,
    "name": "Естонія",
    "score": "L"
  },
  "SWZ": {
    "capital": "Lobamba",
    "code": "SWZ",
    "cpi_score": 32,
    "lat": -26.4463,
    "lng": 31.2084,
    "name": "Есватіні",
    "score": "H"
  },
  "ETH": {
    "capital": "Addis Ababa",
    "code": "ETH",
    "cpi_score": 39,
    "lat": 9.01079,
    "lng": 38.7613,
    "name": "Ефіопія",
    "score": "H"
  },
  "FSM": {
    "capital": "Palikir",
    "code": "FSM",
    "cpi_score": 0,
    "lat": 6.92074,
    "lng": 158.163,
    "name": "Федеративні Штати Мікронезії",
    "score": ""
  },
  "FJI": {
    "capital": "Suva",
    "code": "FJI",
    "cpi_score": 55,
    "lat": -18.1416,
    "lng": 178.442,
    "name": "Фіджі",
    "score": ""
  },
  "FIN": {
    "capital": "Helsinki",
    "code": "FIN",
    "cpi_score": 88,
    "lat": 60.1674,
    "lng": 24.9426,
    "name": "Фінляндія",
    "score": "L"
  },
  "FRA": {
    "capital": "Paris",
    "code": "FRA",
    "cpi_score": 71,
    "lat": 48.8567,
    "lng": 2.35146,
    "name": "Франція",
    "score": "L"
  },
  "GAB": {
    "capital": "Libreville",
    "code": "GAB",
    "cpi_score": 31,
    "lat": 0.390002,
    "lng": 9.454,
    "name": "Габон",
    "score": "H"
  },
  "GEO": {
    "capital": "Tbilisi",
    "code": "GEO",
    "cpi_score": 55,
    "lat": 41.6935,
    "lng": 44.8014,
    "name": "Грузія",
    "score": "M"
  },
  "DEU": {
    "capital": "Berlin",
    "code": "DEU",
    "cpi_score": 80,
    "lat": 52.517,
    "lng": 13.3889,
    "name": "Німеччина",
    "score": "L"
  },
  "GHA": {
    "capital": "Accra",
    "code": "GHA",
    "cpi_score": 43,
    "lat": 5.56001,
    "lng": -0.205744,
    "name": "Гана",
    "score": "M"
  },
  "GRC": {
    "capital": "Athens",
    "code": "GRC",
    "cpi_score": 49,
    "lat": 37.9839,
    "lng": 23.7283,
    "name": "Греція",
    "score": "M"
  },
  "GRD": {
    "capital": "St. George's",
    "code": "GRD",
    "cpi_score": 53,
    "lat": 48.6581,
    "lng": 6.9281,
    "name": "Гренада",
    "score": "M"
  },
  "GTM": {
    "capital": "Guatemala City",
    "code": "GTM",
    "cpi_score": 25,
    "lat": 14.6222,
    "lng": -90.5185,
    "name": "Гватемала",
    "score": "H"
  },
  "GIN": {
    "capital": "Conakry",
    "code": "GIN",
    "cpi_score": 25,
    "lat": 9.51706,
    "lng": -13.6998,
    "name": "Гвінея",
    "score": "H"
  },
  "GNB": {
    "capital": "Bissau",
    "code": "GNB",
    "cpi_score": 21,
    "lat": 11.8613,
    "lng": -15.5831,
    "name": "Гвінея-Бісау",
    "score": "H"
  },
  "GUY": {
    "capital": "Georgetown",
    "code": "GUY",
    "cpi_score": 39,
    "lat": 6.80258,
    "lng": -58.1629,
    "name": "Гайана",
    "score": "M"
  },
  "HTI": {
    "capital": "Port-au-Prince",
    "code": "HTI",
    "cpi_score": 20,
    "lat": 18.5473,
    "lng": -72.3396,
    "name": "Гаїті",
    "score": "H"
  },
  "HND": {
    "capital": "Tegucigalpa",
    "code": "HND",
    "cpi_score": 23,
    "lat": 14.1057,
    "lng": -87.2047,
    "name": "Гондурас",
    "score": "H"
  },
  "HKG": {
    "capital": "City of Victoria",
    "code": "HKG",
    "cpi_score": 76,
    "lat": 22.3027,
    "lng": 114.177,
    "name": "Гонконг",
    "score": "L"
  },
  "HUN": {
    "capital": "Budapest",
    "code": "HUN",
    "cpi_score": 43,
    "lat": 47.4984,
    "lng": 19.0405,
    "name": "Угорщина",
    "score": "M"
  },
  "ISL": {
    "capital": "Reykjavik",
    "code": "ISL",
    "cpi_score": 74,
    "lat": 64.146,
    "lng": -21.9422,
    "name": "Ісландія",
    "score": "L"
  },
  "IND": {
    "capital": "New Delhi",
    "code": "IND",
    "cpi_score": 40,
    "lat": 28.6142,
    "lng": 77.2023,
    "name": "Індія",
    "score": "M"
  },
  "IDN": {
    "capital": "Jakarta",
    "code": "IDN",
    "cpi_score": 38,
    "lat": -6.17539,
    "lng": 106.827,
    "name": "Індонезія",
    "score": "H"
  },
  "IRN": {
    "capital": "Tehran",
    "code": "IRN",
    "cpi_score": 25,
    "lat": 35.7006,
    "lng": 51.4014,
    "name": "Іран",
    "score": "H"
  },
  "IRQ": {
    "capital": "Baghdad",
    "code": "IRQ",
    "cpi_score": 23,
    "lat": 33.3024,
    "lng": 44.3788,
    "name": "Ірак",
    "score": "H"
  },
  "IRL": {
    "capital": "Dublin",
    "code": "IRL",
    "cpi_score": 74,
    "lat": 53.3498,
    "lng": -6.26027,
    "name": "Ірландія",
    "score": "L"
  },
  "ISR": {
    "capital": "Jerusalem",
    "code": "ISR",
    "cpi_score": 59,
    "lat": 31.7783,
    "lng": 35.2251,
    "name": "Ізраїль",
    "score": "L"
  },
  "ITA": {
    "capital": "Rome",
    "code": "ITA",
    "cpi_score": 56,
    "lat": 41.8933,
    "lng": 12.4829,
    "name": "Італія",
    "score": "M"
  },
  "CIV": {
    "capital": "Yamoussoukro",
    "code": "CIV",
    "cpi_score": 36,
    "lat": 6.80911,
    "lng": -5.27326,
    "name": "Берег Слонової Кістки",
    "score": "H"
  },
  "JAM": {
    "capital": "Kingston",
    "code": "JAM",
    "cpi_score": 44,
    "lat": 17.9712,
    "lng": -76.7928,
    "name": "Ямайка",
    "score": "M"
  },
  "JPN": {
    "capital": "Tokyo",
    "code": "JPN",
    "cpi_score": 73,
    "lat": 35.6828,
    "lng": 139.759,
    "name": "Японія",
    "score": "L"
  },
  "JOR": {
    "capital": "Amman",
    "code": "JOR",
    "cpi_score": 49,
    "lat": 31.9516,
    "lng": 35.924,
    "name": "Йорданія",
    "score": "M"
  },
  "KAZ": {
    "capital": "Astana",
    "code": "KAZ",
    "cpi_score": 37,
    "lat": 51.1282,
    "lng": 71.4307,
    "name": "Казахстан",
    "score": "H"
  },
  "KEN": {
    "capital": "Nairobi",
    "code": "KEN",
    "cpi_score": 30,
    "lat": -1.28325,
    "lng": 36.8172,
    "name": "Кенія",
    "score": "H"
  },
  "KIR": {
    "capital": "South Tarawa",
    "code": "KIR",
    "cpi_score": 0,
    "lat": 1.34908,
    "lng": 173.039,
    "name": "Кірібаті",
    "score": ""
  },
  "KSV": {
    "capital": "Pristina",
    "code": "KSV",
    "cpi_score": 0,
    "lat": 42.6633,
    "lng": 21.1622,
    "name": "Косово",
    "score": "H"
  },
  "KWT": {
    "capital": "Kuwait City",
    "code": "KWT",
    "cpi_score": 43,
    "lat": 29.3797,
    "lng": 47.9736,
    "name": "Кувейт",
    "score": "M"
  },
  "KGZ": {
    "capital": "Bishkek",
    "code": "KGZ",
    "cpi_score": 27,
    "lat": 42.8766,
    "lng": 74.607,
    "name": "Киргизстан",
    "score": "H"
  },
  "LAO": {
    "capital": "Vientiane",
    "code": "LAO",
    "cpi_score": 30,
    "lat": 17.9641,
    "lng": 102.613,
    "name": "Лаос",
    "score": "H"
  },
  "LVA": {
    "capital": "Riga",
    "code": "LVA",
    "cpi_score": 59,
    "lat": 56.9494,
    "lng": 24.1052,
    "name": "Латвія",
    "score": "H"
  },
  "LBN": {
    "capital": "Beirut",
    "code": "LBN",
    "cpi_score": 24,
    "lat": 33.8959,
    "lng": 35.4784,
    "name": "Ліван",
    "score": "H"
  },
  "LSO": {
    "capital": "Maseru",
    "code": "LSO",
    "cpi_score": 38,
    "lat": -29.3101,
    "lng": 27.4782,
    "name": "Лесото",
    "score": "M"
  },
  "LBR": {
    "capital": "Monrovia",
    "code": "LBR",
    "cpi_score": 29,
    "lat": 6.32803,
    "lng": -10.7978,
    "name": "Ліберія",
    "score": "H"
  },
  "LBY": {
    "capital": "Tripoli",
    "code": "LBY",
    "cpi_score": 17,
    "lat": 32.8967,
    "lng": 13.1778,
    "name": "Лівія",
    "score": "H"
  },
  "LTU": {
    "capital": "Vilnius",
    "code": "LTU",
    "cpi_score": 61,
    "lat": 54.687,
    "lng": 25.2829,
    "name": "Литва",
    "score": "L"
  },
  "LUX": {
    "capital": "Luxembourg",
    "code": "LUX",
    "cpi_score": 81,
    "lat": 49.8159,
    "lng": 6.12967,
    "name": "Люксембург",
    "score": "L"
  },
  "MDG": {
    "capital": "Antananarivo",
    "code": "MDG",
    "cpi_score": 26,
    "lat": -18.91,
    "lng": 47.5256,
    "name": "Мадагаскар",
    "score": "H"
  },
  "MWI": {
    "capital": "Lilongwe",
    "code": "MWI",
    "cpi_score": 35,
    "lat": -13.9875,
    "lng": 33.7681,
    "name": "Малаві",
    "score": "H"
  },
  "MYS": {
    "capital": "Kuala Lumpur",
    "code": "MYS",
    "cpi_score": 48,
    "lat": 3.1517,
    "lng": 101.694,
    "name": "Малайзія",
    "score": "M"
  },
  "MDV": {
    "capital": "Malé",
    "code": "MDV",
    "cpi_score": 40,
    "lat": 16.37,
    "lng": -2.29002,
    "name": "Мальдіви",
    "score": "M"
  },
  "MLI": {
    "capital": "Bamako",
    "code": "MLI",
    "cpi_score": 29,
    "lat": 12.605,
    "lng": -7.98651,
    "name": "Малі",
    "score": "H"
  },
  "MLT": {
    "capital": "Valletta",
    "code": "MLT",
    "cpi_score": 54,
    "lat": 35.899,
    "lng": 14.5137,
    "name": "Мальта",
    "score": "M"
  },
  "MHL": {
    "capital": "Majuro",
    "code": "MHL",
    "cpi_score": 0,
    "lat": 7.09099,
    "lng": 171.382,
    "name": "Маршаллові острови",
    "score": ""
  },
  "MRT": {
    "capital": "Nouakchott",
    "code": "MRT",
    "cpi_score": 28,
    "lat": 18.0792,
    "lng": -15.978,
    "name": "Мавританія",
    "score": ""
  },
  "MUS": {
    "capital": "Port Louis",
    "code": "MUS",
    "cpi_score": 54,
    "lat": -20.1637,
    "lng": 57.5045,
    "name": "Маврикій",
    "score": "M"
  },
  "MEX": {
    "capital": "Mexico City",
    "code": "MEX",
    "cpi_score": 31,
    "lat": 19.4326,
    "lng": -99.1332,
    "name": "Мексика",
    "score": "H"
  },
  "MDA": {
    "capital": "Chișinău",
    "code": "MDA",
    "cpi_score": 36,
    "lat": 47.0245,
    "lng": 28.8323,
    "name": "Молдова",
    "score": "H"
  },
  "MCO": {
    "capital": "Monaco",
    "code": "MCO",
    "cpi_score": 100,
    "lat": 43.7323,
    "lng": 7.42768,
    "name": "Монако",
    "score": "L"
  },
  "MNG": {
    "capital": "Ulan Bator",
    "code": "MNG",
    "cpi_score": 35,
    "lat": 47.9185,
    "lng": 106.918,
    "name": "Монголія",
    "score": "H"
  },
  "MNE": {
    "capital": "Podgorica",
    "code": "MNE",
    "cpi_score": 46,
    "lat": 42.7833,
    "lng": 19.4667,
    "name": "Чорногорія",
    "score": "M"
  },
  "MAR": {
    "capital": "Rabat",
    "code": "MAR",
    "cpi_score": 39,
    "lat": 34.0224,
    "lng": -6.83454,
    "name": "Марокко",
    "score": "M"
  },
  "MOZ": {
    "capital": "Maputo",
    "code": "MOZ",
    "cpi_score": 26,
    "lat": -25.9662,
    "lng": 32.5675,
    "name": "Мозамбік",
    "score": "H"
  },
  "MMR": {
    "capital": "Naypyidaw",
    "code": "MMR",
    "cpi_score": 28,
    "lat": 19.75,
    "lng": 96.1,
    "name": "М'янма",
    "score": "H"
  },
  "NAM": {
    "capital": "Windhoek",
    "code": "NAM",
    "cpi_score": 49,
    "lat": -22.5744,
    "lng": 17.0791,
    "name": "Намібія",
    "score": "M"
  },
  "NRU": {
    "capital": "Yaren",
    "code": "NRU",
    "cpi_score": 0,
    "lat": -0.547101,
    "lng": 166.916,
    "name": "Науру",
    "score": ""
  },
  "NPL": {
    "capital": "Kathmandu",
    "code": "NPL",
    "cpi_score": 33,
    "lat": 27.7083,
    "lng": 85.3206,
    "name": "Непал",
    "score": "H"
  },
  "NLD": {
    "capital": "Amsterdam",
    "code": "NLD",
    "cpi_score": 82,
    "lat": 52.3728,
    "lng": 4.8936,
    "name": "Нідерланди",
    "score": "L"
  },
  "NZL": {
    "capital": "Wellington",
    "code": "NZL",
    "cpi_score": 88,
    "lat": -41.2888,
    "lng": 174.777,
    "name": "Нова Зеландія",
    "score": "L"
  },
  "NIC": {
    "capital": "Managua",
    "code": "NIC",
    "cpi_score": 20,
    "lat": 12.146,
    "lng": -86.2747,
    "name": "Нікарагуа",
    "score": "H"
  },
  "NER": {
    "capital": "Niamey",
    "code": "NER",
    "cpi_score": 31,
    "lat": 13.5248,
    "lng": 2.10982,
    "name": "Нігер",
    "score": "H"
  },
  "NGA": {
    "capital": "Abuja",
    "code": "NGA",
    "cpi_score": 24,
    "lat": 9.06433,
    "lng": 7.4893,
    "name": "Нігерія",
    "score": "H"
  },
  "NIU": {
    "capital": "Alofi",
    "code": "NIU",
    "cpi_score": 0,
    "lat": -19.0534,
    "lng": -169.919,
    "name": "Ніуе",
    "score": ""
  },
  "PRK": {
    "capital": "Pyongyang",
    "code": "PRK",
    "cpi_score": 16,
    "lat": 39.0195,
    "lng": 125.753,
    "name": "Північна Корея",
    "score": "H"
  },
  "NOR": {
    "capital": "Oslo",
    "code": "NOR",
    "cpi_score": 85,
    "lat": 59.9133,
    "lng": 10.739,
    "name": "Норвегія",
    "score": "L"
  },
  "OMN": {
    "capital": "Muscat",
    "code": "OMN",
    "cpi_score": 52,
    "lat": 23.5998,
    "lng": 58.5451,
    "name": "Оман",
    "score": "M"
  },
  "PAK": {
    "capital": "Islamabad",
    "code": "PAK",
    "cpi_score": 28,
    "lat": 33.6938,
    "lng": 73.0651,
    "name": "Пакистан",
    "score": "H"
  },
  "PLW": {
    "capital": "Ngerulmud",
    "code": "PLW",
    "cpi_score": 0,
    "lat": 7.50062,
    "lng": 134.624,
    "name": "Палау",
    "score": ""
  },
  "PAN": {
    "capital": "Panama City",
    "code": "PAN",
    "cpi_score": 36,
    "lat": 8.97145,
    "lng": -79.5342,
    "name": "Панама",
    "score": "H"
  },
  "PNG": {
    "capital": "Port Moresby",
    "code": "PNG",
    "cpi_score": 31,
    "lat": -9.47433,
    "lng": 147.16,
    "name": "Папуа-Нова Гвінея",
    "score": "H"
  },
  "PRY": {
    "capital": "Asunción",
    "code": "PRY",
    "cpi_score": 30,
    "lat": -25.28,
    "lng": -57.6344,
    "name": "Парагвай",
    "score": "H"
  },
  "PER": {
    "capital": "Lima",
    "code": "PER",
    "cpi_score": 36,
    "lat": -12.0621,
    "lng": -77.0365,
    "name": "Перу",
    "score": "H"
  },
  "PHL": {
    "capital": "Manila",
    "code": "PHL",
    "cpi_score": 33,
    "lat": 14.5906,
    "lng": 120.98,
    "name": "Філіппіни",
    "score": "H"
  },
  "POL": {
    "capital": "Warsaw",
    "code": "POL",
    "cpi_score": 56,
    "lat": 52.2337,
    "lng": 21.0714,
    "name": "Польща",
    "score": "M"
  },
  "PRT": {
    "capital": "Lisbon",
    "code": "PRT",
    "cpi_score": 62,
    "lat": 38.7078,
    "lng": -9.13659,
    "name": "Португалія",
    "score": "L"
  },
  "PRI": {
    "capital": "",
    "code": "PRI",
    "cpi_score": 0,
    "lat": 0,
    "lng": 0,
    "name": "Пуерто Ріко",
    "score": "M"
  },
  "QAT": {
    "capital": "Doha",
    "code": "QAT",
    "cpi_score": 63,
    "lat": 25.2856,
    "lng": 51.5264,
    "name": "Катар",
    "score": "L"
  },
  "MKD": {
    "capital": "Skopje",
    "code": "MKD",
    "cpi_score": 39,
    "lat": 41.9961,
    "lng": 21.4317,
    "name": "Республіка Македонія",
    "score": "H"
  },
  "COG": {
    "capital": "Brazzaville",
    "code": "COG",
    "cpi_score": 21,
    "lat": -4.26944,
    "lng": 15.2712,
    "name": "Республіка Конго",
    "score": "H"
  },
  "ROU": {
    "capital": "Bucharest",
    "code": "ROU",
    "cpi_score": 45,
    "lat": 44.4361,
    "lng": 26.1027,
    "name": "Румунія",
    "score": "M"
  },
  "RUS": {
    "capital": "Moscow",
    "code": "RUS",
    "cpi_score": 29,
    "lat": 55.7504,
    "lng": 37.6175,
    "name": "Росія",
    "score": "H"
  },
  "RWA": {
    "capital": "Kigali",
    "code": "RWA",
    "cpi_score": 53,
    "lat": -1.88596,
    "lng": 30.1297,
    "name": "Руанда",
    "score": "M"
  },
  "KNA": {
    "capital": "Basseterre",
    "code": "KNA",
    "cpi_score": 0,
    "lat": 17.2961,
    "lng": -62.7223,
    "name": "Сент-Кітс і Невіс",
    "score": ""
  },
  "LCA": {
    "capital": "Castries",
    "code": "LCA",
    "cpi_score": 56,
    "lat": 13.9526,
    "lng": -60.9878,
    "name": "Сент-Люсія",
    "score": "M"
  },
  "VCT": {
    "capital": "Kingstown",
    "code": "VCT",
    "cpi_score": 59,
    "lat": 13.1562,
    "lng": -61.228,
    "name": "Сент-Вінсент і Гренадини",
    "score": "M"
  },
  "WSM": {
    "capital": "Apia",
    "code": "WSM",
    "cpi_score": 0,
    "lat": -13.8344,
    "lng": -171.769,
    "name": "Самоа",
    "score": ""
  },
  "SMR": {
    "capital": "City of San Marino",
    "code": "SMR",
    "cpi_score": 0,
    "lat": 43.9364,
    "lng": 12.4467,
    "name": "Сан-Марино",
    "score": ""
  },
  "STP": {
    "capital": "São Tomé",
    "code": "STP",
    "cpi_score": 45,
    "lat": 0.338924,
    "lng": 6.7313,
    "name": "Сан-Томе і Принсіпі",
    "score": "M"
  },
  "SAU": {
    "capital": "Riyadh",
    "code": "SAU",
    "cpi_score": 53,
    "lat": 24.632,
    "lng": 46.7151,
    "name": "Саудівська Аравія",
    "score": "M"
  },
  "SEN": {
    "capital": "Dakar",
    "code": "SEN",
    "cpi_score": 43,
    "lat": 14.6934,
    "lng": -17.4479,
    "name": "Сенегал",
    "score": "M"
  },
  "SRB": {
    "capital": "Belgrade",
    "code": "SRB",
    "cpi_score": 38,
    "lat": 44.8178,
    "lng": 20.4569,
    "name": "Сербія",
    "score": "H"
  },
  "SYC": {
    "capital": "Victoria",
    "code": "SYC",
    "cpi_score": 70,
    "lat": -36.5986,
    "lng": 144.678,
    "name": "Сейшельські острови",
    "score": "L"
  },
  "SLE": {
    "capital": "Freetown",
    "code": "SLE",
    "cpi_score": 34,
    "lat": 8.479,
    "lng": -13.268,
    "name": "Сьєрра-Леоне",
    "score": "H"
  },
  "SGP": {
    "capital": "Singapore",
    "code": "SGP",
    "cpi_score": 85,
    "lat": 1.35711,
    "lng": 103.819,
    "name": "Сінгапур",
    "score": "L"
  },
  "SVK": {
    "capital": "Bratislava",
    "code": "SVK",
    "cpi_score": 52,
    "lat": 48.1517,
    "lng": 17.1093,
    "name": "Словаччина",
    "score": "M"
  },
  "SVN": {
    "capital": "Ljubljana",
    "code": "SVN",
    "cpi_score": 57,
    "lat": 46.05,
    "lng": 14.5069,
    "name": "Словенія",
    "score": "L"
  },
  "SLB": {
    "capital": "Honiara",
    "code": "SLB",
    "cpi_score": 43,
    "lat": -9.43108,
    "lng": 159.955,
    "name": "Соломонові острови",
    "score": "M"
  },
  "SOM": {
    "capital": "Mogadishu",
    "code": "SOM",
    "cpi_score": 13,
    "lat": 2.04278,
    "lng": 45.3386,
    "name": "Сомалі",
    "score": "H"
  },
  "ZAF": {
    "capital": "Pretoria",
    "code": "ZAF",
    "cpi_score": 44,
    "lat": -25.7459,
    "lng": 28.1879,
    "name": "Південна Африка",
    "score": "M"
  },
  "KOR": {
    "capital": "Seoul",
    "code": "KOR",
    "cpi_score": 62,
    "lat": 37.5667,
    "lng": 126.978,
    "name": "Південна Корея",
    "score": "L"
  },
  "SSD": {
    "capital": "Juba",
    "code": "SSD",
    "cpi_score": 11,
    "lat": 4.8472,
    "lng": 31.5952,
    "name": "Південний Судан",
    "score": "H"
  },
  "ESP": {
    "capital": "Madrid",
    "code": "ESP",
    "cpi_score": 61,
    "lat": 40.4167,
    "lng": -3.70358,
    "name": "Іспанія",
    "score": "L"
  },
  "LKA": {
    "capital": "Colombo",
    "code": "LKA",
    "cpi_score": 37,
    "lat": 6.935,
    "lng": 79.8538,
    "name": "Шрі Ланка",
    "score": "H"
  },
  "SDN": {
    "capital": "Khartoum",
    "code": "SDN",
    "cpi_score": 20,
    "lat": 15.5933,
    "lng": 32.5356,
    "name": "Судан",
    "score": "H"
  },
  "SUR": {
    "capital": "Paramaribo",
    "code": "SUR",
    "cpi_score": 39,
    "lat": 5.82161,
    "lng": -55.177,
    "name": "Сурінам",
    "score": "H"
  },
  "SWE": {
    "capital": "Stockholm",
    "code": "SWE",
    "cpi_score": 85,
    "lat": 59.3251,
    "lng": 18.0711,
    "name": "Швеція",
    "score": "L"
  },
  "CHE": {
    "capital": "Bern",
    "code": "CHE",
    "cpi_score": 84,
    "lat": 46.9483,
    "lng": 7.45145,
    "name": "Швейцарія",
    "score": "L"
  },
  "SYR": {
    "capital": "Damascus",
    "code": "SYR",
    "cpi_score": 13,
    "lat": 33.5131,
    "lng": 36.3096,
    "name": "Сирія",
    "score": "H"
  },
  "TWN": {
    "capital": "Taipei",
    "code": "TWN",
    "cpi_score": 68,
    "lat": 25.0667,
    "lng": 121.517,
    "name": "Тайвань",
    "score": "L"
  },
  "TJK": {
    "capital": "Dushanbe",
    "code": "TJK",
    "cpi_score": 25,
    "lat": 38.5426,
    "lng": 68.8152,
    "name": "Таджикистан",
    "score": "H"
  },
  "TZA": {
    "capital": "Dodoma",
    "code": "TZA",
    "cpi_score": 39,
    "lat": -6.33728,
    "lng": 35.7372,
    "name": "Танзанія",
    "score": "H"
  },
  "THA": {
    "capital": "Bangkok",
    "code": "THA",
    "cpi_score": 35,
    "lat": 13.7543,
    "lng": 100.493,
    "name": "Таїланд",
    "score": "H"
  },
  "BHS": {
    "capital": "Nassau",
    "code": "BHS",
    "cpi_score": 64,
    "lat": 25.0783,
    "lng": -77.3383,
    "name": "Багамські острови",
    "score": "L"
  },
  "GMB": {
    "capital": "Banjul",
    "code": "GMB",
    "cpi_score": 37,
    "lat": 13.4413,
    "lng": -16.5625,
    "name": "Гамбія",
    "score": "H"
  },
  "TGO": {
    "capital": "Lomé",
    "code": "TGO",
    "cpi_score": 30,
    "lat": 6.13042,
    "lng": 1.21583,
    "name": "Тоґо",
    "score": "H"
  },
  "TKL": {
    "capital": "Fakaofo",
    "code": "TKL",
    "cpi_score": 0,
    "lat": -9.3743,
    "lng": -171.265,
    "name": "Токелау",
    "score": ""
  },
  "TON": {
    "capital": "Nuku'alofa",
    "code": "TON",
    "cpi_score": 0,
    "lat": -21.1343,
    "lng": -175.202,
    "name": "Тонга",
    "score": ""
  },
  "TTO": {
    "capital": "Port of Spain",
    "code": "TTO",
    "cpi_score": 41,
    "lat": 10.6573,
    "lng": -61.518,
    "name": "Тринідад і Тобаго",
    "score": "M"
  },
  "TUN": {
    "capital": "Tunis",
    "code": "TUN",
    "cpi_score": 44,
    "lat": 33.8439,
    "lng": 9.40014,
    "name": "Туніс",
    "score": "M"
  },
  "TUR": {
    "capital": "Ankara",
    "code": "TUR",
    "cpi_score": 38,
    "lat": 39.9208,
    "lng": 32.8541,
    "name": "Туреччина",
    "score": "M"
  },
  "TKM": {
    "capital": "Ashgabat",
    "code": "TKM",
    "cpi_score": 19,
    "lat": 37.9397,
    "lng": 58.3874,
    "name": "Туркменістан",
    "score": "H"
  },
  "TUV": {
    "capital": "Funafuti",
    "code": "TUV",
    "cpi_score": 0,
    "lat": -8.535,
    "lng": 179.119,
    "name": "Тувалу",
    "score": ""
  },
  "UGA": {
    "capital": "Kampala",
    "code": "UGA",
    "cpi_score": 27,
    "lat": 0.317714,
    "lng": 32.5814,
    "name": "Уганда",
    "score": "H"
  },
  "UKR": {
    "capital": "Kiev",
    "code": "UKR",
    "cpi_score": 32,
    "lat": 50.45,
    "lng": 30.5241,
    "name": "Україна",
    "score": "H"
  },
  "ARE": {
    "capital": "Abu Dhabi",
    "code": "ARE",
    "cpi_score": 69,
    "lat": 24.4748,
    "lng": 54.3706,
    "name": "Об'єднані Арабські Емірати",
    "score": "L"
  },
  "GBR": {
    "capital": "London",
    "code": "GBR",
    "cpi_score": 78,
    "lat": 51.5073,
    "lng": -0.127647,
    "name": "Об'єднане Королівство",
    "score": "L"
  },
  "USA": {
    "capital": "Washington D.C.",
    "code": "USA",
    "cpi_score": 67,
    "lat": 38.895,
    "lng": -77.0366,
    "name": "Сполучені Штати Америки",
    "score": "L"
  },
  "URY": {
    "capital": "Montevideo",
    "code": "URY",
    "cpi_score": 73,
    "lat": -34.9059,
    "lng": -56.1914,
    "name": "Уругвай",
    "score": "L"
  },
  "UZB": {
    "capital": "Tashkent",
    "code": "UZB",
    "cpi_score": 28,
    "lat": 41.3123,
    "lng": 69.2787,
    "name": "Узбекистан",
    "score": "H"
  },
  "VUT": {
    "capital": "Port Vila",
    "code": "VUT",
    "cpi_score": 45,
    "lat": -17.7415,
    "lng": 168.315,
    "name": "Вануату",
    "score": "M"
  },
  "VEN": {
    "capital": "Caracas",
    "code": "VEN",
    "cpi_score": 14,
    "lat": 10.5061,
    "lng": -66.9146,
    "name": "Венесуела",
    "score": "H"
  },
  "VNM": {
    "capital": "Hanoi",
    "code": "VNM",
    "cpi_score": 39,
    "lat": 21.0294,
    "lng": 105.854,
    "name": "В'єтнам",
    "score": "H"
  },
  "YEM": {
    "capital": "Sana'a",
    "code": "YEM",
    "cpi_score": 16,
    "lat": 15.3539,
    "lng": 44.2059,
    "name": "Ємен",
    "score": "H"
  },
  "ZMB": {
    "capital": "Lusaka",
    "code": "ZMB",
    "cpi_score": 33,
    "lat": -15.4164,
    "lng": 28.2822,
    "name": "Замбія",
    "score": "H"
  },
  "ZWE": {
    "capital": "Harare",
    "code": "ZWE",
    "cpi_score": 23,
    "lat": -17.8318,
    "lng": 31.0457,
    "name": "Зімбабве",
    "score": "H"
  }
}

export const getCountryName = (code) => COUNTRIES[code] ? COUNTRIES[code].name : ''


export const INDUSTRIES = {
  "1":{
     "id":1,
     "name":"Гірнича справа",
     "score":"H"
  },
  "2":{
     "id":2,
     "name":"Металургія",
     "score":"H"
  },
  "3":{
     "id":3,
     "name":"Нафтогазова промисловість",
     "score":"H"
  },
  "4":{
     "id":4,
     "name":"Хімічна промисловість",
     "score":"H"
  },
  "5":{
     "id":5,
     "name":"Будівництво",
     "score":"H"
  },
  "6":{
     "id":6,
     "name":"Агентські та дистриб\'юторські послуги",
     "score":"H"
  },
  "7":{
     "id":7,
     "name":"Операції з нерухомістю",
     "score":"H"
  },
  "8":{
     "id":8,
     "name":"Комунальні послуги",
     "score":"H"
  },
  "9":{
     "id":9,
     "name":"Оборонно-промисловий комплекс",
     "score":"H"
  },
  "10":{
     "id":10,
     "name":"Важка промисловість",
     "score":"M"
  },
  "11":{
     "id":11,
     "name":"Логістичні послуги",
     "score":"M"
  },
  "12":{
     "id":12,
     "name":"Телекомунікації",
     "score":"H"
  },
  "13":{
     "id":13,
     "name":"Обслуговування та ремонт технічного обладнання",
     "score":"M"
  },
  "14":{
     "id":14,
     "name":"Охорона здоров\'я",
     "score":"H"
  },
  "15":{
     "id":15,
     "name":"Енергетика",
     "score":"M"
  },
  "16":{
     "id":16,
     "name":"Електронна промисловість",
     "score":"M"
  },
  "17":{
     "id":17,
     "name":"Легка промисловість",
     "score":"L"
  },
  "18":{
     "id":18,
     "name":"Юридичні, аудиторські та консультаційні послуги",
     "score":"L"
  },
  "19":{
     "id":19,
     "name":"Інформаційні технології",
     "score":"L"
  },
  "20":{
     "id":20,
     "name":"Споживчі товари та послуги",
     "score":"L"
  },
  "21":{
     "id":21,
     "name":"Лісове господарство",
     "score":"L"
  },
  "22":{
     "id":22,
     "name":"Фінансова та банківська діяльність",
     "score":"M"
  },
  "23":{
     "id":23,
     "name":"Сільське господарство",
     "score":"L"
  },
  "24":{
     "id":24,
     "name":"Інше",
     "score":"L"
  },
  "25":{
     "id":25,
     "name":"Нічого",
     "score":"L"
  }
}

export const getIndustryName = (id) => INDUSTRIES[id] ? INDUSTRIES[id].name : ''