import axios from 'axios';

const searchOpenStreetAddress = (q, lang = 'ua-UA') => {
  // ?q=%D0%9F%D0%BE%D0%BB%D1%82%D0%B0%D0%B2%D0%B0+%D0%A8%D0%B5%D0%B2%D1%87%D0%B5%D0%BD%D0%BA%D0%BE+114&format=jsonv2
  const params = {
    q,
    format: 'jsonv2',
    'accept-language': 'ua'
  };
  const headers = {
    'Accept-Language': lang,
  }
  return axios.get('https://nominatim.openstreetmap.org/search.php', { params, headers });
}


export { searchOpenStreetAddress }