import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import logo from 'assets/img/logo.svg';

const useStyles = makeStyles(() => ({
  footer: {
    fontSize: '10px',
    color: 'var(--grey)',
    lineHeight: '1.8',
  },
  footerLogo: {
    display: 'inline-flex',
    alignItems: 'center',
    color: 'var(--primary-regular)',
    fontSize: '14px',
    fontWeight: '500',
    '& span': {
      marginRight: '8px',
    },
  },
  footerHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Footer = ({ children, borderTop = 1, py = 2, px = 0, showLogo }) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <Box py={py} px={px} borderTop={borderTop} borderColor="custom.borderColor" component="footer" className={classes.footer}>
      <Container disableGutters={true}>
        <Box className={classes.footerHolder}>
          <span>
            This platform is powered by COSA ™ with the CIPE support <br />© { currentYear } Cluster Platform
          </span>
          {showLogo && (
            <a className={classes.footerLogo} href={process.env.REACT_APP_WASP_URL}>
              <span>Більше інформації</span>
              <img src={logo} height="28" alt="WASP" />
            </a>
          )}
        </Box>
      </Container>
    </Box>
  );
};
Footer.defaultProps = {
  smallFooter: false,
};
Footer.propTypes = {
  smallFooter: PropTypes.bool,
};
export default Footer;
