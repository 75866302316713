import client from './client';

const ENDPOINT = '/novators';

async function createNovator(data) {
  const response = await client.post(`${ENDPOINT}/`, data);
  return response;
}

async function getNovator(id) {
  const response = await client.get(`${ENDPOINT}/${id}/`);
  return response;
}

async function getNovators(params) {
  const response = await client.get(`${ENDPOINT}/`, { params });
  return response;
}

async function updateNovator({ id, data }) {
  const response = await client.put(`${ENDPOINT}/${id}/`, data);
  return response;
}

async function deleteNovator(id) {
  const response = await client.delete(`${ENDPOINT}/${id}/`);
  return response;
}

export { createNovator, getNovator, getNovators, updateNovator, deleteNovator };
