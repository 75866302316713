// Core
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer, Popup } from 'react-leaflet';

const useStyles = makeStyles(theme => ({
  map: {
    width: '100%',
    height: '100%',
  },
  tooltip: {
    '& .leaflet-popup-content-wrapper': {
      background: 'rgba(6, 20, 39, .7)',
      color: 'white',
      fontSize: '14px',
      fontWeight: 700
    },
    '& .leaflet-popup-tip': {
      background: 'rgba(6, 20, 39, .7)',
    },
    '& .leaflet-popup-close-button': {
      display: 'none'
    }
  }
}));

const MapChart = ({lat = "51.505", lon = "-0.09", zoom = 13, address, ref}) => {
  const classes = useStyles();
  return lat && lon ? <MapContainer className={classes.map} center={[lat, lon]} zoom={zoom} scrollWheelZoom={false} ref={ref}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
      {lat && lon && address ? <Popup className={classes.tooltip} position={[lat, lon]} permanent>{address}</Popup> : null}
  </MapContainer> : null;
};

export default MapChart;
