// Core
import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MapChart from 'components/MapChart/MapChart';
import Button from '@material-ui/core/Button';
import { Language, Mail, NavigateBefore, PhoneEnabled } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import DashboardInfoBlock from 'components/Dashboard/DashboardInfoBlock';
import DashboardChips from 'components/Dashboard/DashboardChips';
import Footer from 'components/blocks/Footer';
import useAsync from 'hooks/useAsync';
import { getApplicant } from 'api/applicants';
import { UserConsumer } from 'context/UserContext';
import { ROLES } from 'utils';
import { getCountryName, INDUSTRIES } from 'config/metaData';

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
  detailsWrapper: {
    margin: '3px',
    padding: '18px',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: 'calc(100% - 79px - 5px - 70px)',
      padding: '18px 45px',
    }
  },
  infoBlockHolder: {
    width: '50%',
    minWidth: 180
  },
  contactsBlock: {
    border: '1px solid var(--border-color)',
    padding: '11px 16px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  sectionHeader: {
    padding: '18px',
    marginRight: '-4px',
    borderBottom: '1px solid var(--border-color)',
    height: '79px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '16px 48px',
    }
  },
}));
const ApplicantDetails = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [applicant, setApplicant] = useState(null);
  const { execute: loadApplicants, value: applicantDetails } = useAsync(getApplicant, false);
  useEffect(() => {
    console.log(id);
    loadApplicants(id);
  }, [id, loadApplicants]);

  useEffect(() => {
    console.log(applicantDetails);
    if (applicantDetails) {
      setApplicant({
        ...applicantDetails,
        industries: applicantDetails.industries.map(item => INDUSTRIES[item.id]).map(item => ({ ...item, label: item.name })),
        synergies: applicantDetails.synergies.map(item => ({ ...item, label: item.name })),
      });
    }
  }, [applicantDetails, setApplicant]);

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} md={6} className={classes.fullHeight}>
        <Box className={classes.sectionHeader}>
          <Link to="/entrepreneurs">
            <Button className={classes.button} variant="outlined" startIcon={<NavigateBefore />} size="small" color="primary">
              Назад
            </Button>
          </Link>
        </Box>
        {applicant ? (
          <Box className={classes.fullHeight}>
            <Box className={classes.detailsWrapper}>
              <Typography variant="h4" component="h4" gutterBottom>
                {applicant.name}
                <UserConsumer>
                {({ loggedInUser }) => {
                  if (loggedInUser && loggedInUser.role.name === ROLES.cipe_admin) {
                    return <Button
                    component={Link}
                    to={`/manage-applicant/${applicant.id}`}
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                  >Edit</Button>
                  }
                  return null;
                }}
                </UserConsumer>
              </Typography>
              <Box className={classes.contactsBlock}>
                <Box className={classes.infoBlockHolder} sx={{ marginBottom: '18px' }}>
                  <DashboardInfoBlock title="Країна" content={getCountryName(applicant.country_code)} icon={<Language fontSize="small" />} />
                </Box>
                <Box className={classes.infoBlockHolder} sx={{ marginBottom: '18px' }}>
                  <DashboardInfoBlock title="Імейл" content={applicant.email} icon={<Mail fontSize="small" />} />
                </Box>
                <Box className={classes.infoBlockHolder}>
                  <DashboardInfoBlock title="Контактні данні" content={applicant.contact} icon={<PhoneEnabled fontSize="small" />} />
                </Box>
                <Box className={classes.infoBlockHolder}>
                  <DashboardInfoBlock title="Веб сайт" content={applicant.website} icon={<Language fontSize="small" />} />
                </Box>
              </Box>
              <Box className="text-block" sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h5" gutterBottom>
                  Про компанію
                </Typography>
                <Box>
                  {applicant.description}
                </Box>
              </Box>
              <Box className="text-block" sx={{ marginBottom: '20px' }}>
                <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
                  Ключові напрями діяльності:
                </Typography>
                <Box>
                  {applicant.key_activities}
                </Box>
              </Box>
              <Box sx={{ marginBottom: '24px' }}>
                <DashboardChips items={applicant.industries} />
              </Box>
              <Box>
                <DashboardChips items={applicant.synergies} title="Синергії" />
              </Box>
            </Box>
            <Footer showLogo px={6} />
          </Box>
        ) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
      </Grid>
      <Grid item xs={12} md={6} className={classes.mapChartWrapper}>
        {applicant ? <MapChart lat={applicant.lat || 30} lon={applicant.lon || 30} address={applicant.lon && applicant.lat ? applicant.address : 'Адреса не вказана'} /> : null}
      </Grid>
    </Grid>
  );
};

export default ApplicantDetails;
