// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 220,
  },
}));

const SimpleSelect = ({ items, selected, onChange }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Select
        id="search-select"
        value={selected}
        onChange={onChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 36,
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}>
        {items.map((item, index) => {
          return (
            <MenuItem value={item.value} key={index + item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SimpleSelect.defaultProps = {
  items: [],
  selected: '',
  onChange: () => null,
};

SimpleSelect.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

export default SimpleSelect;
