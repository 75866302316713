import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useField } from 'formik';
import { Icon, TextField, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useAsync from 'hooks/useAsync';



const Input = withStyles({
  root: {
    marginTop: 15,
    marginBottom: 15,
    '&.withDescription': {
      marginTop: 25,
    },
    '& label': {
      fontSize: 14,
      textTransform: 'capitalize',
      color: 'var(--black-40)',
      transform: 'none',
      top: '-24px',
      pointerEvents: 'auto',
      '& span.fa': {
        display: 'inline',
      },
      '&[data-shrink=false]': {
        '&+ .MuiInputBase-formControl .MuiInputBase-input::placeholder': {
          opacity: '1 !important',
        },
      },
    },
    '&.withDescription label': {
      top: '-43px',
    },
    '& .MuiInputBase-input': {
      padding: '7px 12px',
      color: 'var(--black-100)',
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: 'var(--grey)',
        fontSize: '14',
        fontWeight: '500',
      },
    },
    '& .MuiAutocomplete-input': {
      padding: '7px 12px !important',
    },
    '&.multiselect .MuiInputBase-input': {
      padding: '2px',
      minHeight: 28,
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      overflow: 'hidden',
      padding: 0,
      fontFamily: 'inherit',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      '& fieldset': {
        borderColor: 'var(--black-20)',
      },
      '&:focus': {
        borderColor: 'var(--input-active-border)',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: 'var(--input-active-border)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--input-active-border)',
        border: '1px solid',
        borderWidth: 1,
      },
      '&.Mui-disabled': {
        background: 'var(--input-disabled-border)',
        '& fieldset': {
          borderColor: 'var(--black-20)',
        },
        '&:hover fieldset': {
          borderColor: 'var(--black-20)',
        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: '2px 12px 3px',
    },
    '&:not(.multiselect) .MuiOutlinedInput-multiline': {
      '& .MuiInputBase-inputMultiline': {
        minHeight: '18px !important',
        lineHeight: '18px !important',
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  startAdornment: {
    marginRight: 36,
  },
  uncapitallyze: {
    textTransform: 'none !important',
  },
  description: {
    fontSize: '10px',
    color: 'var(--black-60)',
    paddingTop: 2,
    textTransform: 'none',

    '& .MuiInputBase-root': {
      marginTop: '26px !important',
    },
  },

  openedEye: {
    color: 'var(--primary-regular)',
  },

  endAdornmentCustom: {
    backgroundColor: 'var(--input-regular-border)',

    '& .fa': {
      color: 'var(--black-40)',

      '&.fa-pwd': {
        width: '25px',
        height: 'auto',
        fontSize: '10px',
      },
    },
  },

  endAdornmentCustomPwd: {
    borderLeft: 'none',

    '& .fa': {
      color: 'var(--black-40)',

      '&.fa-pwd': {
        width: '25px',
        height: 'auto',
        fontSize: '10px',

        '& .MuiSvgIcon-root': {
          fontSize: '1.1rem',
        },
      },
    },
  },

  label: {
    fontWeight: 600,
    color: 'var(--black-100)',
    fontSize: 14,
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px',
    borderRadius: '4px',
    height: '24px',
  },
});

const FormikAutocomplete = ({
  id,
  label,
  changeValueHandler,
  uncapitalized,
  hint,
  mandatory,
  request,
  apiError,
  ...props
}) => {
  const classes = useStyles();
  
  const [field, meta, helpers] = useField(props);

  const error = meta.error && meta.touched;
  const helperText = error ? meta.error : <></>;
  const drawHint = hint && (
    <Tooltip color="primary" title={hint} arrow interactive={false}>
      <Icon className="fa fa-info-circle fa-sm" />
    </Tooltip>
  );

  const { execute: searchOptions, value: searchResponse } = useAsync(request, false);
  const [options, setOptions] = useState([]);
  
  const changeHandler = (event, newValue) => {
    helpers.setValue(newValue ? newValue.display_name : '')
    changeValueHandler(newValue)
  }

  const inputChangeHandler = debounce((event, newInputValue) => {
    searchOptions(newInputValue);
  }, 600);

  useEffect(() => {
    if (searchResponse !== null) {
      setOptions(searchResponse)
    }
    
  }, [searchResponse, setOptions]);
  // getOptionSelected
  return (
    <Autocomplete {...{id}} 
      {...field}
      
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.display_name)}
      getOptionSelected={(option, value) => option.display_name === value}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={changeHandler}
      onInputChange={inputChangeHandler}
      renderInput={(params) => {
        return (
          <Input {...params}
            label={(
              <div className={uncapitalized ? classes.uncapitallyze : ''}>
                {label + (mandatory ? ' * ' : ' ')}
                {drawHint}
              </div>
            )}
            InputLabelProps={{
              style: { pointerEvents: 'auto' },
              disableAnimation: true,
              shrink: false,
            }}
            variant="outlined"
            fullWidth
            error={!!apiError || error}
            helperText={helperText}
            />
        )
      }}
      renderOption={(option) => {
        return (<>{option.display_name}</>);
      }}
    />
  );
};

export default FormikAutocomplete;