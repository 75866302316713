import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import useAsync from 'hooks/useAsync';
import { fetchCountries, fetchIndustries, fetchUserRoles } from 'api/info';
import FormikInput from 'components/ui/FormikInput';
import CustomRadioGroup from 'components/UI/CustomRadio/CustomRadioGroup';
import { ROLES, WEBSITE_REGEX } from 'utils';
import { searchOpenStreetAddress } from 'api/3rdparty';
import FormikAutocomplete from 'components/ui/FormikAutocomplete/FormikAutocomplete';
import { CircularProgress } from '@material-ui/core';

const initialValues = {
  type: 0,
  name: '',
  country_code: '',
  region: '',
  address: '',
  website: '',
  contact: '',
  email: '',
  industries: [],
  description: '',
  key_activities: '',
  lat: null,
  lon: null
};

const validationSchema = Yup.object().shape({
  id: Yup.mixed(),
  type: Yup.number().min(1).required('Поле є обов\'язковим'),
  name: Yup.string()
    .min(2, 'Поле має бути не менше 2 символів')
    .max(64, 'Поле має бути не більше 64 символів')
    .required('Поле є обов\'язковим'),
  country_code: Yup.string().required('Поле є обов\'язковим'),
  region: Yup.string().required('Поле є обов\'язковим'),
  address: Yup.string()
    .min(2, 'Поле має бути не менше 2 символів')
    .max(512, 'Поле має бути не більше 512 символів')
    .required('Поле є обов\'язковим'),
  website: Yup.string()
    .matches(WEBSITE_REGEX, 'URL-адреса недійсна')
    .max(128, 'Поле має бути не більше 128 символів')
    .required('Поле є обов\'язковим'),
  contact: Yup.string()
    .min(2, 'Поле має бути не менше 2 символів')
    .max(128, 'Поле має бути не більше 128 символів')
    .required('Поле є обов\'язковим'),
  email: Yup.string()
    .matches(/^[A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]+$/, {
      message: 'Невірно вказана поштова адреса',
      excludeEmptyString: true,
    })
    .max(64, 'Поле має бути не більше 64 символів')
    .required('Поле є обов\'язковим'),
  industries: Yup.array()
    .min(1, 'Потрібно вибрати принайні 1 пункт')
    .max(5, 'Потрібно вибрати небільше 5 пунктів')
    .required('Поле є обов\'язковим'),
  description: Yup.string()
    .min(2, 'Поле має бути не менше 2 символів')
    .max(16000, 'Поле має бути не більше 16000 символів').required('Поле є обов\'язковим'),
  key_activities: Yup.string()
  .min(2, 'Поле має бути не менше 2 символів')
  .max(16000, 'Поле має бути не більше 16000 символів'),
  lat: Yup.mixed().nullable(),
  lon: Yup.mixed().nullable()
});

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  disableRadio: {
    cursor: 'not-allowed'
  }
}));



function ProfileForm({ formSubmit, formBlur, initValue, externalErrors, submitButtonText, showRole = true, loading = false }) {
  const classes = useStyles();

  const [roles, setRoles] = useState([]);
  const { execute: loadRoles, value: rolesResponse } = useAsync(fetchUserRoles, false);
  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  useEffect(() => {
    if (rolesResponse) {
      const items = rolesResponse
        .filter(item => ['Applicant', 'Novator'].includes(item.name))
        .map(item => {
          if (item.name === ROLES.Applicant) {
            return { ...item, name_en: item.name, name: 'Підприємець', disabled: !!initValue};
          }
          if (item.name === ROLES.Novator) {
            return { ...item, name_en: item.name, name: 'Новатор', disabled: !!initValue };
          }
          return item;
        });
      setRoles(items);
    }
  }, [setRoles, rolesResponse]);

  const formikRadio = ({ field, form, ...props }) => {
    return <CustomRadioGroup {...field} {...props} />;
  };

  const [countiresItems, setCountiresItems] = useState([]);
  const { execute: loadCountries, value: countires } = useAsync(fetchCountries, false);
  useEffect(() => {
    loadCountries();
  }, [loadCountries]);

  useEffect(() => {
    const items = countires
      ? countires.map(country => {
          const { code, name } = country;
          return (
            <MenuItem key={code} name={name} value={code}>
              <div className={`flag flag-${code} mr-2`} />
              {name}
            </MenuItem>
          );
        })
      : null;
    if (items) {
      setCountiresItems(items);
    }
  }, [countires]);

  const [industriesItems, setIndustriesItems] = useState([]);
  const { execute: loadIndustries, value: industries } = useAsync(fetchIndustries, false);
  useEffect(() => {
    loadIndustries();
  }, [loadIndustries]);

  useEffect(() => {
    
    if (industries) {
      setIndustriesItems(industries.map( ({id, name}) => ({id, name})));
    }
  }, [industries]);

  const [coords, setCoords] = useState(!!initValue ? {lat: initValue.lat, lon: initValue.lon} : {lat: 0, lon: 0});
  const addressHandler = ({lat, lon}) => {
    setCoords({lat, lon})
  }

  const showKeyActivities = (type, key_activities) => {
    if (!roles.length) {
      return null;
    }
    const role = roles.find(item => item.id === +type)

    if (role && role.name_en === ROLES.Applicant) {
      return <Grid item xs={12}>
        <FormikInput
          key="key_activities"
          id="key_activities"
          name="key_activities"
          label="Ключові напрями діяльності"
          rows={4}
          type="text"
          value={key_activities}
          apiError={externalErrors.key_activities}
        />
      </Grid>
    }
    return null;
    
  }

  return (
    <Formik initialValues={initValue || initialValues} validationSchema={validationSchema} onSubmit={values => formSubmit({...values, ...coords})}>
      {({ values, errors, isSubmitting }) => {
        return (
          <Form className={classes.formContainer} onBlur={formBlur}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {errors.type ? <Box mb={2} color="error.main">Поле є обов'язковим</Box> : null}
                {showRole && roles.length ? <Field id="type" name="type" color="simple" variant="inline" fields={roles} component={formikRadio} /> : showRole && <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}

                

                <FormikInput mandatory key="name" id="name" name="name" label="Назва компанії" type="text" offMultiline value={values.name} apiError={externalErrors.name} />
              </Grid>
              <Grid item xs={12} sm={6}>
                {countiresItems.length ? (
                  <FormikInput mandatory select id="country_code" name="country_code" value={values ? values.country_code : ''} label="Країна">
                    {countiresItems}
                  </FormikInput>
                ) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}

                <FormikAutocomplete mandatory
                  key="address" 
                  id="address" 
                  name="address" 
                  label="Операційна адреса" 
                  value={values.address} 
                  request={searchOpenStreetAddress}
                  changeValueHandler={addressHandler}
                  />

                {/* <FormikInput mandatory key="address" id="address" name="address" label="Операційна адреса" type="text" offMultiline value={values.email} apiError={externalErrors.address} /> */}

                <FormikInput mandatory key="contact" id="contact" name="contact" label="Контактні дані" type="text" offMultiline value={values.contact} apiError={externalErrors.contact} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikInput mandatory id="region" name="region" value={values ? values.region : ''} label="Регіон" />

                <FormikInput mandatory key="website" id="website" name="website" label="Вебсайт" type="url" offMultiline value={values.webiste} apiError={externalErrors.website} />

                <FormikInput mandatory key="email" id="email" name="email" label="Електронна пошта" type="email" offMultiline value={values.email} apiError={externalErrors.email} disabled={!!initValue} />
              </Grid>
              <Grid item xs={12}>
                {industriesItems.length ? (
                  <FormikInput mandatory select selectMultiple selectKey="name" selectKeyValue="id" id="industries" name="industries" value={values.industries} options={industriesItems} label="Галузь">
                  {industriesItems.map(item => {
                    
                  return <MenuItem key={item.id} name={item.name} value={item.id} className={values.industries.includes(item.id) ? 'Mui-selected': ''}>
                    {item.name}
                  </MenuItem>
                  })}
                  </FormikInput> 
                ) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  mandatory
                  key="description"
                  id="description"
                  name="description"
                  label="Короткий опис діяльності, ключові досягнення, потреби"
                  rows={4}
                  type="text"
                  value={values.description}
                  apiError={externalErrors.description}
                />
              </Grid>
              {showKeyActivities(values.type, values.key_activities)}
              
              <Grid item xs={12}>
                <Box display="flex" mb={2} justifyContent="flex-end">
                  <Box component="span" pr={2}>
                    <Button variant="outlined" color="primary" component={Link} to="/">
                      Відмінити
                    </Button>
                  </Box>
                  <Button variant="contained" color="primary" type="submit" disabled={loading}>
                    {submitButtonText}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

ProfileForm.defaultProps = {
  initValue: null,
  externalErrors: {},
  submitButtonText: 'Зберегти',
};
ProfileForm.propTypes = {
  formSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  initValue: PropTypes.exact({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf([6, 7]),
    name: PropTypes.string,
    country_code: PropTypes.string,
    region: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
    contact: PropTypes.string,
    email: PropTypes.string,
    industries: PropTypes.array,
    description: PropTypes.string,
    key_activities: PropTypes.string,
    lat: PropTypes.any,
    lon: PropTypes.any
  }),
  externalErrors: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    country_code: PropTypes.string,
    region: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
    contact: PropTypes.string,
    email: PropTypes.string,
    industries: PropTypes.string,
    description: PropTypes.string,
    key_activities: PropTypes.string
  }),
};

export default ProfileForm;
