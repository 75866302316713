import React from 'react';

const BulbIcon = props => {
  const { color } = props;

  return (
    <svg width="68" height="68" viewBox="0 0 50 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.5259 6.68494C36.5082 1.97306 29.9517 -0.387662 23.0607 0.0519868C10.827 0.816593 0.934903 10.6227 0.0651638 22.8468C-0.47006 30.2635 2.31119 37.4508 7.69211 42.5449C11.8688 46.5113 14.1721 51.6724 14.1721 57.0916V57.7989C14.1721 58.4297 14.6882 58.9457 15.319 58.9457H33.9945C34.6253 58.9457 35.1415 58.4297 35.1415 57.7989V57.4261C35.1415 51.7585 37.397 46.4253 41.3443 42.803C46.4098 38.1485 49.3153 31.5346 49.3153 24.6627C49.3153 17.7908 46.4767 11.3299 41.5355 6.68494H41.5259ZM39.7864 41.1209C35.5524 45.0108 33.0579 50.6402 32.8572 56.6615H30.4296L37.2537 27.1286C37.397 26.5073 37.0147 25.8956 36.3935 25.7523C35.7723 25.5994 35.1606 25.9912 35.0172 26.6125L28.0689 56.6615H21.2256L14.2773 26.6125C14.1339 25.9912 13.5222 25.6089 12.901 25.7523C12.2797 25.8956 11.8974 26.5073 12.0408 27.1286L18.8649 56.6615H16.4468C16.3322 50.7645 13.7803 45.1829 9.25955 40.8915C4.38519 36.2656 1.86199 29.7569 2.33987 23.0188C3.13314 11.932 12.0981 3.05307 23.1945 2.35537C29.4451 1.9635 35.3995 4.09485 39.9489 8.36709C44.4983 12.6393 47.0024 18.4312 47.0024 24.6723C47.0024 30.9134 44.3645 36.906 39.7769 41.1304L39.7864 41.1209Z"
        fill={color || '#FFF'}
      />
      <path
        d="M31.7485 24.8061L28.8525 27.702L25.5361 24.3951C25.2971 24.1562 24.9722 24.051 24.6472 24.0702C24.3318 24.051 24.0068 24.1466 23.7583 24.3951L20.4419 27.702L17.5459 24.8061C17.0967 24.3569 16.3703 24.3569 15.9211 24.8061C15.4719 25.2553 15.4719 25.9817 15.9211 26.4309L19.6295 30.1297C19.8493 30.3495 20.1456 30.4642 20.4419 30.4642C20.7381 30.4642 21.0249 30.3495 21.2543 30.1297L24.6376 26.7463L28.021 30.1297C28.2408 30.3495 28.5371 30.4642 28.8334 30.4642C29.1297 30.4642 29.4164 30.3495 29.6458 30.1297L33.3541 26.4309C33.8034 25.9817 33.8034 25.2553 33.3541 24.8061C32.9049 24.3569 32.1786 24.3569 31.7294 24.8061H31.7485Z"
        fill={color || '#FFF'}
      />
      <path
        d="M31.9109 61.0293H17.4025C15.5865 61.0293 14.1051 62.5107 14.1051 64.3266V65.8559C14.1051 65.9132 14.0669 65.9705 14.0669 66.0279C14.0669 66.0852 14.0956 66.1426 14.1051 66.1999V69.2679C14.1051 69.3253 14.0669 69.3826 14.0669 69.4399C14.0669 69.4973 14.0956 69.5546 14.1051 69.612V70.5008C14.1051 71.9536 15.0322 73.2152 16.4181 73.6453L16.8099 73.7695C17.2782 73.9129 17.7179 74.0467 18.1193 74.2187L24.2362 76.9044C24.3795 76.9713 24.542 77 24.6949 77C24.8478 77 25.0103 76.9618 25.1632 76.9044L31.1558 74.2378C31.519 74.0754 31.9204 73.9511 32.3505 73.8173C32.5512 73.76 32.7424 73.6931 32.9431 73.6262C34.2907 73.177 35.1987 71.9153 35.1987 70.5008V64.3266C35.1987 62.5107 33.7173 61.0293 31.9013 61.0293H31.9109ZM16.3989 68.293V67.1748H32.9144V68.293H16.4085H16.3989ZM17.4025 63.3231H31.9109C32.4652 63.3231 32.9144 63.7723 32.9144 64.3266V64.8714H16.4085V64.3266C16.4085 63.7723 16.8577 63.3231 17.412 63.3231H17.4025ZM32.2263 71.4566C32.0447 71.5139 31.8631 71.5713 31.6815 71.6286C31.2132 71.772 30.7257 71.9249 30.2383 72.1447L24.7045 74.6106L19.0464 72.1352C18.5207 71.9058 17.9855 71.7433 17.4789 71.5904L17.1062 71.4757C16.7143 71.3515 16.4467 71.0074 16.4181 70.5964H32.9049C32.8666 70.9978 32.6181 71.3419 32.2358 71.4661L32.2263 71.4566Z"
        fill={color || '#FFF'}
      />
    </svg>
  );
};

export default BulbIcon;
