import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, Route, Switch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Hidden from '@material-ui/core/Hidden';
import { Navigation } from 'components/ui/Navbar';

import { AUTH_PATHS, ACCOUNT_PATHS, DASHBOARD_PATHS } from 'config/routingPaths';
import { DASHBOARD_MENU, DEFAULT_MENU } from 'config/menus';
import { UserConsumer } from 'context/UserContext';
import { useAuth } from 'hooks/useAuth';

import logoHeader from 'assets/img/logo-header.png';
import { ROLES } from 'utils';

const [loginPath, registrationPath, passwordRestorationPath, userActivation, resetPassword] = AUTH_PATHS;
const dashboardPaths = DASHBOARD_PATHS.concat(ACCOUNT_PATHS);

const useStyles = makeStyles(theme => ({
  avatar: {
    padding: '7.5px 9.5px',
    background: '#D9D9D9',
  },
  userActionMenu: {
    cursor: 'pointer',
  },
  dropdownMenu: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #CCCFD2',
  },
  poper: {
    zIndex: 1
  }
}));

const HeaderLogo = ({ logoPath }) => <RouterLink to={logoPath}>
  <img src={logoHeader} alt="logo" width="218" />
</RouterLink>

const HeaderActionMenu = ({ user, updateUserData, onClickLink }) => {
  
  const classes = useStyles();

  const { signout } = useAuth();
  

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  let userRoleName = '';
  let userCompany = '';
  if (user) {
    if (user.company) {
      userCompany = user.company.name;
      if (user.role.name === ROLES.Applicant) {
        userRoleName = 'Підприємець';
      } else if (user.role.name === ROLES.Novator) {
        userRoleName = 'Новатор';
      }
    }
    if (user.role.name === ROLES.cipe_admin) {
      userCompany = ROLES.cipe_admin;
      userRoleName = ROLES.cipe_admin;
    }
  }

  return <Switch>
    <Route exact path="/">
      <Button variant="contained" color="primary" size="small" component={RouterLink} to="/registration" onClick={onClickLink}>
        Зареєструватися
      </Button>
      <Box component="span" pl={{sm: 2}} >
        <Button variant="outlined" color="primary" size="small" component={RouterLink} to="/login" onClick={onClickLink}>
          Увійти в систему
        </Button>
      </Box>
    </Route>
    <Route path={[loginPath, passwordRestorationPath]}>
      <Button variant="contained" color="primary" size="small" component={RouterLink} to="/registration" onClick={onClickLink}>
        Зареєструватися
      </Button>
    </Route>
    <Route path={[registrationPath, userActivation, resetPassword]}>
      <Button variant="outlined" color="primary" size="small" component={RouterLink} to="/login" onClick={onClickLink}>
        Увійти в систему
      </Button>
    </Route>
    <Route path={dashboardPaths}>
      {user ? 
      <Box component="div" ref={anchorRef} className={classes.userActionMenu} display="flex" alignItems="center" onClick={handleToggle}>
        <Box mr={1}>
          <Box p={1} fontSize={24} borderRadius="borderRadius" className={classes.avatar}>
            <Box component="i" className="fa fa-user" color="text.secondary" />
          </Box>
        </Box>
        <Box>
          <Box fontSize={14} fontWeight={600} mb={1}>
            {userCompany}
          </Box>
          <Box fontSize={10} color="text.secondary">
            {userRoleName}
          </Box>
        </Box>
        <Box mr={2} p={2}>
          <Box component="i" className={`fa fa-sort-down${open ? ' fa-flip-vertical' : ''}`} color="text.secondary" />
        </Box>
      </Box> :
      <>
        <Box component="span" mr={2}>
          <Button variant="contained" color="primary" size="small" component={RouterLink} to="/registration" onClick={onClickLink}>
            Зареєструватися
          </Button>
        </Box>
        <Button variant="outlined" color="primary" size="small" component={RouterLink} to="/login" onClick={onClickLink}>
          Увійти в систему
        </Button>
      </> 
      }
      <Popper className={classes.poper} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.dropdownMenu} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={() => {
                    setOpen(false)
                    if (onClickLink) {
                      onClickLink()
                    }
                  }} component={RouterLink} to="/account/profile">
                    Налаштування профілю
                  </MenuItem>
                  <MenuItem onClick={() => {
                    setOpen(false)
                    if (onClickLink) {
                      onClickLink()
                    }
                  }} component={RouterLink} to="/account/change-password">
                    Налаштування паролю
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                      signout().then(() => updateUserData());
                      if (onClickLink) {
                        onClickLink()
                      }
                    }}>
                    Вийти із системи
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Route>
  </Switch>
}

const HeaderMobileMenu = ({ children, logo }) => {
  const [drawerOpened, setDrawerOpened] = useState(false)
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpened(open);
  };

  return <>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={toggleDrawer(true)}
      edge="start"
      
    >
      <MenuIcon />
    </IconButton>
    <SwipeableDrawer
      anchor="left"
      open={drawerOpened}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {children(toggleDrawer, drawerOpened)}
    </SwipeableDrawer>
  </>
}

export const Header = () => {
  const location = useLocation().pathname;
  return (
    <UserConsumer>
      {({ loggedInUser, updateUserData }) => {
        const persistUser = localStorage.getItem('user');
        const parsedUser = JSON.parse(persistUser);
        const user = loggedInUser || parsedUser;
        const menu = user ? DASHBOARD_MENU : DEFAULT_MENU;
        const logoPath = user ? '/entrepreneurs' : '/';
        

        return (
          <Container maxWidth={location === '/' ? 'lg' : false}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <HeaderLogo logoPath={logoPath}/>
              </Grid>
              <Hidden mdDown>
                <Grid item>
                  <Toolbar component="nav" variant="dense">
                    <Navigation links={menu} />
                  </Toolbar>
                </Grid>
                <Grid item>
                  <HeaderActionMenu user={user} updateUserData={updateUserData}/>
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <HeaderMobileMenu>
                  {(toggleDrawer) => {
                    return <>
                      <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                        <HeaderLogo logoPath={logoPath}/>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={toggleDrawer(false)}
                          edge="start"
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                      </Box>
                      <Box p={2}>
                        <HeaderActionMenu user={user} updateUserData={updateUserData} onClickLink={toggleDrawer(false)}/>
                      </Box>
                      <Box>
                        <Navigation links={menu} mode="vertical" onClick={toggleDrawer(false)}/>
                      </Box>
                    </>
                  }}
                </HeaderMobileMenu>
              </Hidden>
            </Grid>
          </Container>
        );
      }}
    </UserConsumer>
  );
};

export default Header;
