import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { getNovator, createNovator, updateNovator } from "api/novators";
import ProfileForm from "components/forms/ProfileForm";


const ManageNovatorPage = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { execute, value, error, pending } = useAsync( id ? updateNovator : createNovator, false);
  const [externalErrors, setExternalErrors] = useState({});
  const [activeAction, setActiveAction] = useState();

  const { execute: fetchItem, value: itemResponse, pendingItem } = useAsync(getNovator, false);
  const [item, setItem] = useState();

  useEffect(() => {
    if (id) {
      fetchItem(id)
    }
  }, [id, fetchItem]);
  useEffect(() => {
    if (itemResponse) {
      const {id: itemId, name, email, country_code, region, address, contact, website, industries, description, lat, lon} = itemResponse;
      setItem({id: itemId, name, email, country_code, region, address, contact, website,
        industries: industries.map(item => item.id),
        description,
        lat, lon,
        type: 1, // for valid form
      })
    }
  }, [setItem, itemResponse])

  useEffect(() => {
    if (id) {
      if (value) {
        enqueueSnackbar('Novator has been updated', {variant: 'success'})
      } else if (error) {
        enqueueSnackbar('There is error', {variant: 'error'})
        setExternalErrors(error)
      }
    } else {
      if (value) {
        enqueueSnackbar('Novator has been created', {variant: 'success'})
        activeAction.resetForm()
      } else if (error) {
        enqueueSnackbar('There is error', {variant: 'error'})
        setExternalErrors(error)
      }
    }
  }, [id, execute, value, error, activeAction]);

  const handleSubmit = (values, actions) => {
    setActiveAction(actions)
    if (id) {
      execute({id, data: values});
    } else {
      execute(values);
    }
  }

  const onBlur = () => {
    setExternalErrors({})
  }
  
  return <Box p={6}>
    <Box py={4}>
      <Typography component="h2">{id ? 'Update' : 'Create'} Novator</Typography>
    </Box>
    { (pending || pendingItem) ? <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box> : null}
    {
      item ?
      <ProfileForm initValue={item} formSubmit={handleSubmit} formBlur={onBlur} externalErrors={externalErrors} submitButtonText={id ? 'Update' : 'Create'} loading={pending} showRole={false} />
      : null
    }
      
  </Box>
  
}

export default ManageNovatorPage;