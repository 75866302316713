import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import useAsync from 'hooks/useAsync';
// Instruments
import { changePassword } from 'api/auth';
import PasswordSettingForm from 'components/forms/PasswordSettingForm';

export const ChangePasswordPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { execute: onResetPassword, error: changePasswordError, value: changePasswordRespone } = useAsync(changePassword, false);

  const changeUserPassword = values => {
    const activateData = {
      old_password: values.oldPassword,
      password: values.password,
    };
    onResetPassword(activateData);
  };

  useEffect(() => {
    if (changePasswordRespone) {
      enqueueSnackbar(changePasswordRespone.message, { variant: 'success' });
    }
  }, [changePasswordRespone]);

  return (
    <Box>
      <Typography variant="h3" component="h1" gutterBottom>
        Відновлення паролю
      </Typography>
      <PasswordSettingForm formSubmit={changeUserPassword} errorReport={changePasswordError} />
    </Box>
  );
};

export default ChangePasswordPage;
