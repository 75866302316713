// Core
import React, { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DashboardSearch from 'components/Dashboard/DashboardSearch';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { escapeRegExp } from 'lodash';
import CustomTablePagination from 'components/ui/CustomTablePagination/CustomTablePagination';
import { ArrowDropDown, ArrowDropUp, ArrowRight } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import LibraryDetails from '../libraryDetails/LibraryDetails';
import { getBusinesses } from 'api/businesses';
import useAsync from 'hooks/useAsync';
import { useDebounce } from 'hooks/useDebounce';
import { useHistory, useLocation } from 'react-router-dom';

const pageSize = 10;
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDataGrid-main': {
      '&~ div': {
        position: 'sticky',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: 'var(--white)',
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      position: 'sticky',
      zIndex: '1',
      backgroundColor: 'var(--white)',
    },
    '& .MuiIconButton-root': {
      '&.MuiIconButton-colorPrimary': {
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'var(--primary-regular)',
          borderColor: 'var(--border-color)',
        },
      },
      '&.Mui-disabled': {
        color: 'var(--primary-regular)',
        borderColor: 'var(--border-color)',
      },
    },
    '& .MuiDataGrid-columnHeader--sortable': {
      '& .MuiDataGrid-iconButtonContainer': {
        '& .MuiIconButton-root': {
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'var(--white)',
          },
        },
      },
    },
    '& .custom-grid-overlay': {
      top: '46px',
    },
    '&.MuiDataGrid-root': {
      border: 0,
      '& .MuiDataGrid-columnHeader': {
        outline: 0,
      },
      '& .MuiDataGrid-cell': {
        outline: 0,
        padding: 0,
        cursor: 'pointer',
      },
      '& .MuiDataGrid-row': {
        '&:hover': {
          '& .MuiButtonBase-root': {
            opacity: 1,
          },
        },
        '&.Mui-selected': {
          '& .MuiDataGrid-cell': {
            backgroundColor: 'var(--white)',
            border: '1px solid var(--primary-regular)',
            borderRadius: '4px',
          },
          '& .MuiButtonBase-root': {
            opacity: 1,
            color: 'var(--white)',
            backgroundColor: 'var(--primary-regular)',
            borderColor: 'var(--primary-regular)',
          },
          '&:hover': {
            backgroundColor: 'var(--white)',
          },
        },
      },
      '&.MuiDataGrid-autoHeight': {
        height: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '10px',
      color: 'var(--grey)',
      textTransform: 'uppercase',
      fontWeight: '400',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
      padding: '0 16px',
    },
  },
  rowButton: {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
    }
  },
  rowHolder: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  sortIconsContainer: {
    fontSize: '15px',
  },
  fullHeight: {
    height: '100%',
  },
  cardsSearch: {
    padding: '0 12px',
    [theme.breakpoints.up('md')]: {
      padding: '0 48px',
    }
  },
  dataGridWrapper: {
    margin: '5px 0',
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    padding: '0 12px',
    margin: '3px',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: 'calc(100% - 79px - 89px)',
      padding: '0 45px',
    }
  },
  sectionHeader: {
    padding: '16px 48px',
    marginRight: '-4px',
    borderBottom: '1px solid var(--border-color)',
    height: '79px',
    display: 'flex',
    alignItems: 'center',
  },
  itemsWrapper: {
    height: '100%',
    borderRight: '1px solid var(--border-color)',
  },
  detailsContainer: {
    height: '100%',
  },
}));

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const Library = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Назва програми',
      disableColumnMenu: true,
      flex: 1,
      renderCell: params => {
        // items.forEach(item => {
          // if (item.id === params.id) {
            // TODO: refactor
            // currentItems.push(item);
          // }
        // });
        // setNumberOfCurrentItems(currentItems.length.toString());
        return (
          <Box className={classes.rowHolder}>
            <Box component="span" sx={{ marginRight: '16px' }}>
              {params.value}
            </Box>
            <IconButton disabled color="primary" className={classes.rowButton}>
              <ArrowRight />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const { push } = useHistory();
  

  const classes = useStyles();
  const [item, setItem] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  const [totalItems, setTotalItems] = useState(1);
  const { execute: loadList, value: loadedList, pending: listPending } = useAsync(getBusinesses, false);
  
  const onBack = () => {
    setItem({})
  }
  useEffect(() => {
    if (loadedList) {
      setRows(loadedList.data);
      setCurrentPageItems(loadedList.data.length);
      setTotalItems(loadedList.total)
    }
  }, [loadedList]);

  const [searchText, setSearchText] = useState('');
  const requestSearch = (event) => {
    setPage(1);
    setSearchText(event.target.value)
  }

  const handlePageChange = (event) => {
    setPage(event + 1)
  }
  const handleFiltersChange = useDebounce(() => {
    
    const params = {
      filter_value: searchText,
      filter_by: 'name',
      order_by: 'name',
      offset: (page - 1) * pageSize,
      limit: pageSize,
    };
    loadList( params)
    
  }, 600);

  useEffect(handleFiltersChange, [page, searchText]);

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} md={6} className={classes.itemsWrapper}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h4" component="h4">
            Бібліотека
          </Typography>
        </Box>
        <Box className={classes.fullHeight} sx={{ padding: '8px 3px' }}>
          <Box className={classes.cardsSearch}>
            <DashboardSearch onSearchChange={requestSearch} />
          </Box>
          <Box className={classes.fullHeight}>
            <Box className={classes.dataGridWrapper}>
              <DataGrid
                autoHeight
                className={classes.root}
                hideFooterSelectedRowCount
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[pageSize]}
                rowCount={totalItems}
                headerHeight={46}
                rowHeight={47}
                hideFooterPagination={totalItems < pageSize}
                onCellClick={(params) => {
                  if (mediaQuery) {
                    console.log(params.row.id)
                    push('/library/' + params.row.id)
                  } else {
                    setItem(params.row);
                  }
                  
                }}
                paginationMode="server"
                ColumnSortedAscendingIcon={<ArrowDropDown />}
                ColumnSortedDescendingIcon={<ArrowDropUp />}
                loading={listPending}
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                  Pagination: CustomTablePagination,
                  NoRowsOverlay: () => <div className="MuiDataGrid-overlay custom-grid-overlay">Немає даних</div>,
                  ColumnSortedDescendingIcon: () => (
                    <div className={classes.sortIconsContainer}>
                      <ArrowDropDown color="primary" fontSize="inherit" />
                      <ArrowDropUp color="secondary" fontSize="inherit" />
                    </div>
                  ),
                  ColumnSortedAscendingIcon: () => (
                    <div className={classes.sortIconsContainer}>
                      <ArrowDropDown color="secondary" fontSize="inherit" />
                      <ArrowDropUp color="primary" fontSize="inherit" />
                    </div>
                  ),
                }}
                componentsProps={{
                  pagination: {
                    numberOfCurrentItems: currentPageItems,
                    numberOfItems: totalItems,
                    pageChange: handlePageChange
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} className={classes.detailsContainer}>
        <LibraryDetails item={item}  onBack={onBack} />
      </Grid>
    </Grid>
  );
};

export default Library;
