import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { refresh_token } from 'api/auth';
import { useAuth } from 'hooks/useAuth';

const useAsync = (asyncFunction, immediate = true, showSnackbar = true) => {
  const { push } = useHistory();
  const { signout } = useAuth();
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const execute = useCallback(
    values => {
      setPending(true);
      setValue(null);
      setError(null);
      setStatus(null);
      return asyncFunction(values)
        .then(({ data, status }) => {
          setValue(data);
          setStatus(status);
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response);
            setStatus(err.response.status);
            if (err.response.status === 401) {
              const errorMsg = `${err.response.status} ${err.response.statusText}`;
              setError(err.response.data);
              // Refreshing both tokens if refresh token not expired
              return refresh_token()
                .then(resp => {
                  if (resp.status === 200) {
                    const { access_token } = resp.data;
                    localStorage.setItem('access_token', access_token);
                    asyncFunction(values).then(({ data, status }) => {
                      setValue(data);
                      setStatus(status);
                    });
                  }
                  // Logout user, if refresh token expired
                })
                .catch(err => {
                  signout();
                });
            }

            if (err.response.status >= 500) {
              const errorMsg = `${err.response.status} ${err.response.statusText}`;
              setError(err.response.data);
              push('/500');
              enqueueSnackbar(errorMsg, {
                variant: 'error',
              });
              return errorMsg;
            }

            if (err.response.status === 404) {
              const errorMsg = `${err.response.status} requested data is ${err.response.statusText}`;
              setError(err.response.data);
              // push('/404');
              enqueueSnackbar(errorMsg, {
                variant: 'error',
              });
              return errorMsg;
            }

            const errorStatus = `(Error ${err.response.status})`;
            const errorMsg = err.response.data.errors ? Object.values(err.response.data.errors)[0] : err.response.data.msg;
            let error = '';
            if (typeof errorMsg === 'object') {
              let errorInner = '';
              for (const errorsKey in errorMsg) {
                if (typeof errorMsg[errorsKey] === 'string') {
                  errorInner = `Field '${errorsKey}': ${errorMsg[errorsKey]}`;
                } else {
                  for (const errorsKeyOne in errorMsg[errorsKey]) {
                    if (typeof errorMsg[errorsKey][errorsKeyOne] === 'string') errorInner = `Field '${errorsKey}, ${errorsKeyOne}': ${errorMsg[errorsKey][errorsKeyOne]}`;
                  }
                }
              }
              error = `${errorStatus}. ${errorInner}`;
            } else {
              error = `${errorStatus}. ${errorMsg}`;
            }
            setError(err.response.data);
            if (showSnackbar) {
              enqueueSnackbar(error, {
                variant: 'error',
              });
            }
            return error;
          }
          if (err.request) {
            console.log(err.request);
          } else if (err.message) {
            console.log('Error', err.message);
            throw err;
          }
          console.log(err.config);
        })
        .finally(() => setPending(false));
    },
    [asyncFunction, enqueueSnackbar, push, showSnackbar, signout],
  );

  useEffect(() => {
    let isMounted = true;
    if (immediate && isMounted) {
      execute();
    }
    return () => {
      isMounted = false;
    };
  }, [execute, immediate]);

  return { execute, pending, value, setValue, error, status };
};

export default useAsync;
