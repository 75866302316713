import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Applicants from 'pages/applicants/Applicants';
import Synergies from '../pages/synergies/Synergies';
import Innovators from '../pages/innovators/Innovators';
import Library from '../pages/library/Library';
import ApplicantDetails from '../pages/applicantDetails/ApplicantDetails';
import SynergyDetails from '../pages/synergyDetails/SynergieDetails';
import InnovatorDetails from '../pages/innovatorDetails/InnovatorDetails';
import RouteAuthenticated from 'routes/AuthenticatedRoute';
import ManageSynergiesPage from 'pages/manageSynergies/ManageSynergiesPage';
import ManageLibrariesPage from 'pages/manageLibraries/ManageLibrariesPage';
import ManageSynergyPage from 'pages/manageSynergy/ManageSynergyPage';
import { ROLES } from 'utils';
import ManageApplicantPage from 'pages/manageApplicant/ManageApplicantPage';
import ManageNovatorPage from 'pages/manageNovator/ManageNovatorPage';
import LibraryDetails from 'pages/libraryDetails/LibraryDetails';

const useStyles = makeStyles(theme => ({
  fullHeight: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 80px)',
    }
  },
}));
export const DashboardLayout = () => {
  const classes = useStyles();
  const location = useLocation();
  const useFullHeight = ['create-synergy', 'manage-applicant', 'manage-novator', 'manage-synergies', 'manage-libraries'].some((item) => location.pathname.includes(item));
  
  return (
    <Box component="section" className={useFullHeight ? null : classes.fullHeight}>
      <Switch>
        <Route exact path="/entrepreneurs">
          <Applicants />
        </Route>
        <RouteAuthenticated exact path="/entrepreneurs/:id">
          <ApplicantDetails />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/synergies">
          <Synergies />
        </RouteAuthenticated>
        <RouteAuthenticated exact path="/synergies/:id">
          <SynergyDetails />
        </RouteAuthenticated>

        <Route exact path="/innovators">
          <Innovators />
        </Route>
        <RouteAuthenticated exact path="/innovators/:id">
          <InnovatorDetails />
        </RouteAuthenticated>

        <Route exact path="/library">
          <Library />
        </Route>

        <Route exact path="/library/:id">
          <LibraryDetails />
        </Route>

        <RouteAuthenticated exact path="/upload-synergies" roles={[ROLES.cipe_admin]}>
          <ManageSynergiesPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path={["/manage-synergies", "/manage-synergies/:id"]} roles={[ROLES.cipe_admin]}>
          <ManageSynergyPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/manage-applicant" roles={[ROLES.cipe_admin]}>
          <ManageApplicantPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/manage-applicant/:id" roles={[ROLES.cipe_admin]}>
          <ManageApplicantPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/manage-novator" roles={[ROLES.cipe_admin]}>
          <ManageNovatorPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/manage-novator/:id" roles={[ROLES.cipe_admin]}>
          <ManageNovatorPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/manage-libraries" roles={[ROLES.cipe_admin]}>
          <ManageLibrariesPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/manage-libraries/:id" roles={[ROLES.cipe_admin]}>
          <ManageLibrariesPage />
        </RouteAuthenticated>

        <RouteAuthenticated exact path="/create-synergy" roles={[ROLES.cipe_admin]}>
          <ManageSynergyPage />
        </RouteAuthenticated>

      </Switch>
    </Box>
  );
};

export default DashboardLayout;
