import * as yup from 'yup';
import { Form, Formik } from "formik";
import { Box, Grid , Button } from "@material-ui/core";
import FormikInput from "components/ui/FormikInput";
import { Link } from "react-router-dom";

const validationSchema = yup.object({
  name: yup.string('Enter your email').required('Required'),
  short_description: yup.string('Enter your password'),
  conditions: yup.string('Enter your password'),
  additional_info: yup.string('Enter your password')
});

const initialValues = {
  name: '',
  short_description: '',
  conditions: '',
  additional_info: ''
};

const BusinessForm = ({ initValues, externalErrors, handleSubmit, isUpdate, loading }) => {
  return <Formik initialValues={initValues || initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
    {({ values, isSubmitting }) => {
      return <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikInput mandatory key="name" id="name" name="name" label="Назва" type="text" offMultiline value={values.name} />
            </Grid>
            <Grid item xs={12}>
              <FormikInput
                key="short_description"
                id="short_description"
                name="short_description"
                label="Короткий опис"
                rows={4}
                type="url"
                value={values.short_description}
                apiError={externalErrors.short_description}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikInput
                key="conditions"
                id="conditions"
                name="conditions"
                label="Умови"
                rows={4}
                type="url"
                value={values.conditions}
                apiError={externalErrors.conditions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikInput
                key="additional_info"
                id="additional_info"
                name="additional_info"
                label="Додаткова інформація"
                rows={4}
                type="url"
                value={values.additional_info}
                apiError={externalErrors.additional_info}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" mb={2} justifyContent="flex-end">
                <Box component="span" pr={2}>
                  <Button variant="outlined" color="primary" component={Link} to="/library">
                    Відмінити
                  </Button>
                </Box>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                  {isUpdate ? 'Редагувати' : 'Створити'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
    }}
  </Formik>
}

export default BusinessForm;