// Core
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
// Components
import DefaultLayout from 'layouts/DefaultLayout';
import AuthLayout from 'layouts/AuthLayout';
import DashboardLayout from 'layouts/DashboardLayout';

// Pages
import HomePage from 'pages/HomePage';
import Styleguide from 'pages/Styleguide';
import NotFoundPage from 'pages/NotFoundPage';

// Context
import UserProvider from 'context/UserContext';

// Constants
import { AUTH_PATHS, ACCOUNT_PATHS, DASHBOARD_PATHS } from 'config/routingPaths';
import AccountLayout from 'layouts/AccountLayout';
import RouteAuthenticated from 'routes/AuthenticatedRoute';
import RouteUnauthenticated from 'routes/UnauthenticatedRoute';

const App = () => {
  const location = useLocation();
  const captchaRoutes = AUTH_PATHS;

  React.useEffect(() => {
    if (process.env.REACT_APP_RECAPTCHA_KEY) {
      if (captchaRoutes.includes(location.pathname) && process.env.REACT_APP_RECAPTCHA_KEY) {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
          if (!isScriptExist) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            script.id = id;
            script.onload = function() {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
          if (isScriptExist && callback) callback();
        };
        loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY.trim()}`);
      } else {
        const nodeBadge = document.querySelector('.grecaptcha-badge');
        if (nodeBadge !== null) {
          if (nodeBadge) {
            document.body.removeChild(nodeBadge.parentNode);
          }
          const scriptSelectorGstatic = 'script[src*="https://www.gstatic.com/recaptcha/"]';
          const scriptSelectorGoogle = 'script[src*="https://www.google.com/recaptcha/"]';
          const scriptGstatic = document.querySelector(scriptSelectorGstatic);
          const scriptGoogle = document.querySelector(scriptSelectorGoogle);
          if (scriptGstatic && scriptGoogle) {
            scriptGstatic.remove();
            scriptGoogle.remove();
          }
        }
      }
    }
  }, [location.pathname, captchaRoutes]);

  return (
    <UserProvider>
      <DefaultLayout>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <RouteUnauthenticated path={AUTH_PATHS}>
            <AuthLayout />
          </RouteUnauthenticated>
          <RouteAuthenticated path={ACCOUNT_PATHS}>
            <AccountLayout />
          </RouteAuthenticated>
          <Route exact path={DASHBOARD_PATHS}>
            <DashboardLayout />
          </Route>
          <Route path="/styleguide">
            <Styleguide />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </DefaultLayout>
    </UserProvider>
  );
};

export default App;
