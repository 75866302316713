import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import FormikInput from 'components/ui/FormikInput';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const initialValues = { email: '' };

const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]+$/);

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGEX, {
      message: 'Invalid email address',
      excludeEmptyString: true,
    })
    .required('Required'),
});

const ForgotPasswordForm = ({ className, getCodeError, onGetCode }) => {
  const onSubmit = values => {
    onGetCode(values);
  };

  const mailError = getCodeError && getCodeError.split('.');
  const renderForm = values => {
    const form = Object.keys(values).map(key => {
      const type = 'text';
      let error = '';
      if (mailError && mailError[1]) {
        error = mailError[1];
      }

      return <FormikInput key={key} mandatory type={type} offMultiline id={key} name={key} label={key} value={values[key]} apiError={error} />;
    });

    return form;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
      {({ values, handleSubmit, isSubmitting, isValid }) => (
        <form className={className} onSubmit={handleSubmit}>
          {renderForm(values)}
          <Box display="flex" justifyContent="flex-end">
            <Box component="span" pr={2}>
              <Button variant="outlined" color="primary" component={Link} to="/login">
                Відмінити
              </Button>
            </Box>

            <Button variant="contained" color="primary" type="submit" disabled={!isValid || isSubmitting}>
              Підтвердити
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
