// Core
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import SimpleSelect from '../ui/SimpleSelect';
import SimpleSearch from '../ui/SimpleSearch';
import SimpleSort from '../ui/SimpleFilter';

const useStyles = makeStyles(theme => ({
  searchWrapper: {
    [theme.breakpoints.up('sm')]: {
      padding: '14px 0 11px 0',
      height: '89px',
    }
  },
  searchHolder: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    }
  },
  selectWrapper: {
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '8px',
      flex: '0 0 auto'
    }
  },
  searchComponentWrapper: {
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '8px',
      width: '100%'
    }
  },
  sortWrapepr: {
    marginBottom: '8px',
    flex: '0 0 auto',
    width: '32px'
  }
}));

const DashboardSearch = ({ selectItems, selected, onSelectChange, onSearchChange, sortItems, sortSelected, onSortSelectChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.searchWrapper}>
      <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
        Пошук
      </Typography>
      {selectItems.length || sortItems.length ? (
        <Box className={classes.searchHolder}>
          <Box className={classes.selectWrapper}>
            <SimpleSelect items={selectItems} selected={selected} onChange={onSelectChange} />
          </Box>
          <Box className={classes.searchComponentWrapper}>
            <SimpleSearch onChange={onSearchChange}></SimpleSearch>
          </Box>
          <Box className={classes.sortWrapepr}>
            <SimpleSort items={sortItems} selected={sortSelected} onChange={onSortSelectChange} />
          </Box>
        </Box>
      ) : (
        <Box className={classes.searchHolder}>
          <Box sx={{ width: '100%' }}>
            <SimpleSearch onChange={onSearchChange}></SimpleSearch>
          </Box>
        </Box>
      )}
    </Box>
  );
};

DashboardSearch.defaultProps = {
  selectItems: [],
  sortItems: [],
  selected: '',
  sortSelected: '',
  onSelectChange: () => null,
  onSearchChange: () => null,
  onSortSelectChange: () => null,
};

DashboardSearch.propTypes = {
  selectItems: PropTypes.array,
  sortItems: PropTypes.array,
  selected: PropTypes.string,
  sortSelected: PropTypes.string,
  onSelectChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSortSelectChange: PropTypes.func,
};

export default DashboardSearch;
