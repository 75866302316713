import client from './client';

const ENDPOINT = '/businesses';

async function createBusiness(data) {
  const response = await client.post(`${ENDPOINT}/`, data);
  if (response.status === 201) {
    return response;
  }
}

async function getBusiness(id) {
  const response = await client.get(`${ENDPOINT}/${id}/`);
  if (response.status === 200) {
    return response;
  }
}

async function getBusinesses(params) {
  const response = await client.get(`${ENDPOINT}/`, { params });
  if (response.status === 200) {
    return response;
  }
}

async function updateBusiness({ id, data }) {
  const response = await client.put(`${ENDPOINT}/${id}/`, data);
  if (response.status === 200) {
    return response;
  }
}

async function deleteBusiness(id) {
  const response = await client.delete(`${ENDPOINT}/${id}/`);
  if (response.status === 200) {
    return response;
  }
}

export {
  createBusiness,
  getBusiness,
  getBusinesses,
  updateBusiness,
  deleteBusiness,
};
