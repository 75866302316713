// Core
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Search } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
  searchIcon: {
    fontSize: '20px',
  },
}));

const SimpleSearch = ({ onChange }) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        onChange={onChange}
        variant="outlined"
        label={null}
        placeholder="Введіть пошук"
        id="simple-search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

SimpleSearch.defaultProps = {
  onChange: () => null,
};

SimpleSearch.propTypes = {
  onChange: PropTypes.func,
};

export default SimpleSearch;
