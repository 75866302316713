import React, { useEffect } from 'react';
import { useHistory, useRouteMatch, Switch, Route, Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ForgotPasswordForm from 'components/ui/ForgotPasswordForm';
// Hooks
import useAsync from 'hooks/useAsync';
// Instruments
import { getResetCode } from 'api/auth';

export const ForgotPasswordPage = () => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const { execute: onGetCode, value: result, error: getCodeError, status } = useAsync(getResetCode, false, false);

  useEffect(() => {
    if (status === 200 && url) {
      const timeout = setTimeout(() => {
        push(`${url}/request-sent`);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [result, push, url, status]);

  return (
    <Box>
      <Typography variant="h3" component="h1" gutterBottom>
        Відновлення паролю
      </Typography>
      <Box component="h2" fontSize={14} fontWeight={500} color="text.secondary">
        <Switch>
          <Route exact path={url}>
            Введіть e-mail, на який зареєстровано аккаунт.
          </Route>
          <Route path={`${url}/request-sent`}>На вказаний e-mail був відправлений пароль для входу в систему.</Route>
        </Switch>
      </Box>
      <Box py={3}>
        <Switch>
          <Route exact path={url}>
            <ForgotPasswordForm getCodeError={getCodeError} onGetCode={onGetCode} />
          </Route>
          <Route path={`${url}/request-sent`}>
            <Button variant="contained" color="primary" component={Link} to="/login">
              Зрозуміло
            </Button>
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
