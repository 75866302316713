import React from 'react';
import { usePagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useGridSlotComponentProps } from '@mui/x-data-grid';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  paginationList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  paginationNumber: {
    margin: '0 2px',
  },
  paginationNavButton: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: '1px solid var(--border-color)',
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    padding: 0,
    fontSize: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&[aria-current="true"]': {},
  },
  paginationButton: {
    border: 0,
    color: 'var(--primary-regular)',
    backgroundColor: 'transparent',
    fontSize: '14px',
    width: '32px',
    height: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    fontWeight: '500',
  },
  dots: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '14px',
    height: '32px',
    fontSize: '12px',
    letterSpacing: '.8px',
    color: 'var(--primary-regular)',
  },
  paginationHolder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    '& .MuiPaginationItem-root': {
      color: 'var(--primary-regular)',
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      backgroundColor: 'var(--black-100)',
      color: 'var(--white)',
    },
    '& .MuiPagination-ul li:last-of-type .MuiPaginationItem-page': {
      border: '1px solid var(--border-color)',
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1rem',
    },
  },
});

// const CustomPagination = () => {
//   const { state, apiRef } = useGridSlotComponentProps();
//   const classes = useStyles();
//   return (
//     <Pagination
//       className={classes.root}
//       color="primary"
//       count={state.pagination.pageCount}
//       page={state.pagination.page + 1}
//       shape="rounded"
//       hidePrevButton={true}
//       onChange={(event, value) => apiRef.current.setPage(value - 1)}
//     />
//   );
// }

const CustomPagination = ({ numberOfCurrentItems, numberOfItems, pageChange }) => {
  const classes = useStyles();
  const { state, apiRef } = useGridSlotComponentProps();
  const { items } = usePagination({
    count: state.pagination.pageCount,
    color: 'primary',
    page: state.pagination.page + 1,
    shape: 'rounded',
    hidePrevButton: false,
    hideNextButton: false,
    onChange: (event, value) => {
      apiRef.current.setPage(value - 1);
      pageChange(value - 1)
    },
  });

  const selectedStyles = selected => {
    return {
      backgroundColor: selected ? 'var(--black-100)' : undefined,
      color: selected ? 'var(--white)' : 'var(--primary-regular)',
    };
  };

  return (
    <Box className={classes.paginationHolder}>
      <Typography color="textSecondary" variant="h6" component="h6">
        Показано {numberOfCurrentItems} аплікантів з {numberOfItems}
      </Typography>
      <nav>
        <ul className={classes.paginationList}>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = <span className={classes.dots}>...</span>;
            } else if (type === 'page') {
              children = (
                <button className={classes.paginationButton} type="button" style={selectedStyles(selected)} {...item}>
                  {page}
                </button>
              );
            } else if (type === 'previous') {
              children = (
                <button type="button" {...item} className={classes.paginationNavButton}>
                  <ArrowLeft fontSize="inherit" color="primary" />
                </button>
              );
            } else if (type === 'next') {
              children = (
                <button type="button" {...item} className={classes.paginationNavButton}>
                  <ArrowRight fontSize="inherit" color="primary" />
                </button>
              );
            }

            return (
              <li className={classes.paginationNumber} key={index}>
                {children}
              </li>
            );
          })}
        </ul>
      </nav>
    </Box>
  );
};

export default CustomPagination;
