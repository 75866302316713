import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import authBanner from 'assets/img/auth-banner.jpg';
import Footer from 'components/blocks/Footer';
import ProfilePage from 'pages/account/ProfilePage';
import ChangePasswordPage from 'pages/account/ChangePasswordPage';

const useStyles = makeStyles(() => ({
  fullHeight: {
    minHeight: 'calc(100vh - 64px)',
  },
  authBackground: {
    backgroundImage: `url(${authBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  formContainer: {
    maxWidth: '440px',
  },
}));
export const AccountLayout = () => {
  const classes = useStyles();
  return (
    <Box component="section">
      <Grid container className={classes.fullHeight}>
        <Grid item xs={12} lg={6}>
          <Box p={4} height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Switch>
                <Route path="/account/profile">
                  <Box>
                    <ProfilePage />
                  </Box>
                </Route>
                <Route path="/account/change-password">
                  <Box>
                    <ChangePasswordPage />
                  </Box>
                </Route>
              </Switch>
            </Box>
            <Footer />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.authBackground} />
      </Grid>
    </Box>
  );
};

export default AccountLayout;
