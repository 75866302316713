import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import FormikInput from 'components/ui/FormikInput';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// Constants
import { PASSWORD_REGEX } from 'utils';

const initialValues = { password: '', 'confirm Password': '' };

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Пароль має містити принайні 8 символів')
    .max(16, 'Пароль не має перевищувати 16 символів')
    .matches(PASSWORD_REGEX, {
      message: 'Пароль має містити літери верхнього та нижнього регістру, цифри, та принайні один із цих спец символів @#$%^&+=',
      excludeEmptyString: true,
    })
    .required('Поле є обов\'язковим'),

  'confirm Password': Yup.string()
    .required('Поле є обов\'язковим')
    .matches(PASSWORD_REGEX, {
      message: 'Пароль занадто легкий',
      excludeEmptyString: true,
    })
    .oneOf([Yup.ref('password'), null], 'Пароль має співпадати'),
});

const ResetPasswordForm = ({ resetPasswordError, formResetPassword, className }) => {
  const onSubmit = (values, setSubmitting) => {
    formResetPassword({ id, values });
    setTimeout(() => {
      setSubmitting(false);
    }, 1500);
  };

  let { id } = useParams();

  const codeError = resetPasswordError && resetPasswordError.message;
  const passwordError = resetPasswordError && resetPasswordError.password;

  const renderForm = values => {
    const form = Object.keys(values).map(key => {
      const type = key === 'code' ? 'text' : 'password';
      const error = key === 'code' ? codeError : passwordError;

      return <FormikInput key={key} mandatory type={type} offMultiline={key === 'code'} id={key} name={key} label={key} value={values[key]} apiError={error} />;
    });

    return form;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}>
      {({ values, handleSubmit, isSubmitting, isValid }) => (
        <form className={className} onSubmit={handleSubmit}>
          {renderForm(values)}
          <Box display="flex" justifyContent="flex-end">
            <Box component="span" pr={2}>
              <Button variant="outlined" color="primary" component={Link} to="/login">
                Відмінити
              </Button>
            </Box>

            <Button variant="contained" color="primary" type="submit" disabled={!isValid || isSubmitting}>
              Підтвердити
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
