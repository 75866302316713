import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import authBanner from 'assets/img/auth-banner.jpg';
import Footer from 'components/blocks/Footer';
import LoginPage from 'pages/auth/LoginPage';
import RegistrationPage from 'pages/auth/RegistrationPage';
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage';
import ActivateUserPage from 'pages/auth/ActivateUserPage';
import ResetPasswordPage from 'pages/auth/ResetPasswordPage';

const useStyles = makeStyles(() => ({
  fullHeight: {
    minHeight: 'calc(100vh - 64px)',
  },
  authBackground: {
    backgroundImage: `url(${authBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  formContainer: {
    maxWidth: '440px',
  },
}));
export const AuthLayout = () => {
  const classes = useStyles();
  return (
    <Box component="section">
      <Grid container className={classes.fullHeight}>
        <Grid item xs={12} lg={6}>
          <Box p={4} height="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Button variant="outlined" color="primary" component={Link} to="/login">
                <i className="fa fa-chevron-left" />{' '}
                <Box component="span" px={2}>
                  Назад
                </Box>
              </Button>
            </Box>

            <Box>
              <Switch>
                <Route path="/login">
                  <Box className={classes.formContainer}>
                    <LoginPage />
                  </Box>
                </Route>
                <Route path="/registration">
                  <RegistrationPage />
                </Route>
                <Route path="/forgot_password">
                  <Box className={classes.formContainer}>
                    <ForgotPasswordPage />
                  </Box>
                </Route>
                <Route path="/reset_password/:user_id">
                  <Box className={classes.formContainer}>
                    <ResetPasswordPage />
                  </Box>
                </Route>
                <Route path="/user_activation/:activation_token">
                  <Box className={classes.formContainer}>
                    <ActivateUserPage />
                  </Box>
                </Route>
                <Route path="/account">
                  <RegistrationPage />
                </Route>
                <Route path="/account/change-password">
                  <Box className={classes.formContainer}>
                    <ResetPasswordPage />
                  </Box>
                </Route>
              </Switch>
            </Box>
            <Footer borderTop={0} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.authBackground} />
      </Grid>
    </Box>
  );
};

export default AuthLayout;
