import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { FormControl, InputBase } from '@material-ui/core';
import { ArrowRight, FilterList, Language, Mail, NavigateBefore, PhoneEnabled, Public } from '@material-ui/icons';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import FormikInput from '../components/ui/FormikInput';

const Textarea = withStyles(theme => ({
  input: {
    position: 'relative',
    padding: '6px 12px',
    marginBottom: 13,
    color: 'var(--black-100)',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    borderRadius: 4,
    border: '1px solid var(--black-20)',
    '&:focus': {
      borderColor: 'var(--input-active-border)',
    },
  },
}))(InputBase);
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  formContainer: {
    position: 'relative',
    marginTop: '32px',
  },
  forgotPassword: {
    position: 'absolute',
    top: 68,
    right: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
      top: 240,
    },
  },
  forgotPasswordLink: {
    fontWeight: 600,
    fontSize: 14,
    color: 'var(--primary-regular)',
    fontFamily: 'Montserrat',
    textDecoration: 'underline',
    '&:hover': {
      color: 'var(--primary-hover)',
      textDecoration: 'underline',
    },
  },
}));

export const Styleguide = () => {
  const classes = useStyles();

  return (
    <Box component="section" id="styleguide">
      <Container>
        <Box textAlign="center">
          <Typography variant="h1" component="h1" gutterBottom>
            Керівництво по стилям
          </Typography>
        </Box>
        <Box sx={{ p: 2, mb: 2, border: '1px dashed var(--border-color)' }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Заголовки
          </Typography>
          <Typography variant="h1" component="h1" gutterBottom>
            Заголовок h1
          </Typography>
          <Typography variant="h2" component="h2" gutterBottom>
            Заголовок h2
          </Typography>
          <Typography variant="h3" component="h3" gutterBottom>
            Заголовок h3
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            Заголовок h4
          </Typography>
          <Typography variant="h5" component="h5" gutterBottom>
            Заголовок h5
          </Typography>
          <Typography variant="h6" component="h6" gutterBottom>
            Заголовок h6
          </Typography>
          <Typography variant="subtitle1" component="h6">
            Subtitle 1
          </Typography>
          <Typography variant="subtitle2" component="h6">
            Subtitle 2
          </Typography>
          <Typography variant="caption" display="block">
            Країна
          </Typography>
          <Typography color="textSecondary" variant="h6" component="h6">
            Пошук
          </Typography>
        </Box>
        <Box sx={{ p: 2, mb: 2, border: '1px dashed var(--border-color)' }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Кнопки
          </Typography>
          <Button className={classes.button} variant="outlined" color="primary">
            Кнопка 1
          </Button>
          <Button className={classes.button} variant="contained" color="primary">
            Кнопка 2
          </Button>
          <Link to="/">
            <Button className={classes.button} variant="outlined" startIcon={<NavigateBefore />} size="small" color="primary">
              Кнопка 2
            </Button>
          </Link>
          <Link to="/">
            <Button className={classes.button} variant="outlined" startIcon={<NavigateBefore />} size="small" color="primary">
              Назад
            </Button>
          </Link>
          <Link to="/">
            <IconButton color="primary" className={classes.button}>
              <ArrowRight />
            </IconButton>
          </Link>
          <IconButton color="primary" className={classes.button}>
            <FilterList />
          </IconButton>
          <IconButton disabled color="secondary" className={classes.button}>
            <Public fontSize="small" />
          </IconButton>
          <IconButton disabled color="secondary" className={classes.button}>
            <Language fontSize="small" />
          </IconButton>
          <IconButton disabled color="secondary" className={classes.button}>
            <Mail fontSize="small" />
          </IconButton>
          <IconButton disabled color="secondary" className={classes.button}>
            <PhoneEnabled fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ p: 2, mb: 2, border: '1px dashed var(--border-color)' }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Формы
          </Typography>
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}>
            {({ isSubmitting }) => (
              <form className={classes.formContainer}>
                <FormikInput mandatory key="email" id="email" name="email" label="Електронна пошта" type="text" shrink placeholder="Введіть свій email" offMultiline />
                <FormikInput mandatory key="password" type="password" offMultiline id="password" name="password" label="Пароль" placeholder="Введіть ваш пароль" />
                <Box display="flex" justifyContent="flex-end">
                  <Box component="span" pr={2}>
                    <Button variant="outlined" color="primary" to="/">
                      Відмінитииии
                    </Button>
                  </Box>
                  <Button variant="contained" disabled={isSubmitting} color="primary" type="submit">
                    Ввійти
                  </Button>
                </Box>
                <span className={classes.forgotPassword}>
                  <Link to="/" className={classes.forgotPasswordLink}>
                    Забули пароль?
                  </Link>
                </span>
              </form>
            )}
          </Formik>
        </Box>
        <Box sx={{ p: 2, mb: 2, border: '1px dashed var(--border-color)' }}>
          <FormControl fullWidth>
            <Textarea disabled={false} id="textarea" multiline type="textarea" placeholder="Введіть текст тут" rows="3" />
          </FormControl>
        </Box>
      </Container>
    </Box>
  );
};

export default Styleguide;
