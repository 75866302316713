import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Header from 'components/blocks/Header';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cipeMain: {
    overflow: 'hidden',
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export const DefaultLayout = ({ children, props }) => {
  const classes = useStyles();
  return (
    <div>
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar position="fixed" disableGutters>
            <Header isMaxWidth={false} />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Box component="main" className={classes.cipeMain}>
        {children}
      </Box>
    </div>
  );
};

export default DefaultLayout;
