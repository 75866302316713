import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from 'hooks/useAuth';
import { UserContext } from 'context/UserContext';

const RouteAuthenticated = ({ children, roles = [], ...rest }) => {
  const { accessToken } = useContext(AuthContext);
  const user = useContext(UserContext);
  if (roles.length && user.loggedInUser && accessToken) {
    if (user.loggedInUser.role.name) {
      if (!roles.includes(user.loggedInUser.role.name)) {
        return <Redirect to="/account/profile" />;    
      }
    }
  }
  if (!accessToken) {
    return <Redirect to="/login" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default RouteAuthenticated;
