// Core
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Language } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: '9px',
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const DashboardInfoBlock = ({ title, content, icon }) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoBlock}>
      <IconButton disabled color="secondary" className={classes.icon}>
        {icon}
      </IconButton>
      <Box>
        <Typography variant="caption" display="block">
          {title}
        </Typography>
        <Typography variant="h6" component="h6">
          {content}
        </Typography>
      </Box>
    </Box>
  );
};

DashboardInfoBlock.defaultProps = {
  title: '',
  content: '',
  icon: <Language fontSize="small" />,
};

DashboardInfoBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.element,
};

export default DashboardInfoBlock;
