// Core
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
// @material-ui
import { Box, Typography, Button } from '@material-ui/core';
import { useAuth } from 'hooks/useAuth';
// Components

const Status = ({ code, children }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
};

const NotFoundPage = () => {
  const { push } = useHistory();
  const { accessToken } = useAuth();

  return (
    <Status code={404}>
      <Box paddingTop="25px">
        <Typography gutterBottom variant="h1">
          404 Page not found
        </Typography>
        <Typography gutterBottom variant="h2">
          Sorry. The page seems to be missing, please try again.
        </Typography>
        <Button variant="outlined" onClick={() => push(accessToken ? '/dashboard' : '/')}>
          Go to Home page
        </Button>
      </Box>
    </Status>
  );
};

export default NotFoundPage;
