// Core
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
// material-ui
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
// Components
import FormikInput from 'components/ui/FormikInput';
import Button from '@material-ui/core/Button';

const initialValues = { email: '', password: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]+$/, {
      message: 'Невірно вказана поштова адреса',
      excludeEmptyString: true,
    })
    .required('Поле є обов\'язковим'),
  password: Yup.string().required('Поле є обов\'язковим'),
});

const useStyles = makeStyles(() => {
  return {
    formContainer: {
      position: 'relative',
      marginTop: '32px',
    },
    forgotPassword: {
      position: 'absolute',
      top: 68,
      right: 0,
    },

    forgotPasswordLink: {
      fontWeight: 500,
      fontSize: 14,
      color: 'var(--primary-regular)',
      fontFamily: 'Montserrat',
      textDecoration: 'underline',
      '&:hover': {
        color: 'var(--primary-hover)',
        textDecoration: 'underline',
      },
    },
  };
});

const SignInForm = ({ onSignIn, authError }) => {
  const classes = useStyles();
  const onSubmit = values => {
    if (process.env.REACT_APP_RECAPTCHA_KEY) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY.trim(), { action: 'submit' }).then(googleRecaptchaToken => {
          onSignIn({ ...values, google_recaptcha_token: googleRecaptchaToken });
        });
      });
    } else {
      onSignIn({ ...values });
    }
  };

  const emailAuthError = authError && authError.message;
  const passwordAuthError = authError && authError.password;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
      {({ values, errors, handleSubmit, isSubmitting }) => (
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <FormikInput mandatory key="email" id="email" name="email" label="email" type="text" offMultiline value={values.email} apiError={emailAuthError} />
          <FormikInput mandatory key="pasword" type="password" offMultiline id="password" name="password" label="password" value={values.password} apiError={passwordAuthError} />
          <Box display="flex" justifyContent="flex-end">
            <Box component="span" pr={2}>
              <Button variant="outlined" color="primary" component={Link} to="/">
                Відмінити
              </Button>
            </Box>

            <Button variant="contained" color="primary" type="submit">
              Ввійти
            </Button>
          </Box>

          <span className={classes.forgotPassword}>
            <Link to="/forgot_password" className={classes.forgotPasswordLink}>
              Забули пароль?
            </Link>
          </span>
        </form>
      )}
    </Formik>
  );
};

SignInForm.defaultProps = {
  authError: null,
  onSignIn: () => null,
};

SignInForm.propTypes = {
  authError: PropTypes.shape({}),
  onSignIn: PropTypes.func,
};

export default SignInForm;
