import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SignInForm from 'components/forms/SignInForm';

import { useAuth } from 'hooks/useAuth';

export const LoginPage = () => {
  const { replace } = useHistory();
  const { state } = useLocation();
  const { accessToken, signin, error: authError, pending, isHuman } = useAuth();

  const { from } = state || { from: { pathname: '/entrepreneurs' } };

  useEffect(() => {
    if (accessToken) {
      replace(from);
    }
  }, [accessToken, replace, from]);
  return (
    <Box>
      <Typography variant="h3" component="h1" gutterBottom>
        Увійти в систему
      </Typography>
      <SignInForm authError={authError} onSignIn={signin} pending={pending} isHuman={isHuman} />
    </Box>
  );
};

export default LoginPage;
