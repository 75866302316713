import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from 'hooks/useAuth';

const RouteUnauthenticated = ({ children, ...rest }) => {
  const { accessToken } = useContext(AuthContext);
  if (accessToken) {
    return <Redirect to="/account/profile" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default RouteUnauthenticated;
