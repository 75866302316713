// Core
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MapChart from 'components/MapChart/MapChart';
import Button from '@material-ui/core/Button';
import { Language, Mail, NavigateBefore, PhoneEnabled } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import DashboardInfoBlock from 'components/Dashboard/DashboardInfoBlock';
import DashboardChips from 'components/Dashboard/DashboardChips';
import Footer from 'components/blocks/Footer';
import { getSynergy } from 'api/synergies';
import useAsync from 'hooks/useAsync';
import { UserConsumer } from 'context/UserContext';
import { ROLES } from 'utils';
import { getCountryName } from 'config/metaData';

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
  detailsWrapper: {
    margin: '3px',
    padding: '18px',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: 'calc(100% - 79px - 5px - 70px)',
      padding: '18px 45px',
    }
  },
  infoBlockHolder: {
    width: '50%',
  },
  contactsBlock: {
    border: '1px solid var(--border-color)',
    padding: '11px 16px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  sectionHeader: {
    padding: '18px',
    marginRight: '-4px',
    borderBottom: '1px solid var(--border-color)',
    height: '79px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '16px 48px',
    }
  },
}));
const SynergyDetails = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [synergy, setSynergy] = useState(null);
  const { execute: loadSynergy, value: synergyDetails } = useAsync(getSynergy, false);
  useEffect(() => {
    loadSynergy(id);
  }, [id, loadSynergy]);

  useEffect(() => {
    console.log(synergyDetails);
    if (synergyDetails) {
      setSynergy({
        ...synergyDetails,
        applicants: synergyDetails.applicants.map(item => ({ ...item, label: item.name })),
        novators: synergyDetails.novators.map(item => ({ ...item, label: item.name })),
      });
    }
  }, [synergyDetails, setSynergy]);

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} md={6} className={classes.fullHeight}>
        <Box className={classes.sectionHeader}>
          <Link to="/synergies">
            <Button className={classes.button} variant="outlined" startIcon={<NavigateBefore />} size="small" color="primary">
              Назад
            </Button>
          </Link>
        </Box>
        {synergy ? <Box className={classes.fullHeight}>
          <Box className={classes.detailsWrapper}>
            <Typography variant="h4" component="h4" gutterBottom>
              {synergy.name}
              <UserConsumer>
              {({ loggedInUser }) => {
                if (loggedInUser && loggedInUser.role.name === ROLES.cipe_admin) {
                  return <Button
                  component={Link}
                  to={`/manage-synergies/${synergy.id}`}
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                >Edit</Button>
                }
                return null;
              }}
              </UserConsumer>
            </Typography>
            <Box className={classes.contactsBlock}>
              <Box className={classes.infoBlockHolder} sx={{ marginBottom: '18px' }}>
                <DashboardInfoBlock title="Країна" content={getCountryName(synergy.country_code)} icon={<Language fontSize="small" />} />
              </Box>
              <Box className={classes.infoBlockHolder} sx={{ marginBottom: '18px' }}>
                <DashboardInfoBlock title="Імейл" content={synergy.email} icon={<Mail fontSize="small" />} />
              </Box>
              <Box className={classes.infoBlockHolder}>
                <DashboardInfoBlock title="Номер телефону" content={synergy.phone_number} icon={<PhoneEnabled fontSize="small" />} />
              </Box>
              <Box className={classes.infoBlockHolder}>
                <DashboardInfoBlock title="Веб сайт" content={synergy.website} icon={<Language fontSize="small" />} />
              </Box>
            </Box>
            <Box className="text-block" sx={{ marginBottom: '20px' }}>
              <Typography variant="h5" component="h5" gutterBottom>
                Про синергію
              </Typography>
              {synergy.description}
            </Box>
            {/* <Box className="text-block" sx={{ marginBottom: '20px' }}>
              <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
                Ключові напрями діяльності:
              </Typography>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
            </Box> */}
            <Box sx={{ marginBottom: '24px' }}>
              <DashboardChips items={synergy.applicants} title="Підприємці" />
            </Box>
            <Box sx={{ marginBottom: '24px' }}>
              <DashboardChips items={synergy.novators} title="Новатори" />
            </Box>
          </Box>
          <Footer showLogo px={6} />
        </Box> : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
      </Grid>
      <Grid item xs={12} md={6} className={classes.mapChartWrapper}>
        {synergy ? <MapChart lat={synergy.lat || 30} lon={synergy.lon || 30} address={synergy.lon && synergy.lat ? synergy.address : 'Адреса не вказана'} /> : null}
      </Grid>
    </Grid>
  );
};

export default SynergyDetails;
