import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Switch, Route, Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ProfileForm from 'components/forms/ProfileForm';
import { useAuth } from 'hooks/useAuth';

export const RegistrationPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { url } = useRouteMatch();
  const { replace } = useHistory();
  const { state } = useLocation();
  const { signup, error: authError } = useAuth();
  const { from } = state || { from: { pathname: '/registration/success' } };
  const [errors, setErrors] = useState({})

  const onSubmit = async data => {
    const values = {
      ...data,
    };

    if (process.env.REACT_APP_RECAPTCHA_KEY) {
      window.grecaptcha.ready(async () => {
        const googleRecaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY.trim(), { action: 'submit' });
        try {
          const signupResponse = await signup({ ...values, google_recaptcha_token: googleRecaptchaToken });
          if (signupResponse === true) {
            replace(from);
          } else {
            console.log(authError, signupResponse);
            const errs = {}
            if (signupResponse) {
              if (signupResponse['account name']) {
                errs.name = signupResponse['account name']
                if (signupResponse['account name'] === 'Account name already taken') {
                  errs.name = 'Ім\'я облікового запису вже зайнято'
                }
              }
              if (signupResponse['account email'] || signupResponse['user email']) {
                errs.email = signupResponse['account email'] || signupResponse['user email']
                if (signupResponse['account email'] === 'Account email already taken' || signupResponse['user email'] === 'User email already taken') {
                  errs.email = 'Email облікового запису вже зайнято'
                }
              }
              if (signupResponse['company website']) {
                errs.website = signupResponse['company website']
                if (signupResponse['company website'] === "Incorrect field 'Company website'. Try again") {
                  errs.website = 'URL-адреса недійсна'
                }
              }
              setErrors(errs)
              Object.keys(errs).forEach(key => {
                enqueueSnackbar(errs[key], {variant: 'error'})
              })
            }
            
          }
        } catch (e) {
          console.error(e);
        }
      });
    } else {
      const signupResponse = await signup({ ...values });
      if (signupResponse === true) {
        replace(from);
      } else {
        console.log(authError, signupResponse);
        const errs = {}
        if (signupResponse) {
          if (signupResponse['account name']) {
            errs.name = signupResponse['account name']
            if (signupResponse['account name'] === 'Account name already taken') {
              errs.name = 'Ім\'я облікового запису вже зайнято'
            }
          }
          if (signupResponse['account email'] || signupResponse['user email']) {
            errs.email = signupResponse['account email'] || signupResponse['user email']
            if (signupResponse['account email'] === 'Account email already taken' || signupResponse['user email'] === 'User email already taken') {
              errs.email = 'Email облікового запису вже зайнято'
            }
          }
          if (signupResponse['company website']) {
            errs.website = signupResponse['company website']
            if (signupResponse['company website'] === "Incorrect field 'Company website'. Try again") {
              errs.website = 'URL-адреса недійсна'
            }
          }
          setErrors(errs)
          console.log(errs)
          Object.keys(errs).forEach(key => {
            enqueueSnackbar(errs[key], {variant: 'error'})
          })
        }
      }
    }
  };

  const onBlur = () => {
    setErrors({})
  }

  return (
    <Box>
      <Switch>
        <Route exact path={url}>
          <Typography variant="h3" component="h1" gutterBottom>
            Реєстрація нового користувача
          </Typography>
        </Route>
        <Route path={`${url}/success`}>
          <Typography variant="h3" component="h1" gutterBottom>
            Реєстрація пройшла успішно
          </Typography>
          <Box mb={4} component="h2" fontSize={14} fontWeight={500} color="text.secondary">
            На вказаний e-mail був відправлений відправлений лист з <br /> подальшими інструкціями щодо налаштування профілю.
          </Box>
        </Route>
      </Switch>
      <Switch>
        <Route exact path={url}>
          <ProfileForm formSubmit={onSubmit} formBlur={onBlur} externalErrors={errors || {}} submitButtonText="Зареєструватися" />
        </Route>
        <Route path={`${url}/success`}>
          <Button variant="contained" color="primary" component={Link} to="/login">
            Зрозуміло
          </Button>
        </Route>
      </Switch>
    </Box>
  );
};

export default RegistrationPage;
