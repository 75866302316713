import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Box, Grid , Button, MenuItem, CircularProgress } from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import FormikInput from "components/ui/FormikInput";
import { Link } from "react-router-dom";
import FormikAutocomplete from "components/ui/FormikAutocomplete/FormikAutocomplete";
import { getApplicants } from "api/applicants";
import { getNovators } from "api/novators";
import { fetchCountries } from "api/info";
import { searchOpenStreetAddress } from 'api/3rdparty';


const validationSchema = Yup.object({
  name: Yup.string('Enter your email').required('Required'),
  email: Yup.string()
  .matches(/^[A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]+$/, {
    message: 'Invalid email address',
    excludeEmptyString: true,
  }),
  country_code: Yup.string(),
  address: Yup.string(),
  phone_number: Yup.string(),
  website: Yup.string().url(),
  description: Yup.string(),
  applicants: Yup.array(),
  novators: Yup.array()
});

const initialValues = {
  name: '',
  email: '',
  phone_number: '',
  website: '',
  address: '',
  country_code: '',
  description: '',
  applicants: [],
  novators: [],
  lat: null,
  lon: null
};

function getStyles(value, values) {
  return {
    fontWeight:
      values.includes(value)
        ? 400
        : 600,
  };
}

const SynergyForm = ({ initValues, externalErrors, handleSubmit, isUpdate, loading }) => {

  const [countiresItems, setCountiresItems] = useState([]);
  const { execute: loadCountries, value: countires } = useAsync(fetchCountries, false);
  useEffect(() => {
    loadCountries();
  }, [loadCountries]);

  useEffect(() => {
    const items = countires
      ? countires.map(country => {
          const { code, name } = country;
          return (
            <MenuItem key={code} name={name} value={code}>
              <div className={`flag flag-${code} mr-2`} />
              {name}
            </MenuItem>
          );
        })
      : null;
    if (items) {
      setCountiresItems(items);
    }
  }, [countires]);

  console.log(initValues);
  const [coords, setCoords] = useState(!!initValues ? {lat: initValues.lat, lon: initValues.lon} : {lat: 0, lon: 0});
  const addressHandler = ({lat, lon}) => {
    setCoords({lat, lon})
  }

  const [applicantsItems, setApplicantsItems] = useState([]);
  const { execute: loadApplicants, value: applicants } = useAsync(getApplicants, false);
  useEffect(() => {
    loadApplicants();
  }, [loadApplicants]);

  useEffect(() => {
    
    if (applicants) {
      setApplicantsItems(applicants.data);
    }
  }, [applicants]);


  const [novatorsItems, setNovatorsItems] = useState([]);
  const { execute: loadNovators, value: novators } = useAsync(getNovators, false);
  useEffect(() => {
    loadNovators();
  }, [loadNovators]);

  useEffect(() => {
    
    if (novators) {
      setNovatorsItems(novators.data);
    }
  }, [novators]);


  const submitHandler = (values, actions) => {
    handleSubmit({
      ...values,
      ...coords,
    }, actions)
  }

  return <Formik initialValues={initValues || initialValues} validationSchema={validationSchema} onSubmit={submitHandler}>
      {({ values, isSubmitting }) => {
        return <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikInput mandatory key="name" id="name" name="name" label="Назва" type="text" offMultiline value={values.name} />
              </Grid>
              <Grid item xs={6}>
                <FormikInput
                  mandatory
                  key="email"
                  id="email"
                  name="email"
                  label="Електронна пошта"
                  type="email"
                  offMultiline
                  value={values.email}
                  apiError={externalErrors.email} />
              </Grid>
              <Grid item xs={6}>
                {countiresItems.length ? (
                  <FormikInput select id="country_code" name="country_code" value={values ? values.country_code : ''} label="Країна">
                    {countiresItems}
                  </FormikInput>
                ) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
              </Grid>
              <Grid item xs={6}>
                <FormikAutocomplete 
                  key="address" 
                  id="address" 
                  name="address" 
                  label="Операційна адреса" 
                  value={values.address} 
                  request={searchOpenStreetAddress}
                  changeValueHandler={addressHandler}
                  />
                <Field type="hidden" id="lat" name="lat" value={values.lat} />
                <Field type="hidden" id="lon" name="lon" value={values.lon} />
              </Grid>
              <Grid item xs={6}>
                <FormikInput
                  mandatory
                  offMultiline
                  key="phone_number"
                  id="phone_number"
                  name="phone_number"
                  label="Телефон"
                  type="text"
                  value={values.phone_number}
                  apiError={externalErrors.phone_number}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                key="website"
                id="website"
                name="website"
                label="Вебсайт"
                type="url"
                offMultiline
                value={values.webiste} 
                apiError={externalErrors.website} />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  key="description"
                  id="description"
                  name="description"
                  label="Опис"
                  rows={4}
                  value={values.description}
                  apiError={externalErrors.description}
                />
              </Grid>
              <Grid item xs={12}>
                {applicantsItems.length ? (
                  <FormikInput select selectMultiple selectKey="name" selectKeyValue="id" id="applicants" name="applicants" value={values.applicants} options={applicantsItems} label="Підприємці">
                  {applicantsItems.map(item => {
                  return <MenuItem key={item.id} name={item.name} value={item.id} className={values.applicants.includes(item.id) ? 'Mui-selected': ''}>
                    {item.name}
                  </MenuItem>
                  })}
                  </FormikInput> 
                ) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
              </Grid>
              <Grid item xs={12}>
                {novatorsItems.length ? (
                  <FormikInput select selectMultiple selectKey="name" selectKeyValue="id" id="novators" name="novators" value={values.novators} options={novatorsItems} label="Новатори">
                  {novatorsItems.map(item => {
                  return <MenuItem key={item.id} name={item.name} value={item.id} className={values.novators.includes(item.id) ? 'Mui-selected': ''}>
                    {item.name}
                  </MenuItem>
                  })}
                  </FormikInput> 
                ) : <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" mb={2} justifyContent="flex-end">
                  <Box component="span" pr={2}>
                    <Button variant="outlined" color="primary" component={Link} to={`/synergies` + (isUpdate ? `/${isUpdate}` : '')}>
                      Відмінити
                    </Button>
                  </Box>
                  <Button variant="contained" color="primary" type="submit" disabled={loading}>
                  {isUpdate ? 'Редагувати' : 'Створити'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
      }}
    </Formik>
}

export default SynergyForm;