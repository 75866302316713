// Core
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { NavigateBefore } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Footer from 'components/blocks/Footer';
import rocket from 'assets/img/rocket.svg';
import { UserConsumer } from 'context/UserContext';
import { ROLES } from 'utils';
import { linkify } from 'helpers';
import { getBusiness } from '../../api/businesses'
import useAsync from 'hooks/useAsync';

const useStyles = makeStyles(theme => ({
  emptyTitle: {
    fontWeight: '600',
    marginBottom: '1em !important',
  },
  emptyBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyInfoHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: '300px',
  },
  fullHeight: {
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    height: '100%',
    width: '100%',
  },
  detailsWrapper: {
    overflow: 'auto',
    height: 'calc(100% - 79px - 70px)',
    padding: '15px 45px',
    margin: '3px',
  },
  sectionHeader: {
    padding: '16px 48px',
    marginRight: '-4px',
    borderBottom: '1px solid var(--border-color)',
    height: '79px',
    display: 'flex',
    alignItems: 'center',
  }
}));

const LibraryContent = ({ item, onBack }) => {
  const classes = useStyles();
  return <Grid item className={classes.fullHeight}>
    <Box className={classes.sectionHeader}>
      <Link to="/library">
        <Button className={classes.button} onClick={onBack} variant="outlined" startIcon={<NavigateBefore />} size="small" color="primary">
          Назад
        </Button>
      </Link>
    </Box>
    <Box className={classes.contentContainer}>
      <Box className={classes.detailsWrapper}>
        <Typography variant="h4" component="h4" gutterBottom>
        {item.name}
        <UserConsumer>
          {({ loggedInUser }) => {
            if (loggedInUser && loggedInUser.role.name === ROLES.cipe_admin) {
              return <Button
              component={Link}
              to={`/manage-libraries/${item.id}`}
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >Edit</Button>
            }
            return null;
          }}
          </UserConsumer>
          
        </Typography>
        <Box className="text-block" sx={{ marginBottom: '20px', marginTop: '24px' }}>
          <Typography variant="h5" component="h5" gutterBottom>
            Короткий опис
          </Typography>
          <p dangerouslySetInnerHTML={{__html: linkify(item.short_description)}}></p>
        </Box>
        <Box className="text-block" sx={{ marginBottom: '20px' }}>
          <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
            Критерії та умови участі:
          </Typography>
          <p dangerouslySetInnerHTML={{__html: linkify(item.conditions)}}></p>
        </Box>
        <Box className="text-block" sx={{ marginBottom: '20px' }}>
          <Typography color="textSecondary" variant="h6" component="h6" gutterBottom>
            Додаткові відомості:
          </Typography>
          <p dangerouslySetInnerHTML={{__html: linkify(item.additional_info)}}></p>
        </Box>
      </Box>
      <Footer showLogo px={6} />
    </Box>
  </Grid>
}

const LibraryDetails = ({ item, onBack }) => {
  const classes = useStyles();

  const { id } = useParams();

  const [business, setBusiness] = useState(null);
  const { execute: loadBusiness, value: businessDetails } = useAsync(getBusiness, false);

  useEffect(() => {
    if (id) {
      loadBusiness(id);
    }
  }, [id, loadBusiness]);

  useEffect(() => {
    if (businessDetails) {
      setBusiness(businessDetails);
    }
  }, [setBusiness, businessDetails]);

  return (
    <Grid container className={classes.fullHeight}>
      {business || item.id ? (
        <LibraryContent item={business ? business : item} onBack={onBack} />
      ) : (
        <Grid item className={classes.fullHeight}>
          <Box className={classes.emptyBlock}>
            <Box className={classes.emptyInfoHolder}>
              <Box sx={{ marginBottom: '10px' }}>
                <img src={rocket} alt="Rocket" />
              </Box>
              <Typography className={classes.emptyTitle} variant="h6" component="h6" gutterBottom>
                Деталі програми
              </Typography>
              <Typography color="textSecondary" variant="h6" component="h6">
                Виберіть, будь ласка, програму, щоб переглянути її деталі.
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

LibraryDetails.defaultProps = {
  item: {},
};

LibraryDetails.propTypes = {
  item: PropTypes.object,
};

export default LibraryDetails;
