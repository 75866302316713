// Core
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Footer from 'components/blocks/Footer';
import homeBanner from 'assets/img/home-banner.jpg';
import arrow from 'assets/img/arrow.svg';
import boldArrow1 from 'assets/img/bold-arrow-1.svg';
import boldArrow2 from 'assets/img/bold-arrow-2.svg';
import logo from 'assets/img/logo.svg';
import { Box, Button, Container, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import EyeInMonitorIcon from '../components/shared/icons/EyeInMonitorIcon';
import CogIcon from '../components/shared/icons/CogIcon';
import TabletIcon from '../components/shared/icons/TabletIcon';
import IntelligenceIcon from '../components/shared/icons/IntelligenceIcon';
import CheckmarkInTabletIcon from '../components/shared/icons/CheckmarkInTabletIcon';
import BulbIcon from '../components/shared/icons/BulbIcon';
import LoupIcon from '../components/shared/icons/LoupIcon';
import SaturnBikeIcon from '../components/shared/icons/SaturnBikeIcon';

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundImage: `url(${homeBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      minHeight: '670px',
      height: '670px',
    }
  },
  fullHeight: {
    minHeight: '670px',
    height: '670px',
    position: 'relative',
  },
  goDownButton: {
    width: '48px',
    height: '48px',
  },
  h1Holder: {
    maxWidth: '750px',
    color: 'var(--white)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px'
    }
  },
  subtitleHolder: {
    maxWidth: '500px',
    color: 'var(--white)',
  },
  titleSection: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  },
  contentSection: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    }
  },
  buttonsHolder: {
    '& .MuiButtonBase-root': {
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(2),
      },
    },
  },
  scrollLink: {
    position: 'absolute',
    left: '50%',
    bottom: '30px',
    transform: 'translate(-50%, 0)',
    borderColor: 'var(--border-color)',
    backgroundColor: 'var(--white)',
  },
}));

const cardsData = [
  {
    title: 'Compliance Screening',
    color: 'var(--orange)',
    icon: <EyeInMonitorIcon height="68px" color="var(--orange)" />,
  },
  {
    title: 'Enhanced Due Diligence',
    color: 'var(--turquoise)',
    icon: <CogIcon color="var(--turquoise)" />,
  },
  {
    title: 'Pre-employment Screening',
    color: 'var(--blue)',
    icon: <TabletIcon color="var(--blue)" />,
  },
  {
    title: 'Strategic Intelligence',
    color: 'var(--green)',
    icon: <IntelligenceIcon color="var(--green)" />,
  },
  {
    title: 'Legal and Compliance',
    color: 'var(--orange-yellow)',
    icon: <CheckmarkInTabletIcon color="var(--orange-yellow)" />,
  },
  {
    title: 'Tech Intelligence',
    color: 'var(--red-orange)',
    icon: <BulbIcon color="var(--red-orange)" />,
  },
  {
    title: 'Partner Search',
    color: 'var(--cyan-blue)',
    icon: <LoupIcon color="var(--cyan-blue)" />,
  },
  {
    title: 'Trends Intelligence',
    color: 'var(--purple-magenta)',
    icon: <SaturnBikeIcon color="var(--purple-magenta)" />,
  },
];

const HomePage = () => {
  const classes = useStyles();

  return (
    <>
      <Box component="section" id="banner" className={classes.banner} color="primary.contrastText">
        <Container className={classes.fullHeight}>
          <Grid container direction="column" justify="space-between" className={classes.fullHeight}>
            <Grid item />
            <Grid item>
              <Typography className={classes.h1Holder} variant="h1" component="h1" gutterBottom>
                Вітаємо на Cluster Platform
              </Typography>
              <Typography className={classes.subtitleHolder} variant="subtitle1" component="h3" gutterBottom>
                Партнерство та інновації на шляху до відбудови країни
              </Typography>
              <Box className={classes.buttonsHolder}>
                <Button variant="contained" color="primary" component={RouterLink} size="large" to="/registration">
                  Зареєструватися
                </Button>
                <Button variant="outlined" color="primary" size="large" component={RouterLink} to="/login">
                  Увійти в систему
                </Button>
              </Box>
            </Grid>
            <Grid item />
            <Box
              component={Link}
              className={classes.scrollLink}
              borderColor="white"
              href="#"
              display="flex"
              alignContent="center"
              justifyContent="center"
              border={1}
              borderRadius="50%"
              width={48}
              height={48}
              p={2}>
              <img src={arrow} alt="arrow down" />
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box component="section" id="mission">
        <Container>
          <Box textAlign="center" p={{xs: 2, sm: 12}}>
            <Typography variant="h2" component="h2" gutterBottom>
              <Box className={classes.titleSection}>Місія Cluster Platform</Box>
            </Typography>
            <Typography variant="subtitle1" component="h3">
              <Box className={classes.contentSection}>
                Місія платформи – сприяння налагодженню партнерських відносин та ефективної кооперації між комерційними і науково-дослідними структурами задля інтенсивного розвитку економіки та
                прогресу, утворення синергії між підприємницьким та новаторським духом
              </Box>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box component="section" id="synergies" sx={{ backgroundColor: 'var(--background-grey)' }}>
        <Container>
          <Box textAlign="center" p={{xs: 2, sm: 12}}>
            <Typography variant="h2" component="h2" gutterBottom>
              <Box className={classes.titleSection}>Перспективи обʼєднання в синергії</Box>
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginRight: '8px',
                }}>
                <Box
                  sx={{
                    height: '90px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '24px',
                    fontWeight: '700',
                    border: '1px solid var(--border-color)',
                    width: '100%',
                  }}>
                  Підприємці
                </Box>
                <img src={boldArrow2} alt="arrow" />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginLeft: '8px',
                }}>
                <Box
                  sx={{
                    height: '90px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '24px',
                    fontWeight: '700',
                    border: '1px solid var(--border-color)',
                    width: '100%',
                  }}>
                  Новатори
                </Box>
                <img src={boldArrow2} alt="arrow" />
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                backgroundColor: 'var(--primary-regular)',
                height: '90px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '24px',
                color: 'var(--white)',
                fontWeight: '700',
              }}>
              Синергії
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}>
              <Box
                sx={{
                  textAlign: 'center',
                  width: '50%',
                  marginRight: '8px',
                }}>
                <img src={boldArrow1} alt="arrow" />
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                  width: '50%',
                  marginLeft: '8px',
                }}>
                <img src={boldArrow1} alt="arrow" />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}>
              <Box
                p={{xs: 2, sm:4}}
                sx={{
                  border: '1px solid var(--border-color)',
                  marginRight: '8px',
                  width: '50%',
                }}>
                <List>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Розробка спільної маркетингової кампанії на міжнародному ринку" />
                  </ListItem>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Консолідована участь в науково-дослідних проектах" />
                  </ListItem>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Об'єднання транспортно-логістичних завдань" />
                  </ListItem>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Організація спільного виробництва" />
                  </ListItem>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Створення кластерів" />
                  </ListItem>
                </List>
              </Box>
              <Box
                p={{xs: 2, sm:4}}
                sx={{
                  border: '1px solid var(--border-color)',
                  marginLeft: '8px',
                  width: '50%',
                }}>
                <List>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Подолання розриву між науковою сферою та реальною економікою" />
                  </ListItem>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Комерціалізація результатів власної науково-дослідної роботи" />
                  </ListItem>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Адаптація напрямків досліджень відповідно до трендів ринку" />
                  </ListItem>
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemIcon fontSize="5px">
                      <CheckCircle fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText secondary="Збільшення частки проривних досліджень та розробок світового рівня" />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box component="section" id="possibility">
        <Container>
          <Box textAlign="center" px={{xs: 2, sm: 12}} py={{xs: 4, sm: 12}}>
            <Typography variant="h2" component="h2" gutterBottom>
              <Box className={classes.titleSection}>Можливості Cluster Platform</Box>
            </Typography>
            <Typography variant="subtitle1" component="h3">
              <Box className={classes.contentSection}>
                Реєструйтесь на платформі як Підприємець чи Новатор та отримайте повну інформацію про учасників платформи, а також акаунт у WASP з доступом до набору модулів для замовлення актуальних
                послуг
              </Box>              
            </Typography>
          </Box>
          <Box
            component="a"
            href={process.env.REACT_APP_WASP_URL}
            textAlign="center"
            display="block"
            sx={{
              border: '1px solid var(--border-color)',
              borderRadius: '4px',
              p: 1,
              mb: 2,
            }}>
            <img src={logo} alt="WASP" width="102" height="88" />
          </Box>

          <Grid container spacing={2}>
            {cardsData.map((item, i) => (
              <Grid item key={i} xs={6} sm={3}>
                <Box
                  sx={{
                    border: `1px solid ${item.color}`,
                    borderLeftWidth: '8px',
                    height: '100%',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 3,
                  }}>
                  <Box sx={{ mb: 1 }}>{item.icon}</Box>
                  <Box sx={{ maxWidth: '200px' }}>
                    <Typography variant="subtitle2" component="h4">
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box textAlign="center" sx={{ mt: 6, mb: 12 }}>
            <Button variant="contained" color="primary" size="large" component={RouterLink} to="/registration">
              Зареєструватися на Cluster Platform
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default HomePage;
