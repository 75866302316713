import { createBusiness, getBusiness, updateBusiness } from "api/businesses";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import BusinessForm from "components/forms/BusinessForm";

const ManageLibrariesPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();  
  const { execute, value, error, pending } = useAsync( id ? updateBusiness : createBusiness, false);
  const [externalErrors, setExternalErrors] = useState({});
  const [activeAction, setActiveAction] = useState();

  const { execute: fetchBusiness, value: businessResponse, pendingBusiness } = useAsync(getBusiness, false);
  const [item, setItem] = useState();
  useEffect(() => {
    if (id) {
      fetchBusiness(id)
    }
  }, [id, fetchBusiness]);
  useEffect(() => {
    if (businessResponse) {
      setItem(businessResponse)
    }
  }, [setItem, businessResponse])

  useEffect(() => {
    if (id) {
      if (value) {
        enqueueSnackbar('Library has been updated', {variant: 'success'})
      } else if (error) {
        enqueueSnackbar('There is error', {variant: 'error'})
        setExternalErrors(error)
      }
    } else {
      if (value) {
        enqueueSnackbar('Library has been created', {variant: 'success'})
        activeAction.resetForm()
      } else if (error) {
        enqueueSnackbar('There is error', {variant: 'error'})
        setExternalErrors(error)
      }
    }
  }, [id, execute, value, error, activeAction]);

  const handleSubmit = (values, actions) => {
    setActiveAction(actions)
    if (id) {
      execute({id, data: values});
    } else {
      execute(values);
    }
  }
  
  return <Box p={6}>
    <Box py={4}>
      <Typography component="h2">Create Business plan</Typography>
    </Box>
    { (pending || pendingBusiness) ? <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box> : null}
    {
      id ? item ? <BusinessForm initValues={item} handleSubmit={handleSubmit} externalErrors={externalErrors} isUpdate={id} loading={pending} /> : null
      :  <BusinessForm handleSubmit={handleSubmit} externalErrors={externalErrors} loading={pending} />
    }
  </Box>
  
}

export default ManageLibrariesPage;