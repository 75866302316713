// Core
import React from 'react';
import propTypes from 'prop-types';
// material-ui
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4f9092',
      main: '#175b5e',
      dark: '#0a3f42',
    },
    secondary: {
      main: '#676e77',
    },
    textSecondary: {
      main: '#676e77',
    },
    custom: {
      // New custom colors
      black: '#000',
      white: '#fff',
      black100: '#020d1c',
      black90: '#061427',
      grey: '#676e77',
      borderColor: '#cccfd2',
      orange: '#ed7916',
      turquoise: '#18b8a3',
      blue: '#183f73',
      green: '#769d2d',
      orangeYellow: '#c59a33',
      redOrange: '#bc401e',
      cyanBlue: '#2c70ba',
      purpleMagenta: '#7b448e',
      //-----------------------

      whiteFour: '#f5f5f5',
      brownishOrange: '#cb7a2a', // --secondary-orange
      greyish: '#061427', // --primary-pressed
      mediumGrey: '#646462',
      darkSlateBlue25: '#2c4173',
      navy: '#183f73',
      blackish: '#061427', // --primary-pressed
      greyBorder: ' #cccfd2', // --black-20
    },
    background: {
      colors: {
        honeycombs: '#ffffff',
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial"',
    fontFamilyCondensed: 'Montserrat',
    h1: {
      fontSize: 28,
      fontWeight: 'bold',
      lineHeight: 'normal',
    },
    h2: {
      fontSize: 22,
      fontWeight: 'bold',
      lineHeight: 1.14,
    },
    h3: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 1.44,
    },
    h4: {
      fontSize: 15,
      fontWeight: 'normal',
      lineHeight: 1.6,
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 'normal',
    },
    body2: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 'normal',
    },
  },
  overrides: {

    MuiPopover: {
      paper: {
        boxShadow: 'none',
        border: '1px solid var(--black-20)',
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        height: 16,
        '& p': {
          fontSize: 14,
          fontWeight: 600,
          color: 'var(--black)',
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        borderRadius: 4,
      },
      day: {
        '&:hover': {
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      root: {
        minHeight: '32px',
      },
      input: {
        fontSize: '14px',
        fontWeight: '500',
        color: 'var(--black-100)',
        '&.Mui-disabled': {
          cursor: 'not-allowed'
        }
      },
      multiline: {
        padding: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        minHeight: '1em',
        lineHeight: '1em',
      },
    },
    MuiTextField: {
      root: {
        '&.searchInput': {
          '& .MuiFormHelperText-root': {
            display: 'none',
          },
          '& .MuiInputAdornment-root': {
            position: 'relative',
            marginLeft: '10px',
            border: 0,
            '& .MuiIcon-root': {
              color: 'var(--black-40)',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: 'var(--black-40)',
        textTransform: 'capitalize',
      },
    },
    MuiStep: {
      root: {
        padding: 0,
      },
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: 0,
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '100%',
      },
      paperScrollPaper: {
        borderRadius: 0,
      },
      root: {
        '&.drawer-page': {
          left: 'auto!important',
          '& .MuiDialog-paperScrollPaper': {
            margin: 0,
            height: '100%',
            maxHeight: '100%',
            width: '408px',
          },
          '& .drawer-page-title': {
            padding: '26px 24px 27px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            '& .fa': {
              color: 'var(--black-100)',
            },
          },
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: 24,
        '& .fa': {
          color: 'var(--primary-regular)',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0 0 24px 0',
        justifyContent: 'center',
      },
    },
    MuiBackdrop: {
      root: {
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.34)',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'white',
        color: 'var(--black-100)',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: 4,
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '80px !important',
      },
    },
    MuiBreadcrumbs: {
      root: {
        padding: '16px 0',
        color: 'var(--black-40)',
        '& a': {
          color: 'var(--black-40)',
        },
        '& p': {
          color: 'var(--black-40)',
        },
      },
    },
    MuiButton: {
      root: {
        transition:
          'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
          'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
          'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
          'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        textTransform: 'none',
        fontWeight: '500',
        boxShadow: 'none',
        fontSize: '14px',
        '&:hover': {
          backgroundColor: 'var(--primary-hover)',
        },
      },
      sizeSmall: {
        fontSize: '14px',
        padding: '3px 16px',
      },
      sizeLarge: {
        fontSize: '14px',
      },
      startIcon: {
        '&.MuiButton-iconSizeSmall': {
          marginLeft: '-12px',
          marginRight: '0',
          '&> *:first-child': {
            fontSize: '22px',
          },
        },
      },
      endIcon: {
        '&.MuiButton-iconSizeSmall': {
          marginLeft: '0',
          marginRight: '-12px',
          '&> *:first-child': {
            fontSize: '22px',
          },
        },
      },
      label: {
        width: 'auto',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: 'var(--primary-hover)',
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: '1px solid var(--border-color)',
        backgroundColor: 'var(--white)',
        '&:hover': {
          border: '1px solid var(--primary-regular)',
          backgroundColor: 'var(--primary-regular)',
          color: 'var(--white)',
        },
      },
      contained: {
        boxShadow: 'none',
        '&.typeService': {
          '&1': {
            backgroundColor: 'var(--secondary-orange)',
            color: 'var(--white)',
          },
          '&2': {
            backgroundColor: 'var(--secondary-turquoise)',
            color: 'var(--white)',
          },
          '&3': {
            backgroundColor: 'var(--secondary-blue)',
            color: 'var(--white)',
          },
          '&4': {
            backgroundColor: 'var(--secondary-green)',
            color: 'var(--white)',
          },
        },
        '&.status-btn': {
          height: '18px',
          margin: '-4px 0 0',
          padding: '6px 10px',
          '&.status-draft': {
            backgroundColor: 'var(--black-60)',
            color: 'var(--white)',
          },
          '&.status-new': {
            backgroundColor: 'var(--secondary-green)',
            color: 'var(--white)',
          },
          '&.status-accepted': {
            backgroundColor: 'var(--secondary-turquoise)',
            color: 'var(--white)',
          },
          '&.status-in-progress': {
            backgroundColor: 'var(--secondary-turquoise)',
            color: 'var(--white)',
          },
          '&.status-completed': {
            backgroundColor: 'var(--secondary-blue)',
            color: 'var(--white)',
          },
          '&.status-on-hold': {
            backgroundColor: 'var(--secondary-orange)',
            color: 'var(--white)',
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&.MuiIconButton-colorPrimary': {
          borderRadius: '4px',
          padding: '4px',
          border: '1px solid var(--border-color)',
          '&:hover': {
            backgroundColor: 'var(--primary-regular)',
            color: 'var(--white)',
            borderColor: 'var(--primary-regular)',
          },
        },
        '&.MuiIconButton-colorSecondary': {
          borderRadius: '4px',
          padding: '8px',
          backgroundColor: 'rgba(23,91,94, .1)',
          color: 'var(--primary-regular)',
          '&:hover': {
            backgroundColor: 'var(--primary-regular)',
            color: 'var(--white)',
          },
        },
      },
    },
    MuiIcon: {
      root: {
        fontSize: 16,
        color: 'var(--white)',
      },
      colorSecondary: {
        color: 'var(--black-100)',
      },
    },
    MuiSelect: {
      root: {
        fontFamily: 'Montserrat',
      },
      selectMenu: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        minHeight: '32px',
      },
      select: {
        '&:focus': {
          backgroundColor: 'var(--white)',
        },
        '&.MuiSelect-select': {
          paddingRight: '28px',
        },
      },
      outlined: {
        '&.MuiSelect-outlined': {
          paddingRight: '30px',
        },
      },
      icon: {
        fontSize: '1rem',
        top: 'calc(50% - 8px)',
        right: '8px',
        color: 'var(--primary-regular)',
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: 'Montserrat',
        fontSize: 80,
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'var(--black-100)',
        '&.MuiTypography-gutterBottom': {
          marginBottom: '.1em',
        },
      },
      h2: {
        fontFamily: 'Montserrat',
        fontSize: 52,
        fontWeight: 'bold',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: 'var(--black-100)',
        '&.MuiTypography-gutterBottom': {
          marginBottom: '.7em',
        },
      },
      h3: {
        fontFamily: 'Montserrat',
        fontSize: 36,
        fontWeight: 700,
        lineHeight: 1.44,
        letterSpacing: 'normal',
        margin: 0,
        color: 'var(--black-100)',
      },
      h4: {
        fontFamily: 'Montserrat',
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        color: 'var(--black-100)',
      },
      h5: {
        fontFamily: 'Montserrat',
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        color: 'var(--black-100)',
      },
      h6: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        color: 'var(--black-100)',
        '&.MuiTypography-gutterBottom': {
          marginBottom: '.65em',
        },
      },
      subtitle1: {
        color: 'var(--black-90)',
        fontSize: '24px',
        lineHeight: '1.3333',
        fontWeight: '500',
        '&.MuiTypography-gutterBottom': {
          marginBottom: '1.4em',
        },
      },
      subtitle2: {
        color: 'var(--black-90)',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '1.3',
      },
      caption: {
        fontSize: '10px',
        color: 'var(--grey)',
        fontWeight: '500',
      },
      body1: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 14,
        color: 'var(--black-90)',
        lineHeight: '1.71',
        letterSpacing: 'normal',
        '& label': {
          fontSize: 11,
        },
        '&.warning-msg': {
          color: '#ed3b16',
          fontSize: 12,
        },
      },
      body2: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 14,
        color: 'var(--black-90)',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '& label': {
          fontWeight: 'normal',
          fontSize: 10,
          lineHeight: '18px',
          color: 'var(--black-60)',
        },
        '& .small-msg': {
          fontWeight: 900,
          fontSize: 10,
          margin: '0 3px',
          color: 'var(--black-100)',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenu: {
      list: {
        padding: '7px',
      },
    },
    MuiListItem: {
      root: {
        width: 'auto',
        paddingBottom: 0,
        paddingTop: 0,
        borderRadius: '4px',
        '&.Mui-selected': {
          backgroundColor: 'var(--primary-regular)',
          color: 'var(--white)',
          '&:hover': {
            backgroundColor: 'var(--primary-regular)',
            color: 'var(--white)',
          },
        },
      },
      button: {
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          backgroundColor: 'var(--primary-light)',
          color: 'var(--white)',
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: '36px',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
        marginRight: '8px',
        fontSize: '14px',
        borderRadius: '50%',
        color: 'var(--black)',
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
        fontFamily: 'Montserrat',
        fontWeight: 'normal',
        color: 'var(--black)',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      },
      secondary: {
        fontSize: 16,
        fontFamily: 'Montserrat',
        fontWeight: 500,
        color: 'var(--black)',
        lineHeight: '1.625',
        letterSpacing: 'normal',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--border-color)',
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--border-color)',
            borderWidth: '1px',
          },
        },
      },
      notchedOutline: {
        borderColor: 'var(--border-color)',
      },
      input: {
        padding: '8px 12px',
      },
      inputAdornedStart: {
        paddingRight: '40px',
      },
    },
    MuiCardActions: {
      root: {
        '&.tableCell': {
          padding: '22px 8px 8px 13px',
          '&.tinyPaddings': {
            padding: '8px 8px 3px 13px',
          },
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        '&.tableCell': {
          padding: '0 13px 0 8px',
          '&:last-child': {
            paddingBottom: 22,
          },
          '&.tinyPaddings': {
            '&:last-child': {
              paddingBottom: 8,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        right: '10px',
      },
      positionStart: {
        height: '100%',
        maxHeight: '100%',
        marginRight: 0,
      },
      positionEnd: {
        height: '100%',
        maxHeight: '100%',
        marginLeft: '8px',
        padding: '0 8px',
        borderLeft: '1px solid var(--black-20)',
      },
    },
    MuiTab: {
      root: {
        '@media(min-width:600px)': {
          minWidth: 0,
        },
      },
    },
    PrivateTabIndicator: {
      root: {
        height: 4,
      },
    },
    MuiCheckbox: {
      root: {
        color: 'var(--black-20)',
      },
      colorSecondary: {
        '&$disabled': {
          color: 'var(--greyish)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: 'var(--greyish)',
        },
      },
    },
    MUIDataTableToolbar: {
      root: {
        display: 'flex',
        padding: '20px 0',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      actions: {
        textAlign: 'right',
        display: 'flex',
        flex: 'none',
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
      filterPaper: {
        width: 408,
        height: '100%',
        margin: 0,
        maxHeight: '100%',
        right: 0,
        left: 'calc(100% - 408px)!important',
        top: '0px!important',
        border: '0 none',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderRadius: 0,
      },
      filterCloseIcon: {
        marginTop: 10,
        marginRight: 8,
      },
    },
    MUIDataTableFilterList: {
      root: {
        margin: 0,
        display: 'inline-flex',
      },
    },
    MUIDataTableFilter: {
      root: {
        padding: '24px 0 24px',
        fontFamily: 'inherit',
        backgroundColor: 'var(--white)',
        '& .MuiGridList-root': {
          padding: '10px 24px',
          '& .MuiGridListTile-root': {
            padding: '15px 17px 0!important',
          },
        },
      },
      checkboxListTitle: {
        marginLeft: 0,
      },
      startSelectAdornment: {
        fontWeight: 'bold',
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1rem',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '4px',
        backgroundColor: 'var(--primary-regular)',
        color: 'var(--white)',
        fontWeight: '500',
        fontSize: '14px',
        // maxWidth: '189px',
        height: '24px',
      },
      label: {
        lineHeight: '24px',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    MuiPaginationItem: {
      root: {
        color: 'var(--primary-regular)',
      },
      textPrimary: {
        '&.Mui-selected': {
          backgroundColor: 'var(--black-100)',
        },
      },
    },
  },
});

const Theming = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

Theming.propTypes = {
  children: propTypes.node.isRequired,
};

export default Theming;
