import { Box, Button, CircularProgress, FormControl, Input, InputAdornment, InputLabel } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { uploadSynergies } from "api/synergies";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';

const ManageSynergiesPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { execute: uploadSynergiesRequest, value: uploadSynergiesResponse, error: uploadSynergiesError, pending } = useAsync(uploadSynergies, false);

  const uploadFile = event => {
    event.preventDefault()
    console.log(event.currentTarget)
    const form = event.currentTarget;
    uploadSynergiesRequest(form)
  }

  useEffect(() => {
    console.log(uploadSynergiesResponse);
    if (uploadSynergiesResponse) {
      enqueueSnackbar('Synergies uploaded', {variant: 'success'})
    } else if (uploadSynergiesError) {
      console.log(uploadSynergiesError)
      let errors = []
      Object.keys(uploadSynergiesError.errors).forEach(item => {
        let errorString = item + ': ' + uploadSynergiesError.errors[item];
        errors.push(errorString)
      })
      enqueueSnackbar(errors.join("\r\n"), {variant: 'error'})
    }
  }, [uploadSynergiesResponse, uploadSynergiesError])

  return <Box p={6}>
    <h2>Upload file with synergies</h2>
    <form onSubmit={uploadFile}>
      <FormControl>
        <InputLabel htmlFor="input-with-icon-adornment">Upload file</InputLabel>
        <Input
          id="input-with-icon-adornment"
          type="file"
          name="file"
          startAdornment={
            <InputAdornment position="start">
              <CloudUploadIcon />
            </InputAdornment>
          }
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        type="submit"
      >
        Upload
      </Button>
    </form>
    {pending ? <Box display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box> : null}
  </Box>
}

export default ManageSynergiesPage;
