// Core
import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: '16px',

    '&:hover': {},

    '&:last-child': {},
  },

  active: {
    '& div': {
      '& span': {
        fontWeight: 700,
      },
    },
  },
}));

const ListItemLink = props => {
  const classes = useStyles(props);
  const { path, text, exact } = props;

  const renderLink = useMemo(
    () =>
      forwardRef((linkProps, ref) => {
        const activeLink = clsx(classes.listItem, classes.active);

        return <NavLink ref={ref} to={path} exact={exact} activeClassName={activeLink} {...linkProps} onClick={props.onClick} />;
      }),
    [classes.listItem, classes.active, path],
  );

  return (
    <ListItem className={classes.listItem} component={renderLink}>
      <ListItemText primary={text} />
    </ListItem>
  );
};

ListItemLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ListItemLink;
