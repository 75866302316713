// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { FilterList } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 32,
  },
  sortWrapper: {
    position: 'relative',
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'var(--primary-hover)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
  },
  sortSelect: {
    width: '32px',
    height: '32px',
    overflow: 'hidden',
    textIndent: '-999999px',
    '& .MuiSelect-select': {
      padding: 0,
      backgroundColor: 'transparent !important',
    },
  },
  sortIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'var(--white)',
  },
}));

const SimpleSort = ({ items, selected, onChange }) => {
  const classes = useStyles();
  let [sortBackgroundColor, setSortBackgroundColor] = useState('var(--primary-regular)');
  const handleSortOpen = event => {
    setSortBackgroundColor('var(--primary-hover)');
  };
  const handleSortClose = event => {
    setSortBackgroundColor('var(--primary-regular)');
  };
  return (
    <FormControl className={classes.formControl} variant="outlined">
      <Box className={classes.sortWrapper} sx={{ backgroundColor: sortBackgroundColor }}>
        <FilterList className={classes.sortIcon} />
        <Select
          className={classes.sortSelect}
          IconComponent={() => null}
          id="filter-select"
          value={selected}
          onChange={onChange}
          onOpen={handleSortOpen}
          onClose={handleSortClose}
          MenuProps={{
            anchorOrigin: {
              vertical: 36,
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            getContentAnchorEl: null,
          }}>
          {items.map((item, index) => {
            return (
              <MenuItem value={item.value} key={index + item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </FormControl>
  );
};

SimpleSort.defaultProps = {
  items: [],
  selected: '',
  onChange: () => null,
};

SimpleSort.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

export default SimpleSort;
